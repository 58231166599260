import { useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField, Box, Button, Grid, Autocomplete } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../features/auth/authSlice';
import { IProject } from '../../../types/project';
import LoadingLogo from '../../common/LoadingLogo';
import { convertAllDates, camelToTitle, numToCurrency } from '../../../utils/Functions';
import StandardTable from '../../tables/StandardTable';
import { IDealDeposit } from '../../../types/CreateDealForm';
import { IPurchaserInfo } from '../../../types/CreateDealForm';

const Assignments = () => {
  const user = useSelector(selectUser);
  const [dateStart, setDateStart] = useState<Date | number | null>(new Date(new Date().setHours(0, 0, 0, 0)));
  const [dateEnd, setDateEnd] = useState<Date | null>(new Date());
  const [projects, setProjects] = useState<IProject[]>([]);
  const [assignments, setAssignments] = useState<IDealDeposit[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [current, setCurrent] = useState<string>('Active');
  const [count, setCount] = useState<number>(0);

  const [getAssignments, { loading }] = useLazyQuery(ASSIGNMENTS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAssignments(data.assignmentPagination.items);
      setCount(data.assignmentPagination.count);
    },
  });

  const columns = useMemo(() => {
    return [
      {
        Header: 'Project',
        accessor: (rowData: any) => rowData.project.name,
      },
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          if (rowData.unit) {
            return (
              <Link
                style={{ textDecoration: 'none', color: 'inherit' }}
                rel="noopener noreferrer"
                target="_blank"
                to={`/${rowData.project._id}/dashboard/${rowData.unit._id}`}
              >
                <strong style={{ cursor: 'pointer' }}>{rowData.unit.suite}</strong>
              </Link>
            );
          } else return '';
        },
      },
      {
        Header: 'Assignment Price',
        accessor: (rowData: any) => (rowData.price ? numToCurrency.format(rowData.price) : 'Not Available'),
      },
      {
        Header: 'Assignors',
        accessor: (rowData: any) =>
          rowData.owners
            .map((profile: IPurchaserInfo, typeIndex: number) => {
              return `${profile.fullName}`;
            })
            .join(', '),
      },
      {
        Header: "Assignor's Realtor",
        accessor: (rowData: any) => (rowData.ownersRealtor ? rowData.ownersRealtor.fullName : ''),
      },
      {
        Header: 'Assignees',
        accessor: (rowData: any) =>
          rowData.purchasers
            .map((profile: IPurchaserInfo, typeIndex: number) => {
              return `${profile.fullName}`;
            })
            .join(', '),
      },
      {
        Header: "Assignee's Realtor",
        accessor: (rowData: any) => (rowData.purchasersRealtor ? rowData.purchasersRealtor.fullName : ''),
      },
      {
        Header: 'Sign Date',
        accessor: (rowData: any) => convertAllDates(rowData.signDate, 'PP'),
      },
    ];
  }, []);

  useEffect(() => {
    getAssignments({
      variables: {
        filter: {
          projects: projects.length ? projects.map((project: IProject) => project._id) : [],
          current: current === 'Active' ? true : false,
          dateGreaterThanEqual: dateStart,
          dateLessThanEqual: dateEnd,
        },
        page: pageNumber,
        perPage: 20,
      },
    });
  }, [pageNumber]);

  const handleAssignments = () => {
    getAssignments({
      variables: {
        filter: {
          projects: projects.length ? projects.map((project: IProject) => project._id) : [],
          current: current === 'Active' ? true : false,
          dateGreaterThanEqual: dateStart,
          dateLessThanEqual: dateEnd,
        },
        page: pageNumber,
        perPage: 20,
      },
    });
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              sx={{ height: '100%' }}
              multiple
              options={[user?.projectAccess!.map((projectAccess: any) => projectAccess?.project)].flat()}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              disableClearable={false}
              freeSolo={false}
              value={projects}
              onChange={(e, value) => {
                setProjects(value!);
              }}
              renderInput={(params) => <TextField {...params} label="Projects" size="medium" />}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              sx={{ height: '100%' }}
              options={['Active', 'Non Active']}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              disableClearable={false}
              freeSolo={false}
              value={current}
              onChange={(e, value) => {
                setCurrent(value!);
              }}
              renderInput={(params) => <TextField {...params} label="Active" size="medium" />}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <DateTimePicker
              label={'Assignment Signed Start Date (YYYY/MM/DD)'}
              value={dateStart}
              onChange={(newValue) => {
                setDateStart(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <DateTimePicker
              label={'Assignment Signed End Date (YYYY/MM/DD)'}
              value={dateEnd}
              onChange={(newValue) => {
                setDateEnd(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button sx={{ height: '100%' }} variant="contained" color="primary" onClick={() => handleAssignments()}>
              Search
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
      {loading ? (
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <LoadingLogo />
        </Box>
      ) : assignments.length ? (
        <Box>
          <Box>
            <StandardTable data={assignments} columns={columns} count={count} />
          </Box>
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
              {'<'}
            </Button>
            <span>{pageNumber}</span>
            <Button disabled={pageNumber === Math.ceil(count / 20)} onClick={() => setPageNumber(pageNumber + 1)}>
              {'>'}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <em>No Assignments Found!</em>
        </Box>
      )}
    </Box>
  );
};

const ASSIGNMENTS = gql`
  query assignmentPagination($filter: FilterFindManyAssignmentInput, $page: Int!, $perPage: Int!, $sort: SortFindManyAssignmentInput) {
    assignmentPagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        project {
          name
          _id
        }
        owners {
          fullName
        }
        purchasers {
          fullName
        }
        ownersRealtor {
          fullName
        }
        purchasersRealtor {
          fullName
        }
        current
        unit {
          _id
          suite
        }
        signDate
        price
      }
      count
    }
  }
`;

export default Assignments;
