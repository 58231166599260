import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import BasicTabs from '../common/BasicTabs';
import { Flex } from '../../commonStyles';
import { useNavigate } from 'react-router-dom';
import Envelopes from './envelopes/Envelopes';
import Deposits from './deposits/Deposits';
import Assignments from './assignments/Assignments';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const tabs = [
    {
      label: 'Envelopes',
      component: <Envelopes />,
    },
    {
      label: 'Deposits',
      component: <Deposits />,
    },
    {
      label: 'Assignments',
      component: <Assignments />,
    },
  ];

  return (
    <Box
      sx={{
        p: 2,
        height: '100vh',
        position: 'relative',
      }}
    >
      <Flex sx={{ mb: 2 }}>
        <Box sx={{ alignSelf: 'center' }}>
          <ArrowBackIcon sx={{ cursor: 'pointer', mr: 1, alignSelf: 'center', color: '#00142A' }} onClick={() => navigate(-1)} />
        </Box>
        <Typography variant="h2" sx={{ alignSelf: 'center' }}>
          <strong>Admin Dashboard</strong>
        </Typography>
      </Flex>
      <BasicTabs tabs={tabs} sub={true} value={value} setValue={setValue} color={'#fff'} />
    </Box>
  );
};

export default AdminDashboard;
