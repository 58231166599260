import { useState, MouseEvent } from 'react';
import { gql, useLazyQuery, useQuery, useSubscription } from '@apollo/client';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  AppBar,
  Toolbar,
  Drawer,
  IconButton,
  MenuItem,
  Menu,
  FormControl,
  TextField,
  Autocomplete,
  Button,
  Collapse,
  Tooltip,
  Badge,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import ListAlt from '@mui/icons-material/ListAlt';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import TaskIcon from '@mui/icons-material/Task';
import { useSelector } from 'react-redux';

import { FlexBetween } from '../../commonStyles';
import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { useLogout } from '../../features/auth/authHooks';
import { withRouter } from '../../utils/WithRouter';
import { IProjectAccess } from '../../types/user';
import SideMenu from './SideMenu';
import Logo from '../../assets/logos/rds-logo-transparent.png';
import { selectPortal } from '../../features/portal/portalSlice';
import { theme } from '../../theme';
import '../priceGrid/mediaPrint.css';

const Header = (props: any) => {
  const { projectid } = useParams();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const portal = useSelector(selectPortal);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [projectEl, setProjectEl] = useState<null | HTMLElement>(null);
  const userOpen = Boolean(anchorEl);
  const projectOpen = Boolean(projectEl);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState<any>([]);
  const [searchOpen, setSearchOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [tasks, setTasks] = useState<number>(0);

  const logout = useLogout();
  const navigate = useNavigate();

  useSubscription(TASKSUBSCRIPTION, {
    variables: { user: user._id },
    onData: async ({ data: { data } }: any) => {
      // Remove unit if not HL or SHL
      setTasks(tasks + 1);
    },
  });

  useQuery(TASKCOUNT, {
    variables: { filter: { viewedBy: user._id } },
    onCompleted: (data) => {
      setTasks(data.taskCount);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [searchAll, { loading: searchLoading }] = useLazyQuery(SEARCHALL, {
    onCompleted: (data) => {
      setSearch(data.globalSearch);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleSearch = (e: any, value: string) => {
    setInputValue(value);
    if (value) {
      searchAll({ variables: { project: projectid, search: value } });
    }
  };

  const handleSearchInput = (event: any, values: any) => {
    setInputValue('');
    setSearchOpen(false);

    if (values.hasOwnProperty('suite') && values.suite) {
      navigate(`/${projectid}/dashboard/${values._id}`);
    } else if (values.hasOwnProperty('realtor') && values.realtor) {
      navigate(`/${projectid}/dashboard/search/${values.realtor._id}`, {
        state: {
          type: values.hasOwnProperty('brokerage') && values.brokerage ? 'realtor' : 'purchaser',
          search: `${values.firstName} ${values.lastName}`,
        },
      });
    } else {
      navigate(`/${projectid}/dashboard/search/${values._id}`, {
        state: {
          type: values.hasOwnProperty('brokerage') && values.brokerage ? 'realtor' : 'purchaser',
          search: `${values.firstName} ${values.lastName}`,
        },
      });
    }
  };

  const optionLabel = (option: any) => {
    if (option.suite) {
      return `${option.suite}`;
    } else {
      return `${option.firstName} ${option.lastName} ${option.email} ${option.primaryPhone}`;
    }
  };

  const renderType = (option: any) => {
    if (option.brokerage) {
      return 'Realtor';
    } else if (option.suite) {
      return 'Suite';
    } else {
      return 'Purchaser';
    }
  };

  const handleProjectNavigate = (id: string) => {
    setProjectEl(null);
    navigate(`/${id}/dashboard`);
  };

  return (
    <AppBar className="grow" position="static">
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: `${portal.primaryColor ? portal.primaryColor : '#00142a'}`,
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              edge="start"
              sx={{
                mr: 1,
                alignSelf: 'center',
                // [theme.breakpoints.down("sm")]: {
                //   marginRight: 0
                // },
              }}
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(true)}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ mr: 1, alignSelf: 'center' }}>
              <Link to={`/${projectid}/dashboard`}>
                <img src={Logo} alt="logo" width="40px" />
              </Link>
            </Box>
            {!showSearch ? (
              <Box
                sx={(theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    display: 'none',
                  },
                  [theme.breakpoints.up('sm')]: {
                    display: 'block',
                  },
                  alignSelf: 'center',
                })}
              >
                <FormControl>
                  <Autocomplete
                    id="search"
                    open={searchOpen}
                    onOpen={() => {
                      if (inputValue) {
                        setSearchOpen(true);
                      }
                    }}
                    loading={searchLoading}
                    disableClearable
                    freeSolo={false}
                    options={search}
                    clearOnBlur={true}
                    getOptionLabel={(option) => optionLabel(option)}
                    value={''}
                    onChange={handleSearchInput}
                    inputValue={inputValue}
                    onClose={() => setSearchOpen(false)}
                    onInputChange={(e, value, reason) => {
                      if (reason === 'reset') {
                        setInputValue('');
                      } else {
                        handleSearch(e, value);
                      }
                      if (!value) {
                        setSearchOpen(false);
                      }
                    }}
                    renderOption={(props, option: any) => {
                      return (
                        <li {...props}>
                          <div>
                            {option.suite ? option.suite : `${option.firstName} ${option.lastName}`}
                            <Box
                              sx={{
                                fontSize: '10px',
                              }}
                            >
                              <div>{option.email}</div>
                              <div>{option.primaryPhone}</div>
                              <div>{renderType(option)}</div>
                            </Box>
                          </div>
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          color: '#fff',
                          width: '300px',
                          borderRadius: '8px',
                          '& .MuiInputLabel-formControl': {
                            position: 'absolute',
                            top: 'auto',
                            left: 'auto',
                          },
                          '& .MuiAutocomplete-inputRoot': {
                            color: '#fff',
                            '& > fieldset': { borderColor: 'inherit' },
                          },
                          '& .MuiFormLabel-root': {
                            color: '#fff',
                          },
                          mt: '10px',
                        }}
                        required={false}
                        {...params}
                        size="small"
                        label={'Search'}
                        margin="normal"
                      />
                    )}
                  />
                </FormControl>
              </Box>
            ) : null}
          </Box>
        </Box>
        <FlexBetween>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                display: 'block',
              },
              [theme.breakpoints.up('sm')]: {
                display: 'none',
              },
              mt: 1,
              mr: 1,
              alignSelf: 'center',
            })}
          >
            <SearchIcon onClick={() => setShowSearch(!showSearch)} />
          </Box>
          {user.type === 'Manager' || user.type === 'Admin' ? (
            <Box
              sx={(theme) => ({
                mt: 1,
                mr: 1,
                alignSelf: 'center',
              })}
            >
              <Link to={`/admin-dashboard`}>
                <Tooltip title="Admin Dashboard">
                  <FolderRoundedIcon sx={{ color: '#fff' }} />
                </Tooltip>
              </Link>
            </Box>
          ) : null}
          {user.type !== 'Developer' ? (
            <Box
              sx={(theme) => ({
                mt: 1,
                mr: 1,
                alignSelf: 'center',
                display: 'flex',
              })}
            >
              <Link to={`/tasks`}>
                <Tooltip title="Tasks">
                  <Badge
                    sx={{
                      color: `${portal.primaryColor ? portal.primaryColor : '#00142a'}`,
                      pb: 0.3,
                      height: '100%',
                      '& .MuiBadge-badge': { fontSize: 11, height: 18, minWidth: 18, backgroundColor: 'yellow !important' },
                    }}
                    invisible={tasks > 0 ? false : true}
                    badgeContent={tasks}
                  >
                    <TaskIcon sx={{ color: '#fff' }} />
                  </Badge>
                </Tooltip>
              </Link>
            </Box>
          ) : null}
          {user.type === 'Manager' ? (
            <Box
              sx={(theme) => ({
                mt: 1,
                mr: 1,
                alignSelf: 'center',
              })}
            >
              <Tooltip title="All Summaries">
                <Link to={`/manager`}>
                  <AnalyticsIcon sx={{ color: '#fff' }} />
                </Link>
              </Tooltip>
            </Box>
          ) : null}
          {user.type !== 'Developer' && user.type !== 'Admin' ? (
            <Box
              sx={(theme) => ({
                mt: 1,
                mr: 1,
                alignSelf: 'center',
              })}
            >
              <Tooltip title="Create Worksheet">
                <Link to={`/${project._id}/dashboard/create-worksheet`}>
                  <ListAlt sx={{ color: '#fff' }} />
                </Link>
              </Tooltip>
            </Box>
          ) : null}
          {user.type !== 'Developer' ? (
            <Box
              sx={(theme) => ({
                mt: 1,
                mr: 1,
                alignSelf: 'center',
              })}
            >
              <Tooltip title="Daily Walk ins">
                <a
                  style={{ color: '#fff' }}
                  href={`https://crm.rdsre.ca/dashboard/project/${
                    project._id
                  }/list/came-ins?row=15&page=1&cameInStart=${new Date().setHours(0, 0, 0, 0)}&cameInEnd=${new Date().getTime()}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <DirectionsWalkIcon sx={{ cursor: 'pointer' }} />
                </a>
              </Tooltip>
            </Box>
          ) : null}
          {project.name ? (
            <Button
              id="basic-button"
              aria-controls={projectOpen ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={projectOpen ? 'true' : undefined}
              onClick={(e: MouseEvent<HTMLButtonElement>) => setProjectEl(e.currentTarget)}
              sx={{
                color: '#fff',
                [theme.breakpoints.down('sm')]: {
                  fontSize: '10px',
                },
              }}
            >
              {project.name}
            </Button>
          ) : null}
          {user ? (
            <Button
              id="basic-button"
              aria-controls={userOpen ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={userOpen ? 'true' : undefined}
              onClick={(e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
              sx={{
                color: '#fff',
                [theme.breakpoints.down('sm')]: {
                  fontSize: '10px',
                },
              }}
            >
              {user.fullName}
            </Button>
          ) : null}
        </FlexBetween>
        <Menu
          id="basic-menu"
          anchorEl={projectEl}
          open={projectOpen}
          onClose={() => setProjectEl(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {[...user?.projectAccess]
            .sort((a: any, b: any) => a.project.name.localeCompare(b.project.name))
            .map((project: IProjectAccess, index: number) => {
              return (
                <MenuItem key={index} onClick={() => handleProjectNavigate(project.project?._id!)}>
                  {project.project.name}
                </MenuItem>
              );
            })}
        </Menu>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={userOpen}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => logout()}>Logout</MenuItem>
        </Menu>
        <Drawer
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            paper: {
              width: 250,
              // [theme.breakpoints.down('sm')]: {
              //   width: '100%',
              // },
            },
          }}
        >
          <SideMenu handleClose={() => setOpen(false)} />
        </Drawer>
      </Toolbar>
      {showSearch ? (
        <Collapse
          in={showSearch}
          easing={'enter'}
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              display: 'block',
            },
            [theme.breakpoints.up('sm')]: {
              display: 'none',
            },
            textAlign: 'center',
            pb: 2,
            backgroundColor: `${portal.primaryColor ? portal.primaryColor : '#00142a'}`,
          })}
        >
          <FormControl>
            <Autocomplete
              id="search-mobile"
              open={searchOpen}
              onOpen={() => {
                if (inputValue) {
                  setSearchOpen(true);
                }
              }}
              loading={searchLoading}
              disableClearable
              freeSolo={false}
              options={search}
              clearOnBlur={true}
              getOptionLabel={(option) => optionLabel(option)}
              value={''}
              onChange={handleSearchInput}
              inputValue={inputValue}
              onClose={() => setSearchOpen(false)}
              onInputChange={(e, value, reason) => {
                if (reason === 'reset') {
                  setInputValue('');
                } else {
                  handleSearch(e, value);
                }
                if (!value) {
                  setSearchOpen(false);
                }
              }}
              renderOption={(props, option: any) => {
                return (
                  <li {...props}>
                    <div>
                      {option.suite ? option.suite : `${option.firstName} ${option.lastName}`}
                      <Box
                        sx={{
                          fontSize: '10px',
                        }}
                      >
                        <div>{option.email}</div>
                        <div>{option.primaryPhone}</div>
                        <div>{renderType(option)}</div>
                      </Box>
                    </div>
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    color: '#fff',
                    width: '350px',
                    borderRadius: '8px',
                    '& .MuiInputLabel-formControl': {
                      position: 'absolute',
                      top: 'auto',
                      left: 'auto',
                    },
                    '& .MuiAutocomplete-inputRoot': {
                      color: '#fff',
                    },
                    '& .MuiFormLabel-root': {
                      color: '#fff',
                    },
                  }}
                  required={false}
                  {...params}
                  size="small"
                  label={'Search'}
                  margin="normal"
                />
              )}
            />
          </FormControl>
        </Collapse>
      ) : null}
    </AppBar>
  );
};

const SEARCHALL = gql`
  query globalSearch($search: String!, $project: MongoID!) {
    globalSearch(search: $search, project: $project) {
      _id
      suite
      firstName
      lastName
      primaryPhone
      email
      brokerage
      realtor {
        _id
      }
    }
  }
`;

const TASKCOUNT = gql`
  query taskCount($filter: FilterCountTaskInput) {
    taskCount(filter: $filter)
  }
`;

const TASKSUBSCRIPTION = gql`
  subscription logTask($user: String!) {
    logTask(user: $user) {
      _id
    }
  }
`;

export default withRouter(Header);
