import { useState, useMemo, useEffect } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import { useQuery, useLazyQuery, useMutation, gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { TextField, Box, Button, Grid, FormControl, Select, InputLabel, MenuItem } from '@mui/material';

import { selectProject } from '../../features/project/projectSlice';
import PdfCard from '../common/PdfCard';
import { baseStyle, activeStyle, acceptStyle, rejectStyle, unitTypes } from '../../utils/Constants';
import { showErrorSnackbar, showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { useAppDispatch } from '../../app/hooks';
import { IUnitData } from '../../types/unit';
import { FlexBetween, FlexEnd } from '../../commonStyles';

const MarketingPlans = () => {
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();
  const [modelTypes, setModelTypes] = useState<string[]>([]);
  const [modelType, setModelType] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [model, setModel] = useState<string>('');
  const [unitType, setUnitType] = useState<string>('');
  const [size, setSize] = useState<string>('');
  const [marketingPlans, setMarketingPlans] = useState<IMarketingPlan[]>([]);
  const [newModel, setNewModel] = useState<boolean>(false);

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useQuery<IUnitData>(UNITS, {
    variables: { filter: { project: project._id } },
    onCompleted: (data) => {
      const uniqueModels = [...new Set(data.unitMany.map((item) => item.modelType))].sort();
      setModelTypes(uniqueModels);
    },
  });

  useEffect(() => {
    if (modelType) {
      getMarketingPlans({ variables: { project: project._id, modelType } });
    }
  }, [modelType]);

  const [getMarketingPlans] = useLazyQuery(MARKETINGPLANS, {
    onCompleted: (data) => {
      if (data.getMarketingFloorPlans) {
        setId(data.getMarketingFloorPlans[0]._id);
        setModel(data.getMarketingFloorPlans[0].modelType);
        setSize(data.getMarketingFloorPlans[0].size);
        setUnitType(data.getMarketingFloorPlans[0].unitType);
        let plans = [];
        if (data.getMarketingFloorPlans[0].marketingFloorPlan) {
          plans.push({
            name: `${data.getMarketingFloorPlans[0].modelType}`,
            url: data.getMarketingFloorPlans[0].marketingFloorPlan,
          });
        }
        if (data.getMarketingFloorPlans[0].pdfFloorPlan) {
          plans.push({
            name: `${data.getMarketingFloorPlans[0].modelType}.pdf`,
            url: data.getMarketingFloorPlans[0].pdfFloorPlan,
          });
        }
        setMarketingPlans(plans);
      }
    },
    onError: (err) => {
      if (err) {
        storeDispatch(showErrorSnackbar('No Marketing Plan found, Please create one'));
        setId('');
        setModel(modelType);
        setUnitType('');
        setSize('');
        setMarketingPlans([]);
        setModelType('');
        setNewModel(true);
      }
    },
  });

  const [updateMarketingPlan] = useMutation(UPDATEMARKETINGPLAN, {
    onCompleted: (data) => {
      setModelType(data.updateMarketingFloorPlan.modelType);
      setModelTypes([...modelTypes, data.updateMarketingFloorPlan.modelType].sort());
      storeDispatch(showSuccessSnackbar('Marketing Floor Plan Updated!'));
    },
  });

  const [createMarketingPlan] = useMutation(CREATEMARKETINGPLAN, {
    onCompleted: (data) => {
      setNewModel(false);
      setModelType('');
      setId('');
      setModel('');
      setUnitType('');
      setSize('');
      setMarketingPlans([]);
      setModelTypes([...modelTypes, data.createMarketingFloorPlan.modelType].sort());
      storeDispatch(showSuccessSnackbar('Marketing Floor Plan Saved!'));
    },
  });

  const [deleteMarketingPlan] = useMutation(DELETEDOCUMENT, {
    variables: { project: project._id },
    onCompleted: (data) => {},
  });

  const handleDelete = (id: string, name: string) => {
    deleteMarketingPlan({
      variables: {
        project: project._id,
        name: name,
      },
    }).then((res) => {
      storeDispatch(showSuccessSnackbar('Document Deleted!'));
      setMarketingPlans(marketingPlans.filter((marketingPlan: IMarketingPlan) => marketingPlan.name !== name));
    });
  };

  const handleDrop = async (acceptedFiles: any) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      if (file.type === 'application/pdf') {
        let selectedPlan = marketingPlans.find((marketingPlan: IMarketingPlan) => marketingPlan.name === `${model}.pdf`);
        if (selectedPlan) {
          let plans = marketingPlans.map((marketingPlan: IMarketingPlan) => {
            if (marketingPlan.name === `${model}.pdf`) {
              return {
                name: `${model}.pdf`,
                file: file,
                url: fileReader.result,
              };
            } else return marketingPlan;
          });
          setMarketingPlans(plans);
        } else {
          setMarketingPlans([
            ...marketingPlans,
            {
              name: `${model}.pdf`,
              file: file,
              url: fileReader.result,
            },
          ]);
        }
      } else {
        let selectedPlan = marketingPlans.find((marketingPlan: IMarketingPlan) => marketingPlan.name === `${model}`);
        if (selectedPlan) {
          let plans = marketingPlans.map((marketingPlan: IMarketingPlan) => {
            if (marketingPlan.name === `${model}`) {
              return {
                name: `${model}`,
                file: file,
                url: fileReader.result,
              };
            } else return marketingPlan;
          });
          setMarketingPlans(plans);
        } else {
          setMarketingPlans([
            ...marketingPlans,
            {
              name: `${model}`,
              file: file,
              url: fileReader.result,
            },
          ]);
        }
      }
    };
  };

  const updateMarketingFloorPlan = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let files = marketingPlans.filter((plan: any) => plan.file).map((plan: any) => plan.file);

    if (id) {
      updateMarketingPlan({
        variables: {
          _id: id,
          record: {
            modelType: model,
            modelName: '',
            size: parseInt(size, 10),
            unitType: unitType,
            project: project._id,
          },
          files: files,
        },
      });
    } else {
      createMarketingPlan({
        variables: {
          record: {
            modelType: model,
            modelName: '',
            size: parseInt(size, 10),
            unitType: unitType,
            project: project._id,
          },
          files: files,
        },
      });
    }
  };

  return (
    <div>
      <FlexBetween>
        <h2>Marketing Plans</h2>
      </FlexBetween>
      <Box
        sx={{
          border: '1px solid #000',
          padding: '30px 20px',
          position: 'relative',
          borderRadius: '8px',
          marginBottom: '20px',
        }}
      >
        <FlexEnd sx={{ mb: 1 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (newModel) {
                setNewModel(false);
              } else {
                setNewModel(true);
              }
              setId('');
              setModel('');
              setUnitType('');
              setSize('');
              setMarketingPlans([]);
            }}
          >
            {newModel ? 'Back' : 'Add New Model'}
          </Button>
        </FlexEnd>
        {!newModel ? (
          <FormControl fullWidth>
            <InputLabel id="id-question-label">Model Types</InputLabel>
            <Select
              value={modelType}
              labelId="id-model-type-label"
              id="id-model-type"
              label="Model Types"
              name="modelType"
              onChange={(e) => setModelType(e.target.value)}
            >
              {modelTypes.map((modelType: string) => {
                return <MenuItem value={modelType}>{modelType}</MenuItem>;
              })}
            </Select>
          </FormControl>
        ) : null}
        {modelType || newModel ? (
          <Box>
            <form onSubmit={updateMarketingFloorPlan}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    sx={{ mt: 2 }}
                    title={'Model Type'}
                    name={'modelType'}
                    fullWidth
                    value={model}
                    label={'Model Type'}
                    required
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setModel(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    sx={{ mt: 2 }}
                    title={'Size'}
                    name={'size'}
                    type="number"
                    onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                    fullWidth
                    value={size}
                    label={'Size'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSize(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="id-question-label">Unit Types</InputLabel>
                    <Select
                      value={unitType}
                      labelId="id-unit-type-label"
                      id="id-unit-type"
                      label="Unit Types"
                      name="unitType"
                      onChange={(e) => setUnitType(e.target.value)}
                    >
                      {unitTypes.map((unitType: string) => {
                        return <MenuItem value={unitType}>{unitType}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Dropzone onDrop={(files) => handleDrop(files)} accept="image/jpg, image/jpeg, application/pdf">
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>Drag and Drop or Upload PDFs/Images</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              <Grid container spacing={2}>
                {marketingPlans.map((marketingPlan: IMarketingPlan, index: number) => {
                  return (
                    <Grid item xs={12} sm={6} md={4}>
                      <PdfCard title={marketingPlan.name} file={marketingPlan.url} id={index} handleDelete={handleDelete} download={true} />
                    </Grid>
                  );
                })}
              </Grid>
              {modelType || newModel ? (
                <FlexEnd sx={{ mt: 2 }}>
                  <Button type="submit" variant="contained" color="success" sx={{ mr: 1 }}>
                    {newModel ? 'Create Marketing Plan' : 'Update Marketing Plan'}
                  </Button>
                </FlexEnd>
              ) : null}
            </form>
          </Box>
        ) : null}
      </Box>
    </div>
  );
};

interface IMarketingPlan {
  name: string;
  file?: any;
  url: any;
}

const MARKETINGPLANS = gql`
  query getMarketingFloorPlans($modelType: String!, $project: MongoID!) {
    getMarketingFloorPlans(modelType: $modelType, project: $project) {
      _id
      size
      modelType
      unitType
      marketingFloorPlan
      pdfFloorPlan
    }
  }
`;

const CREATEMARKETINGPLAN = gql`
  mutation createMarketingFloorPlan($record: CreateOnemarketingFloorPlansInput!, $files: [Upload]) {
    createMarketingFloorPlan(record: $record, files: $files) {
      project {
        _id
      }
      _id
      size
      modelType
      unitType
    }
  }
`;

const UPDATEMARKETINGPLAN = gql`
  mutation updateMarketingFloorPlan($_id: MongoID!, $record: UpdateByIdmarketingFloorPlansInput!, $files: [Upload]) {
    updateMarketingFloorPlan(_id: $_id, record: $record, files: $files) {
      project {
        _id
      }
      _id
      size
      modelType
      unitType
    }
  }
`;

const DELETEDOCUMENT = gql`
  mutation deleteDocument($project: MongoID!, $name: String!) {
    deleteDocument(project: $project, name: $name)
  }
`;

const UNITS = gql`
  query unitMany($filter: FilterFindManyUnitInput) {
    unitMany(filter: $filter, limit: 10000) {
      modelType
    }
  }
`;

export default MarketingPlans;
