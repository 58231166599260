import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  InputAdornment,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControl,
  Typography,
  Autocomplete,
  Box,
  Popper,
  Grid,
  Divider,
} from '@mui/material';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../app/hooks';
import { selectProject } from '../../../features/project/projectSlice';
import { PriceGridContext } from '../../../context/PriceGridContext';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../features/snackbar/snackbarSlice';
import { unitStatusTitles } from '../../../utils/Constants';
import { IStatus } from '../../../types/project';
import { IUserArray } from '../../../types/user';
import { IUnit } from '../../../types/unit';
import { IRealtorInfo } from '../../../types/CreateDealForm';
import { numToCurrency, numberWithCommas } from '../../../utils/Functions';
import EditColumns from './EditColumns';
import LoadingWrapper from '../../common/LoadingWrapper';
import VirtualizedTable from '../../tables/VirtualizedTable';
import { selectUser } from '../../../features/auth/authSlice';
import { handleModal } from '../../../features/modal/modalSlice';

const EditSelected = () => {
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();
  const { setSelectingUnits, setSelectedUnits, selectedUnits, units, setUnits, setEditOpen, draft, selectedPriceList, setModalType } =
    useContext(PriceGridContext);

  const allStatus: IStatus[] = [...project.status, ...unitStatusTitles];
  const [displayType, setDisplayType] = useState<string[]>(
    allStatus.filter((status: IStatus) => status.code !== 'C' && status.code !== 'F').map((status: IStatus) => status.code)
  );

  const [percentages, setPercentages] = useState<any[]>([]);
  const [amountChange, setAmountChange] = useState<any[]>([]);
  const [rentalChange, setRentalChange] = useState<any[]>([]);
  const [status, setStatus] = useState<any[]>([]);
  const [changedPrice, setChangedPrice] = useState<any[]>([]);
  const [changedModel, setChangedModel] = useState<any[]>([]);
  const [changedUnitType, setChangedUnitType] = useState<any[]>([]);
  const [changedAllocation, setChangedAllocation] = useState<any[]>([]);
  const [allAmount, setAllAmount] = useState('');
  const [allPercent, setAllPercent] = useState('');
  const [allFloorPremiums, setAllFloorPremiums] = useState('');
  const [allSamePrice, setAllSamePrice] = useState<string>('0');
  const [allSqFt, setAllSqFt] = useState('');
  const [allRental, setAllRental] = useState('');
  const [allRentalSamePrice, setAllRentalSamePrice] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [allModels, setAllModels] = useState('');
  const [allUnitTypes, setAllUnitTypes] = useState('');
  const [allAllocations, setAllAllocations] = useState('');
  const [filteredSelectedUnits, setFilteredSelectedUnits] = useState<IUnit[]>([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [editColumnOpen, setEditColumnOpen] = useState(false);
  const [realtors, setRealtors] = useState<IRealtorInfo[]>([]);
  const [columns, setColumns] = useState({
    unitNumber: true,
    originalPrice: true,
    originalPPSF: true,
    newPrice: true,
    newPPSF: true,
    status: true,
    size: false,
    outdoorSize: false,
    exposure: false,
    level: false,
    modelType: false,
    unitType: false,
    outdoorType: false,
    bathroom: false,
  });
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);

  const checkSameModel = filteredSelectedUnits.every((unit: IUnit) => unit.modelType === filteredSelectedUnits[0].modelType);

  const [searchRealtors] = useLazyQuery<IUserArray>(GETREALTORS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setRealtors(data.userMany);
    },
  });

  const [updateUnits, { loading: unitLoading }] = useMutation(UPDATEUNIT, {
    onCompleted: (data) => {
      let updatedSelectedUnits = units.map((displayedUnit: any) => {
        let updatedUnit = data.unitUpdateManyInfo.find((updatedUnit: any) => displayedUnit._id === updatedUnit._id);

        if (updatedUnit) {
          return {
            ...displayedUnit,
            allocation: updatedUnit.allocation,
            allocatedDate: updatedUnit.allocatedDate,
            tempAllocation: updatedUnit.tempAllocation,
            basePrice: updatedUnit.basePrice,
            status: updatedUnit.status,
            modelType: updatedUnit.modelType,
            unitType: updatedUnit.unitType,
            size: updatedUnit.size,
            exposure: updatedUnit.exposure,
            bathroom: updatedUnit.bathroom,
            outdoorSize: updatedUnit.outdoorSize,
            rental: updatedUnit.rental,
          };
        } else return displayedUnit;
      });

      setEditOpen(false);
      setSelectingUnits(false);
      setSelectedUnits([]);
      setFilteredSelectedUnits([]);
      setUnits(updatedSelectedUnits);
      setOpenConfirm(false);
      setModalType('');
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar('Units Successfully Updated'));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar('There is an error updating units'));
      setOpenConfirm(false);
      console.log(err, 'err');
    },
  });

  const [updatePriceList] = useMutation(UPDATEPRICELIST, {
    onCompleted: (data) => {
      let updatedSelectedUnits = units.map((displayedUnit: any) => {
        let updatedUnit = data.priceListUpdateById.record.draft.find((updatedUnit: any) => displayedUnit._id === updatedUnit._id);

        if (updatedUnit) {
          return {
            ...displayedUnit,
            basePrice: updatedUnit.basePrice,
            status: updatedUnit.status,
            modelType: updatedUnit.modelType,
            unitType: updatedUnit.unitType,
            size: updatedUnit.size,
            exposure: updatedUnit.exposure,
            bathroom: updatedUnit.bathroom,
            outdoorSize: updatedUnit.outdoorSize,
          };
        } else return displayedUnit;
      });

      setEditOpen(false);
      setSelectingUnits(false);
      setSelectedUnits([]);
      setFilteredSelectedUnits([]);
      setUnits(updatedSelectedUnits);
      setOpenConfirm(false);
      storeDispatch(showSuccessSnackbar('Units Successfully Updated'));
    },
  });

  const setData = (data: IUnit[]) => {
    setPercentages(data.map((unit: IUnit) => 0));
    setAmountChange(data.map((unit: IUnit) => 0));
    setRentalChange(data.map((unit: IUnit) => (unit.rental ? unit.rental : 0)));
    setStatus(data.map((unit: IUnit) => unit.status));
    setChangedPrice(data.map((unit: IUnit) => unit.basePrice));
    setChangedModel(data.map((unit: IUnit) => unit.modelType));
    setChangedUnitType(data.map((unit: IUnit) => unit.unitType));
    setChangedAllocation(data.map((unit: IUnit) => unit));
    setFilteredSelectedUnits(data);
  };

  useEffect(() => {
    if (draft) {
      let filteredData = units.filter((unit: IUnit) => selectedUnits.some((selectedUnit: IUnit) => selectedUnit._id === unit._id));
      setData(filteredData);
    } else {
      setData(selectedUnits.filter((unit: IUnit) => displayType.includes(unit.status)));
    }
  }, [displayType, selectedUnits]);

  let editableKeyToFocus: any = useRef(null);

  const CustomPopper = function (props: any) {
    return <Popper open={true} {...props} style={{ width: 250 }} placement="bottom-start" />;
  };

  const unitColumns = useMemo(() => {
    let array = [
      {
        Header: 'Name',
        accessor: (rowData: IUnit) => rowData.suite,
      },
      {
        Header: 'Original Price',
        accessor: (rowData: IUnit) => {
          return numToCurrency.format(rowData.basePrice);
        },
      },
      {
        Header: 'New Price',
        accessor: (rowData: IUnit, index: number) => {
          const key = `price${index}`;
          return (
            <TextField
              size="small"
              fullWidth
              onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
              label={'New Price'}
              title={'New Price'}
              onBlur={() => (editableKeyToFocus.current = null)}
              onChange={(e: any) => {
                editableKeyToFocus.current = key;
                handleNewPrice(e, index, rowData.basePrice);
                setSelectionStart(e.target.selectionStart);
                setSelectionEnd(e.target.selectionEnd);
              }}
              value={changedPrice[index]}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              placeholder="Enter Price"
              required={true}
              onFocus={(e) => {
                e.target.selectionStart = selectionStart;
                e.target.selectionEnd = selectionEnd;
              }}
              autoFocus={key === editableKeyToFocus.current}
            />
          );
        },
      },
      {
        Header: 'Status',
        accessor: (rowData: IUnit) => {
          return <strong>{rowData.status}</strong>;
        },
      },
      {
        Header: 'Increase Price Percentage',
        accessor: (rowData: IUnit, index: number) => {
          const key = `percentage${index}`;
          return (
            <TextField
              size="small"
              fullWidth
              onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
              label={'Percentage'}
              title={'Percentage'}
              onBlur={() => (editableKeyToFocus.current = null)}
              onChange={(e: any) => {
                editableKeyToFocus.current = key;
                setSelectionStart(e.target.selectionStart);
                setSelectionEnd(e.target.selectionEnd);
                handlePercent(e, index, rowData.basePrice);
              }}
              value={percentages[index]}
              InputProps={{
                startAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
              placeholder="Enter Percentage"
              onFocus={(e) => {
                e.target.selectionStart = selectionStart;
                e.target.selectionEnd = selectionEnd;
              }}
              autoFocus={key === editableKeyToFocus.current}
            />
          );
        },
      },
      {
        Header: 'Increase Price Amount',
        accessor: (rowData: IUnit, index: number) => {
          const key = `amount${index}`;
          return (
            <TextField
              size="small"
              fullWidth
              onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
              label={'Amount'}
              title={'Amount'}
              type="number"
              onBlur={() => (editableKeyToFocus.current = null)}
              onChange={(e: any) => {
                editableKeyToFocus.current = key;
                setSelectionStart(e.target.selectionStart);
                setSelectionEnd(e.target.selectionEnd);
                handleAmountInput(e, index, rowData.basePrice);
              }}
              value={amountChange[index]}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onFocus={(e) => {
                e.target.selectionStart = selectionStart;
                e.target.selectionEnd = selectionEnd;
              }}
              autoFocus={key === editableKeyToFocus.current}
              placeholder="Enter Amount"
            />
          );
        },
      },
      {
        Header: 'New Status',
        accessor: (rowData: IUnit, index: number) => {
          return (
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                sx={{ width: '100% ' }}
                label={'Status'}
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status ? status[index] : ''}
                onChange={(e: any) => {
                  editableKeyToFocus.current = null;
                  handleDropdownChange(e, index);
                }}
              >
                {allStatus.map((status, index) => {
                  return (
                    <MenuItem key={index} value={status.code}>
                      {status.code}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          );
        },
      },
      {
        Header: 'Allocation',
        accessor: (rowData: IUnit, index: number) => {
          const key = `allocation${index}`;
          return (
            <Autocomplete
              sx={{
                width: '100%',
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }}
              id={'search'}
              disableClearable
              freeSolo={true}
              options={realtors.map((realtor: any) => realtor.fullName)}
              getOptionLabel={(option: any) => option.fullName || option}
              onBlur={() => (editableKeyToFocus.current = null)}
              // getOptionSelected={(option, value) => {
              //   return option.fullName === value;
              // }}
              openOnFocus={true}
              PopperComponent={CustomPopper}
              value={
                changedAllocation[index] && changedAllocation[index].allocation
                  ? changedAllocation[index].allocation.fullName
                  : changedAllocation[index].tempAllocation
                  ? `${changedAllocation[index].tempAllocation}`
                  : ''
              }
              onChange={handleSearchInput(index)}
              onInputChange={(e: any) => {
                if (e) {
                  editableKeyToFocus.current = key;
                }
                handleInputChange(e, index);
              }}
              renderInput={(params) => (
                <TextField
                  required={false}
                  {...params}
                  size="small"
                  label={'Search By Name'}
                  autoFocus={key === editableKeyToFocus.current}
                />
              )}
            />
          );
        },
      },
      {
        Header: 'Size',
        accessor: (rowData: IUnit) => {
          return <strong>{rowData.size}</strong>;
        },
      },
      {
        Header: 'Original PPSF',
        accessor: (rowData: IUnit) => {
          return <strong>{numToCurrency.format(rowData.basePrice / rowData.size)}</strong>;
        },
      },
      {
        Header: 'New PPSF',
        accessor: (rowData: IUnit, index: number) => {
          return <strong>{numToCurrency.format(changedPrice[index] / rowData.size)}</strong>;
        },
      },
    ];

    if (filteredSelectedUnits.length && filteredSelectedUnits[0].rental !== null) {
      let rental = {
        Header: 'Rental',
        accessor: (rowData: IUnit, index: number) => {
          const key = `rental${index}`;
          return (
            <TextField
              size="small"
              fullWidth
              required
              onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
              label={'Rental'}
              title={'Rental'}
              onBlur={() => (editableKeyToFocus.current = null)}
              onChange={(e: any) => {
                editableKeyToFocus.current = key;
                setSelectionStart(e.target.selectionStart);
                setSelectionEnd(e.target.selectionEnd);
                handleRentalInput(e, index, rowData.rental!);
              }}
              value={rentalChange[index]}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onFocus={(e) => {
                e.target.selectionStart = selectionStart;
                e.target.selectionEnd = selectionEnd;
              }}
              autoFocus={key === editableKeyToFocus.current}
              placeholder="Enter Rental Amount"
            />
          );
        },
      };
      array.splice(6, 0, rental);
    }
    return array;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedPrice, status, filteredSelectedUnits, rentalChange, changedAllocation]);

  const handleDisplayType = (type: string) => {
    if (type === 'firm') {
      setDisplayType(['F']);
    } else if (type === 'conditional') {
      setDisplayType(['C']);
    } else
      setDisplayType(
        allStatus.filter((status: IStatus) => status.code !== 'C' && status.code !== 'F').map((status: IStatus) => status.code)
      );
  };

  const handleDropdownChange = (event: React.ChangeEvent<{ value: unknown }>, index: number) => {
    const newStatus = status.map((status: any, i: number) => {
      if (index === i) {
        return event.target.value as string;
      } else {
        return status;
      }
    });
    setStatus(newStatus);
  };

  const handleSearchInput = (index: number) => (e: any, values: any) => {
    let selectedRealtor = realtors.find((realtor: any) => realtor.fullName === values);
    if (selectedRealtor) {
      const newAllocation = filteredSelectedUnits.map((unit, i) => {
        if (index === i) {
          return {
            ...unit,
            allocation: {
              _id: selectedRealtor?.realtor._id,
              fullName: selectedRealtor?.fullName,
            },
            tempAllocation: null,
          };
        } else if (changedAllocation[i]) {
          return {
            ...unit,
            tempAllocation: !changedAllocation[i].allocation ? changedAllocation[i].tempAllocation : '',
            allocation: changedAllocation[i].allocation ? changedAllocation[i].allocation : null,
          };
        } else return unit;
      });

      setChangedAllocation(newAllocation as any);
    }
  };

  const handleInputChange = (event: any, index: number) => {
    if (event && (event.target.value || event.target.value === '')) {
      searchRealtors({ variables: { filter: { type: 'Realtor', locked: false, search: event.target.value } } });
      const newAllocation = filteredSelectedUnits.map((unit, i) => {
        if (index === i) {
          return {
            ...unit,
            tempAllocation: event.target.value,
            allocation: null,
          };
        } else if (changedAllocation[i]) {
          return {
            ...unit,
            tempAllocation: !changedAllocation[i].allocation ? changedAllocation[i].tempAllocation : '',
            allocation: changedAllocation[i].allocation ? changedAllocation[i].allocation : null,
          };
        } else return unit;
      });
      setChangedAllocation(newAllocation as any);
    }
  };

  const handleAllAllocations = () => (e: any, values: any) => {
    let selectedRealtor = realtors.find((realtor: any) => realtor.fullName === values);
    if (selectedRealtor) {
      const newAllocation = filteredSelectedUnits.map((unit, i) => {
        return {
          ...unit,
          allocation: {
            _id: selectedRealtor?.realtor._id,
            fullName: selectedRealtor?.fullName,
          },
          tempAllocation: null,
        };
      });
      setChangedAllocation(newAllocation as any);
    }
  };

  const handleAllAllocationsChange = (event: any) => {
    if (event) {
      searchRealtors({ variables: { filter: { type: 'Realtor', locked: false, search: event.target.value } } });
      const newAllocation = filteredSelectedUnits.map((unit, i) => {
        return {
          ...unit,
          tempAllocation: event.target.value,
          allocation: null,
        };
      });
      setChangedAllocation(newAllocation as any);
    }
  };

  const submitChanges = (e: any) => {
    e.preventDefault();
    if (draft && selectedPriceList) {
      let updatedUnits = filteredSelectedUnits.map((updatedUnit: any, index: number) => {
        return {
          ...updatedUnit,
          basePrice: changedPrice[index],
          status: status[index],
          modelType: updatedUnit.modelType,
          unitType: updatedUnit.unitType,
          size: parseFloat(updatedUnit.size),
          exposure: updatedUnit.exposure,
          bathroom: parseFloat(updatedUnit.bathroom),
          outdoorSize: parseFloat(updatedUnit.outdoorSize),
          allocation: updatedUnit.allocation,
          tempAllocation: updatedUnit.tempAllocation,
        };
      });

      let allUnits = units.map((unit: IUnit) => {
        let selectedUnit = updatedUnits.find((updatedUnit: IUnit) => updatedUnit.suite === unit.suite);

        if (selectedUnit) {
          return selectedUnit;
        } else return unit;
      });

      updatePriceList({ variables: { _id: selectedPriceList._id, record: { draft: allUnits } } });
    } else {
      let updatedUnits = filteredSelectedUnits.map((updatedUnit: any, index: number) => {
        let history = {
          type: '',
          description: '',
          timestamp: new Date(),
          user: user._id,
        };
        let historyDescription = [];
        let historyType = [];
        let allocatedDate = updatedUnit.allocatedDate;
        let removeAllocations = false;

        if (changedPrice[index] !== filteredSelectedUnits[index].basePrice) {
          historyType.push('Price');
          historyDescription.push(
            `Price has been changed to from ${numToCurrency.format(updatedUnit.basePrice)} to ${numToCurrency.format(changedPrice[index])}`
          );
        }
        if (status[index] !== filteredSelectedUnits[index].status) {
          historyType.push('Status');
          historyDescription.push(`Status has been changed from ${updatedUnit.status} to ${status[index]}`);
        }

        if (
          changedAllocation[index].tempAllocation !== filteredSelectedUnits[index].tempAllocation &&
          !changedAllocation[index].allocation
        ) {
          historyType.push('Temporary Allocation');
          historyDescription.push(
            `Temporary Allocation has been changed from ${
              filteredSelectedUnits[index].tempAllocation ? filteredSelectedUnits[index].tempAllocation : 'None'
            } to ${changedAllocation[index].tempAllocation ? changedAllocation[index].tempAllocation : 'None'}`
          );
        }
        if (changedModel[index] !== filteredSelectedUnits[index].modelType) {
          historyType.push('Model');
          historyDescription.push(`Model Type has been changed from ${changedModel[index]} to ${updatedUnit.modelType}`);
        }
        if (rentalChange[index] !== filteredSelectedUnits[index].rental && filteredSelectedUnits[index].rental !== null) {
          historyType.push('Rental');
          historyDescription.push(
            `Rental has been changed from ${numToCurrency.format(updatedUnit.rental)} to ${numToCurrency.format(rentalChange[index])}`
          );
        }
        if (changedUnitType[index] !== filteredSelectedUnits[index].unitType) {
          historyType.push('Unit Type');
          historyDescription.push(`Unit Type has been changed from ${changedUnitType[index]} to ${updatedUnit.unitType}`);
        }

        if (changedAllocation[index].allocation !== filteredSelectedUnits[index].allocation) {
          historyType.push('Allocation');
          historyDescription.push(
            `Allocation has been changed from ${
              filteredSelectedUnits[index].allocation ? filteredSelectedUnits[index].allocation.fullName : 'None'
            } to ${changedAllocation[index].allocation ? changedAllocation[index].allocation.fullName : 'None'}`
          );
          if (selectedUnits[index].allocation && filteredSelectedUnits[index].allocation) {
            removeAllocations = true;
          }
          allocatedDate = new Date();
        }

        history.type = historyType.join(', ');
        history.description = historyDescription.join(', ');

        let updateUnitObject: any = {
          _id: updatedUnit._id,
          allocatedDate: allocatedDate,
          allocation:
            changedAllocation && changedAllocation[index] && changedAllocation[index].allocation
              ? changedAllocation[index].allocation._id
              : null,
          tempAllocation: changedAllocation && changedAllocation[index] ? changedAllocation[index].tempAllocation : null,
          basePrice: changedPrice[index],
          status: status[index],
          history: history,
          modelType: updatedUnit.modelType,
          unitType: updatedUnit.unitType,
          size: parseFloat(updatedUnit.size),
          exposure: updatedUnit.exposure,
          bathroom: parseFloat(updatedUnit.bathroom),
          outdoorSize: parseFloat(updatedUnit.outdoorSize),
          removeAllocations: removeAllocations,
        };

        if (updatedUnit.rental !== null) {
          updateUnitObject.rental = parseFloat(rentalChange[index]);
        }

        return updateUnitObject;
      });

      updateUnits({ variables: { unitArray: updatedUnits, project: project._id } });
    }
  };

  const handleAllText = (event: any) => {
    let allUnits = filteredSelectedUnits.map((unit: IUnit) => {
      return {
        ...unit,
        [event.target.name]: event.target.value,
      };
    });
    if (event.target.name === 'modelType') {
      setAllModels(event.target.value);
    } else setAllUnitTypes(event.target.value);
    setFilteredSelectedUnits(allUnits);
  };

  const handleAllAmountInput = (event: any, type: string) => {
    let newAmountChange: any;
    let newPercent: any;
    let newPrice: any;
    if (type === 'same') {
      newAmountChange = amountChange.map((amount: any) => {
        return event.target.value;
      });
      newPercent = percentages.map((amount: any, index: number) => {
        return Math.round((event.target.value / filteredSelectedUnits[index].basePrice) * 100000) / 1000;
      });
      newPrice = changedPrice.map((amount: any, index: number) => {
        return filteredSelectedUnits[index].basePrice + parseInt(event.target.value);
      });
      setAllAmount(event.target.value);
    } else if (type === 'level') {
      let lowestLevel = parseInt(filteredSelectedUnits[0].level);
      newAmountChange = amountChange.map((amount: any, index: number) => {
        return event.target.value * (parseInt(filteredSelectedUnits[index].level, 10) - lowestLevel + 1);
      });
      newPercent = percentages.map((amount: any, index: number) => {
        return (
          Math.round(
            ((event.target.value * (parseInt(filteredSelectedUnits[index].level, 10) - lowestLevel + 1)) /
              filteredSelectedUnits[index].basePrice) *
              100000
          ) / 1000
        );
      });
      newPrice = changedPrice.map((amount: any, index: number) => {
        return (
          filteredSelectedUnits[index].basePrice +
          parseInt(event.target.value) * (parseInt(filteredSelectedUnits[index].level, 10) - lowestLevel + 1)
        );
      });
      if (allSamePrice !== '0') {
        newPrice = changedPrice.map((amount: any, index: number) => {
          return (
            parseInt(allSamePrice, 10) + parseInt(event.target.value) * (parseInt(filteredSelectedUnits[index].level, 10) - lowestLevel + 1)
          );
        });
      }
      setAllFloorPremiums(event.target.value);
    } else if (type === 'samePrice') {
      newAmountChange = amountChange.map((amount: any) => {
        return parseInt(event.target.value);
      });
      newPercent = percentages.map((amount: any, index: number) => {
        return Math.round((event.target.value / filteredSelectedUnits[index].basePrice) * 100000) / 1000;
      });
      newPrice = changedPrice.map((amount: any, index: number) => {
        return parseInt(event.target.value);
      });
      setAllSamePrice(event.target.value);
    }
    setAmountChange(newAmountChange);
    setPercentages(newPercent);
    setChangedPrice(newPrice);
  };

  const handleAllSqFt = (event: any) => {
    if (event.target.value) {
      const newAmount = amountChange.map((amount: any, index: number) => {
        return Math.round(filteredSelectedUnits[index].size * parseInt(event.target.value) - filteredSelectedUnits[index].basePrice);
      });
      const newPrice = changedPrice.map((amount: any, index: number) => {
        return Math.round(filteredSelectedUnits[index].size * parseInt(event.target.value));
      });
      const newPercent = percentages.map((amount: any, index: number) => {
        return Math.round(
          ((filteredSelectedUnits[index].size * parseInt(event.target.value) - filteredSelectedUnits[index].basePrice) /
            filteredSelectedUnits[index].basePrice) *
            100
        );
      });
      setChangedPrice(newPrice);
      setAmountChange(newAmount);
      setPercentages(newPercent);
    } else {
      const newPrice = changedPrice.map((amount: any, index: number) => filteredSelectedUnits[index].basePrice);
      setChangedPrice(newPrice);
      setAmountChange(amountChange.map((amount: any) => 0));
      setPercentages(percentages.map((amount: any) => 0));
    }
    setAllSqFt(event.target.value);
  };

  const handleAllRental = (event: any, type: string) => {
    if (event) {
      if (type === 'sqft') {
        const newRental = rentalChange.map((rental: any, index: number) => {
          return Math.round(filteredSelectedUnits[index].size * parseFloat(event.target.value));
        });
        setRentalChange(newRental);
        setAllRental(event.target.value);
      } else if (type === 'price') {
        const newRental = rentalChange.map((rental: any, index: number) => {
          return parseFloat(event.target.value);
        });
        setRentalChange(newRental);
        setAllRentalSamePrice(event.target.value);
      }
    }
  };

  const handleAllPercent = (event: any) => {
    const newPercent = percentages.map((amount: any) => {
      return event.target.value;
    });
    const newAmount = amountChange.map((amount: any, index: number) => {
      return Math.round((event.target.value * filteredSelectedUnits[index].basePrice) / 100);
    });
    const newPrice = changedPrice.map((amount: any, index: number) => {
      return filteredSelectedUnits[index].basePrice + Math.round((event.target.value * filteredSelectedUnits[index].basePrice) / 100);
    });
    setChangedPrice(newPrice);
    setAmountChange(newAmount);
    setPercentages(newPercent);
    setAllPercent(event.target.value);
  };

  const handleAllStatus = (event: any) => {
    const allStatus = status.map((amount: any) => {
      return event.target.value;
    });
    setNewStatus(event.target.value);
    setStatus(allStatus);
  };

  const handleAllRound = (event: any) => {
    let newPrice = changedPrice;
    if (event.target.value === 'thousandsDown') {
      newPrice = changedPrice.map((amount: any, index: number) => {
        return Math.floor(amount / 1000) * 1000;
      });
    } else if (event.target.value === 'thousandsUp') {
      newPrice = changedPrice.map((amount: any, index: number) => {
        return Math.ceil(amount / 1000) * 1000;
      });
    } else if (event.target.value === 'hundredsDown') {
      newPrice = changedPrice.map((amount: any, index: number) => {
        return Math.floor(amount / 100) * 100;
      });
    } else if (event.target.value === 'hundredsUp') {
      newPrice = changedPrice.map((amount: any, index: number) => {
        return Math.ceil(amount / 100) * 100;
      });
    } else if (event.target.value === '999') {
      newPrice = changedPrice.map((amount: any, index: number) => {
        return Math.floor(amount / 1000) * 1000 + 999;
      });
    } else if (event.target.value === '990') {
      newPrice = changedPrice.map((amount: any, index: number) => {
        return Math.floor(filteredSelectedUnits[index].basePrice / 1000) * 1000 + 990;
      });
    }
    setChangedPrice(newPrice);
  };

  const handleAmountInput = (event: any, index: number, price: number) => {
    const newAmountChange = amountChange.map((amount: any, i: number) => {
      if (index === i) {
        return event.target.value;
      } else {
        return amount;
      }
    });
    const newPercent = percentages.map((amount: any, i: number) => {
      if (index === i) {
        return Math.round((event.target.value / price) * 100000) / 1000;
      } else {
        return amount;
      }
    });
    const newPrice = changedPrice.map((amount: any, i: number) => {
      if (index === i) {
        return price + parseInt(event.target.value);
      } else {
        return amount;
      }
    });
    setAmountChange(newAmountChange);
    setPercentages(newPercent);
    setChangedPrice(newPrice);
  };

  const handleNewPrice = (event: any, index: number, price: number) => {
    let onlyNumber = new RegExp('^\\d+$');
    let validNumber = onlyNumber.test(event.target.value);
    if (validNumber) {
      const newAmountChange = amountChange.map((amount: any, i: number) => {
        if (index === i) {
          return event.target.value - price;
        } else {
          return amount;
        }
      });
      const newPercent = percentages.map((amount: any, i: number) => {
        if (index === i) {
          return Math.round(((event.target.value - price) / price) * 100000) / 1000;
        } else {
          return amount;
        }
      });
      const newPrice = changedPrice.map((amount: any, i: number) => {
        if (index === i) {
          return parseInt(event.target.value);
        } else {
          return amount;
        }
      });
      setAmountChange(newAmountChange);
      setPercentages(newPercent);
      setChangedPrice(newPrice);
    }
  };

  const handlePercent = (event: any, index: number, price: number) => {
    let onlyNumber = new RegExp('^\\d+$');
    let validNumber = onlyNumber.test(event.target.value);
    if (validNumber) {
      const newPercent = percentages.map((amount: any, i: number) => {
        if (index === i) {
          return event.target.value;
        } else {
          return amount;
        }
      });
      const newAmount = amountChange.map((amount: any, i: number) => {
        if (index === i) {
          return Math.round((event.target.value * price) / 100);
        } else {
          return amount;
        }
      });
      const newPrice = changedPrice.map((amount: any, i: number) => {
        if (index === i) {
          return price + Math.round((event.target.value * price) / 100);
        } else {
          return amount;
        }
      });
      setChangedPrice(newPrice);
      setAmountChange(newAmount);
      setPercentages(newPercent);
    }
  };

  const handleRentalInput = (event: any, index: number, price: number) => {
    let onlyNumber = new RegExp('^\\d+$');
    let validNumber = onlyNumber.test(event.target.value);
    if (validNumber) {
      const newRentalChange = rentalChange.map((amount: any, i: number) => {
        if (index === i) {
          return event.target.value;
        } else {
          return amount;
        }
      });
      setRentalChange(newRentalChange);
    }
  };

  return unitLoading ? (
    <Box sx={{ position: 'relative' }}>
      <LoadingWrapper title="Units are being updated..." modal={false} />
    </Box>
  ) : (
    <Box
      sx={{
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: 'center',
            mt: 0,
            mb: 0,
          }}
          gutterBottom
        >
          <strong>Edit Selected</strong>
        </Typography>
        <Grid container spacing={2} sx={{ mt: 0.5 }}>
          <Grid item xs={6} sm={2}>
            <Button
              sx={{ width: '100%' }}
              variant="contained"
              color={!displayType.includes('C') && !displayType.includes('F') ? 'secondary' : 'primary'}
              onClick={() => handleDisplayType('notSold')}
            >
              Show Not Sold
            </Button>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Button
              sx={{ width: '100%' }}
              variant="contained"
              color={!displayType.includes('C') && displayType.includes('F') ? 'secondary' : 'primary'}
              onClick={() => handleDisplayType('firm')}
            >
              Show Firm
            </Button>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Button
              sx={{ width: '100%' }}
              variant="contained"
              color={displayType.includes('C') && !displayType.includes('F') ? 'secondary' : 'primary'}
              onClick={() => handleDisplayType('conditional')}
            >
              Show Conditional
            </Button>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Button sx={{ width: '100%' }} variant="contained" color="primary" onClick={() => setEditColumnOpen(true)}>
              Edit Columns
            </Button>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Button
              sx={{ width: '100%' }}
              form="submitForm"
              variant="contained"
              color="success"
              onClick={() => {
                setOpenConfirm(true);
              }}
            >
              Update Units
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          mt: 3,
        }}
      >
        <Grid item md={2} sm={3} xs={6}>
          <TextField
            size="small"
            key={'allUnitsPercentage'}
            fullWidth
            type="number"
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            label={'Increase All Units (Percentage)'}
            title={'Increase All Units (Percentage)'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleAllPercent(e);
            }}
            value={allPercent}
            InputProps={{
              startAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            placeholder="Enter Percentage"
            required={true}
            autoFocus={false}
          />
        </Grid>
        <Grid item md={2} sm={3} xs={6}>
          <TextField
            size="small"
            fullWidth
            key={'allUnitsAmount'}
            type="number"
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            label={'Increase All Units'}
            title={'Increase All Units'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleAllAmountInput(e, 'same');
            }}
            value={allAmount}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            placeholder="Enter Amount"
            required={true}
          />
        </Grid>
        <Grid item md={2} sm={3} xs={6}>
          <TextField
            size="small"
            key={'allUnitsSqft'}
            fullWidth
            type="number"
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            label={'Set All By SQFT'}
            title={'Set All By SQFT'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleAllSqFt(e);
            }}
            value={allSqFt}
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
            placeholder="Enter Amount"
            required={true}
          />
        </Grid>
        {checkSameModel ? (
          <Grid item md={2} sm={3} xs={6}>
            <TextField
              size="small"
              key={'allUnitsFloorPrems'}
              fullWidth
              type="number"
              onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
              label={'Increase Amount by Floor Premiums'}
              title={'Increase Amount by Floor Premiums'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleAllAmountInput(e, 'level');
              }}
              value={allFloorPremiums}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              placeholder="Enter Amount"
              required={true}
            />
          </Grid>
        ) : null}
        {checkSameModel ? (
          <Grid item md={2} sm={3} xs={6}>
            <TextField
              size="small"
              key={'allUnitsModels'}
              fullWidth
              type="number"
              onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
              label={'Change all Models to Same Price'}
              title={'Change all Models to Same Price'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleAllAmountInput(e, 'samePrice');
              }}
              value={allSamePrice}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              placeholder="Enter Amount"
              required={true}
            />
          </Grid>
        ) : null}
        {columns.modelType ? (
          <Grid item md={2} sm={3} xs={6}>
            <TextField
              size="small"
              key={'allModels'}
              fullWidth
              type="number"
              label={'Change All Selected Units By Model'}
              title={'Change All Selected Units By Model'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleAllText(e);
              }}
              value={allModels}
              placeholder="Enter Text"
              required={true}
            />
          </Grid>
        ) : null}
        {columns.unitType ? (
          <Grid item md={2} sm={3} xs={6}>
            <TextField
              size="small"
              key={'allUnitTypes'}
              fullWidth
              type="number"
              label={'Change All Selected Units By Unit Types'}
              title={'Change All Selected Units By Unit Types'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleAllText(e);
              }}
              value={allUnitTypes}
              placeholder="Enter Text"
              required={true}
            />
          </Grid>
        ) : null}
        <Grid item md={2} sm={3} xs={6}>
          <FormControl size="small" sx={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-label">Change All Statuses</InputLabel>
            <Select
              size="small"
              label="Change All Statuses"
              sx={{ width: '100%' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={newStatus}
              onChange={(e: any) => {
                handleAllStatus(e);
              }}
            >
              {allStatus.map((status, index) => {
                return (
                  <MenuItem key={index} value={status.code}>
                    {status.code}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} sm={3} xs={6}>
          <FormControl size="small" sx={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-label">Round All Prices</InputLabel>
            <Select
              size="small"
              label="Round All Prices"
              sx={{ width: '100%' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={newStatus}
              onChange={(e: any) => {
                handleAllRound(e);
              }}
            >
              <MenuItem value={'thousandsDown'}>Thousands (Down)</MenuItem>
              <MenuItem value={'thousandsUp'}>Thousands (Up)</MenuItem>
              <MenuItem value={'hundredsDown'}>Hundreds (Down)</MenuItem>
              <MenuItem value={'hundredsUp'}>Hundreds (Up)</MenuItem>
              <MenuItem value={'990'}>End with $990</MenuItem>
              <MenuItem value={'999'}>End with $999</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} sm={3} xs={6}>
          <FormControl sx={{ width: '100%' }}>
            <TextField
              size="small"
              key={'rentalsqft'}
              fullWidth
              type="number"
              onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
              label={'Set Rental By SQFT'}
              title={'Set Rental By SQFT'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleAllRental(e, 'sqft');
              }}
              value={allRental}
              placeholder="Enter Amount"
              required={true}
            />
          </FormControl>
        </Grid>
        <Grid item md={2} sm={3} xs={6}>
          <FormControl sx={{ width: '100%' }}>
            <TextField
              size="small"
              key={'rentalPrice'}
              fullWidth
              type="number"
              onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
              label={'Set Rental to Same Price'}
              title={'Set Rental to Same Price'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleAllRental(e, 'price');
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              value={allRentalSamePrice}
              placeholder="Enter Amount"
              required={true}
            />
          </FormControl>
        </Grid>
        <Grid item md={2} sm={3} xs={6}>
          <Autocomplete
            sx={{
              width: '100%',
              '& .MuiFormLabel-asterisk': {
                color: 'red',
              },
            }}
            id={'search'}
            disableClearable
            freeSolo={true}
            options={realtors.map((realtor: any) => realtor.fullName)}
            getOptionLabel={(option: any) => option.fullName || option}
            onBlur={() => (editableKeyToFocus.current = null)}
            // getOptionSelected={(option, value) => {
            //   return option.fullName === value;
            // }}
            openOnFocus={true}
            PopperComponent={CustomPopper}
            value={allAllocations}
            onChange={handleAllAllocations()}
            onInputChange={(e: any) => {
              handleAllAllocationsChange(e);
            }}
            renderInput={(params) => <TextField required={false} {...params} size="small" label={'Set All Allocations'} />}
          />
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      {filteredSelectedUnits.length ? (
        <Grid container spacing={2}>
          <Grid sm={3} xs={6} item>
            Count: <strong>{filteredSelectedUnits.length}</strong>
          </Grid>
          <Grid sm={3} xs={6} item>
            Total Original Price:{' '}
            <strong>{numToCurrency.format(filteredSelectedUnits.reduce((a: any, b: any) => a + b.basePrice, 0))}</strong>
          </Grid>
          <Grid sm={3} xs={6} item>
            Total New Price: <strong>{numToCurrency.format(changedPrice.reduce((a: any, b: any) => a + b, 0))}</strong>
          </Grid>
          <Grid sm={3} xs={6} item>
            Percentage Changed:{' '}
            <strong>
              {(
                ((changedPrice.reduce((a: any, b: any) => a + b, 0) -
                  filteredSelectedUnits.reduce((a: any, b: any) => a + b.basePrice, 0)) /
                  filteredSelectedUnits.reduce((a: any, b: any) => a + b.basePrice, 0)) *
                100
              ).toFixed(2)}
              %
            </strong>
          </Grid>
          <Grid sm={3} xs={6} item>
            Total Difference:{' '}
            <strong>
              {numToCurrency.format(
                changedPrice.reduce((a: any, b: any) => a + b, 0) - filteredSelectedUnits.reduce((a: any, b: any) => a + b.basePrice, 0)
              )}
            </strong>
          </Grid>
          <Grid sm={3} xs={6} item>
            Average Original PPSF{' '}
            <strong>
              {numToCurrency.format(
                filteredSelectedUnits.reduce((a: any, b: any) => a + b.basePrice, 0) /
                  filteredSelectedUnits.reduce((a: any, b: any) => a + b.size, 0)
              )}
            </strong>
          </Grid>
          <Grid sm={3} xs={6} item>
            Average New PPSF{' '}
            <strong>
              {numToCurrency.format(
                changedPrice.reduce((a: any, b: any) => a + b, 0) / filteredSelectedUnits.reduce((a: any, b: any) => a + b.size, 0)
              )}
            </strong>
          </Grid>
          <Grid sm={3} xs={6} item>
            Average Size{' '}
            <strong>
              {numberWithCommas(filteredSelectedUnits.reduce((a: any, b: any) => a + b.size, 0) / filteredSelectedUnits.length)}
            </strong>
          </Grid>
        </Grid>
      ) : null}
      <form
        id="submitForm"
        style={{
          marginTop: '16px',
        }}
        onSubmit={submitChanges}
      >
        <Box>
          <form
            id="submitForm"
            style={{
              display: 'flex',
              height: '90%',
            }}
            onSubmit={submitChanges}
          >
            <VirtualizedTable columns={unitColumns} data={filteredSelectedUnits} itemHeight={100} tableHeight={450} />
          </form>
        </Box>
        <Modal
          open={editColumnOpen}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClose={() => setEditColumnOpen(false)}
          closeAfterTransition
        >
          <div>
            <EditColumns columns={columns} setColumns={setColumns} />
          </div>
        </Modal>
        <Dialog
          open={openConfirm}
          onClose={() => {
            setOpenConfirm(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Confirm Changes?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Confirm the changes for the selected units?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenConfirm(false);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button type="submit" form="submitForm" color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </Box>
  );
};

const GETREALTORS = gql`
  query userMany($filter: FilterFindManyUserInput) {
    userMany(filter: $filter, limit: 10000) {
      _id
      firstName
      lastName
      fullName
      realtor {
        _id
      }
      locked
    }
  }
`;

const UNITS = gql`
  query unitMany($filter: FilterFindManyUnitInput) {
    unitMany(filter: $filter, limit: 10000) {
      _id
      suite
      modelType
      unitType
      bathroom
      size
      unit
      outdoorType
      basePrice
      level
      exposure
      outdoorSize
      col
      row
      stackCol
      stackRow
      status
      allocation {
        fullName
        _id
      }
      allocatedDate
      tempAllocation
      custom
      flag
      tier
      rental
      type
    }
  }
`;

const UPDATEUNIT = gql`
  mutation unitUpdateManyInfo($unitArray: [NewUnitInput!], $project: MongoID!) {
    unitUpdateManyInfo(unitArray: $unitArray, project: $project) {
      _id
      allocation {
        fullName
        _id
      }
      allocatedDate
      tempAllocation
      basePrice
      status
      modelType
      unitType
      size
      exposure
      bathroom
      outdoorSize
      worksheets {
        _id
      }
      rental
    }
  }
`;

const UPDATEPRICELIST = gql`
  mutation priceListUpdateById($_id: MongoID!, $record: UpdateByIdPriceListInput!) {
    priceListUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        project {
          _id
        }
        draft {
          _id
          status
          suite
          unit
          unitId
          level
          modelType
          unitType
          basePrice
          size
          outdoorType
          outdoorSize
          bathroom
          exposure
          col
          row
          stackCol
          stackRow
        }
      }
    }
  }
`;

export default EditSelected;
