import React from 'react';
import parseISO from 'date-fns/parseISO';
import { PDFDocument, rgb, StandardFonts, degrees, grayscale, PageSizes } from 'pdf-lib';
import { format, addDays, subDays, subHours, addHours } from 'date-fns';
import { CsvBuilder } from 'filefy';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import { IUnit } from '../types/unit';
import { IDocuments } from '../types/docusign';
import { IDeal, IDealAdjustment, IDealOption } from '../types/CreateDealForm';
import { IPurchaserInfo, IRealtorInfo, IDealDeposit } from '../types/CreateDealForm';
import ExcelJS from 'exceljs';
import { IUpgrade } from '../types/colourSelection';
import { mainCategories, randomColors, unitStatusTitles, unitTypesColour } from './Constants';
import { IProject, IStatus } from '../types/project';

export function stringSplit(string: string) {
  let stringArray = string.split(' ');
  return stringArray;
}

export function isLetter(string: string) {
  let letter = string.charAt(string.length - 1);
  if (letter.match(/[a-z]/i)) {
    return string.slice(0, -1).toString();
  } else {
    return string;
  }
}

export const formatPhoneNumber = (value: string) => {
  let num = value.replace(/[^\d]/g, '');
  return `${
    num.length > 3 && num.length < 11 ? `${num.slice(0, 3)}-${num.length > 6 ? `${num.slice(3, 6)}-${num.slice(6)}` : num.slice(3)}` : num
  }`;
};

export const formatSin = (value: string) => {
  let num = value.replace(/[^\d]/g, '');
  return `${
    num.length > 3 && num.length < 10 ? `${num.slice(0, 3)} ${num.length > 6 ? `${num.slice(3, 6)} ${num.slice(6)}` : num.slice(3)}` : num
  }`;
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function camelToTitle(string: string) {
  const result = string.replace(/([A-Z])/g, ' $1');
  const titleCase = result.charAt(0).toUpperCase() + result.slice(1);
  return titleCase;
}

export function uppercaseToTitle(string: string) {
  const output = string.replace(/(\w)(\w*)/g, (_, firstChar, rest) => firstChar + rest.toLowerCase());

  return output;
}

export const normalToCamel = (string: string) => {
  return string.toLocaleLowerCase().replace(/\s+(.)/g, function (match, group) {
    return group.toUpperCase();
  });
};

export function uintToString(uintArray: any) {
  var encodedString = String.fromCharCode.apply(null, uintArray),
    decodedString = decodeURIComponent(escape(atob(encodedString)));
  return decodedString;
}

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export function dateDiffInDays(a: Date, b: Date) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export const urlName = (string: string) => {
  return string.replace(/\s+/g, '-').toLowerCase();
};

export const numberWithCommas = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numToCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const numToCurrencyDecimal = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const convertDate = (timestamp: any) => {
  return format(parseISO(timestamp), 'PPPP HH:mm:ss');
};

export const convertDDMMYYYY = (timestamp: any) => {
  return format(parseISO(timestamp), 'dd/MM/yyyy');
};

export const convertDateType = (timestamp: any, type: string) => {
  if (!timestamp) {
    if (type === 'day') {
      return format(new Date(), 'dd');
    } else if (type === 'year') {
      return format(new Date(), 'yy');
    } else if (type === 'month') {
      return format(new Date(), 'LLLL');
    }
  } else {
    if (type === 'day') {
      return format(parseISO(timestamp), 'dd');
    } else if (type === 'year') {
      return format(parseISO(timestamp), 'yy');
    } else if (type === 'month') {
      return format(parseISO(timestamp), 'LLLL');
    }
  }
};

export const convertAllDates = (timestamp: any, dateFormat: string) => {
  return format(new Date(timestamp), dateFormat);
};

export const getKeyByValue = (object: any, value: any) => {
  return `${Object.keys(object).find((key) => object[key] === value)}`;
};

export const calculateOptions = (mergeValue: string, project: any, options: any = null, toOptions: any = null) => {
  if (mergeValue === 'OP1' || mergeValue === 'OP1Price' || mergeValue === 'OP1Num') {
    let selectedOption = options.find((option: any) => project.options[0].name === option.name);
    if (selectedOption) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'OP2' || mergeValue === 'OP2Price' || mergeValue === 'OP2Num') {
    let selectedOption = options.find((option: any) => project.options[1].name === option.name);
    if (selectedOption) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'OP3' || mergeValue === 'OP3Price' || mergeValue === 'OP3Num') {
    let selectedOption = options.find((option: any) => project.options[2].name === option.name);
    if (selectedOption) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'OP4' || mergeValue === 'OP4Price' || mergeValue === 'OP4Num') {
    let selectedOption = options.find((option: any) => project.options[3].name === option.name);
    if (selectedOption) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'OP5' || mergeValue === 'OP5Price' || mergeValue === 'OP5Num') {
    let selectedOption = options.find((option: any) => project.options[4].name === option.name);
    if (selectedOption) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'OP6' || mergeValue === 'OP6Price' || mergeValue === 'OP6Num') {
    let selectedOption = options.find((option: any) => project.options[5].name === option.name);
    if (selectedOption) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'OP7' || mergeValue === 'OP7Price' || mergeValue === 'OP7Num') {
    let selectedOption = options.find((option: any) => project.options[6].name === option.name);
    if (selectedOption) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'OP8' || mergeValue === 'OP8Price' || mergeValue === 'OP8Num') {
    let selectedOption = options.find((option: any) => project.options[7].name === option.name);
    if (selectedOption) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'OP9' || mergeValue === 'OP9Price' || mergeValue === 'OP9Num') {
    let selectedOption = options.find((option: any) => project.options[8].name === option.name);
    if (selectedOption) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'OP10' || mergeValue === 'OP10Price' || mergeValue === 'OP10Num') {
    let selectedOption = options.find((option: any) => project.options[9].name === option.name);
    if (selectedOption) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'toOP1' || mergeValue === 'toOP1Price' || mergeValue === 'toOP1Num') {
    let selectedOption = toOptions.find((option: any) => project.options[0].name === option.name);
    if (selectedOption && selectedOption.purchaseAmount) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'toOP2' || mergeValue === 'toOP2Price' || mergeValue === 'toOP2Num') {
    let selectedOption = toOptions.find((option: any) => project.options[1].name === option.name);
    if (selectedOption && selectedOption.purchaseAmount) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'toOP3' || mergeValue === 'toOP3Price' || mergeValue === 'toOP3Num') {
    let selectedOption = toOptions.find((option: any) => project.options[2].name === option.name);
    if (selectedOption && selectedOption.purchaseAmount) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'toOP4' || mergeValue === 'toOP4Price' || mergeValue === 'toOP4Num') {
    let selectedOption = toOptions.find((option: any) => project.options[3].name === option.name);
    if (selectedOption && selectedOption.purchaseAmount) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'toOP5' || mergeValue === 'toOP5Price' || mergeValue === 'toOP5Num') {
    let selectedOption = toOptions.find((option: any) => project.options[4].name === option.name);
    if (selectedOption && selectedOption.purchaseAmount) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'toOP6' || mergeValue === 'toOP6Price' || mergeValue === 'toOP6Num') {
    let selectedOption = toOptions.find((option: any) => project.options[5].name === option.name);
    if (selectedOption && selectedOption.purchaseAmount) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'toOP7' || mergeValue === 'toOP7Price' || mergeValue === 'toOP7Num') {
    let selectedOption = toOptions.find((option: any) => project.options[6].name === option.name);
    if (selectedOption && selectedOption.purchaseAmount) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'toOP8' || mergeValue === 'toOP8Price' || mergeValue === 'toOP8Num') {
    let selectedOption = toOptions.find((option: any) => project.options[7].name === option.name);
    if (selectedOption && selectedOption.purchaseAmount) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'toOP9' || mergeValue === 'toOP9Price' || mergeValue === 'toOP9Num') {
    let selectedOption = toOptions.find((option: any) => project.options[8].name === option.name);
    if (selectedOption && selectedOption.purchaseAmount) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  } else if (mergeValue === 'toOP10' || mergeValue === 'toOP10Price' || mergeValue === 'toOP10Num') {
    let selectedOption = toOptions.find((option: any) => project.options[9].name === option.name);
    if (selectedOption && selectedOption.purchaseAmount) {
      return {
        purchaseNumber: `${selectedOption.purchaseAmount}`,
        purchaseAmount: `${selectedOption.purchaseAmount} (${numberToEnglish(selectedOption.purchaseAmount)})`,
        amount: numToCurrency.format(selectedOption.amount),
      };
    } else
      return {
        purchaseNumber: `0`,
        purchaseAmount: '0 (NIL)',
        amount: project.defaultZeroValue ? project.defaultZeroValue : '$0',
      };
  }
};

const capitalizeDifference = (type: string) => {
  if (type === 'email') {
    return 'Email';
  } else if (type === 'fullName') {
    return 'Full Name';
  } else if (type === 'fullAddress') {
    return 'Full Address';
  } else if (type === 'dob') {
    return 'Date of Birth';
  } else if (type === 'address') {
    return 'Address';
  } else if (type === 'city') {
    return 'City';
  } else if (type === 'province') {
    return 'Province';
  } else if (type === 'country') {
    return 'Country';
  } else if (type === 'primaryPhone') {
    return 'Phone Number';
  } else if (type === 'sin') {
    return 'SIN';
  }
};

export const calculateCommission = (mergeValue: string, project: any, totalPrice: number, unit: any, realtor: any) => {
  let price = unit.type === 'commercial' ? unit.basePrice : netHST(unit.basePrice);
  if (project.commissionIncludeOptions) {
    price = unit.type === 'commercial' ? totalPrice : netHST(totalPrice);
  }
  if (realtor.coopRates.length === 0) {
    return '';
  }
  if (mergeValue === 'CommissionPercentAmount1') {
    return numToCurrencyDecimal.format(parseFloat(((realtor.coopRates[0].percentage / 100) * price).toFixed(2)));
  } else if (mergeValue === 'CommissionPercentAmount2') {
    return numToCurrencyDecimal.format(parseFloat(((realtor.coopRates[1].percentage / 100) * price).toFixed(2)));
  } else if (mergeValue === 'CommissionPercentAmount3') {
    return numToCurrencyDecimal.format(parseFloat(((realtor.coopRates[2].percentage / 100) * price).toFixed(2)));
  } else if (mergeValue === 'CommissionPercentAmount4') {
    return numToCurrencyDecimal.format(parseFloat(((realtor.coopRates[3].percentage / 100) * price).toFixed(2)));
  } else if (mergeValue === 'CommissionPercentAmount5') {
    return numToCurrencyDecimal.format(parseFloat(((realtor.coopRates[4].percentage / 100) * price).toFixed(2)));
  } else if (mergeValue === 'CommissionPercentAmount6') {
    return numToCurrencyDecimal.format(parseFloat(((realtor.coopRates[5].percentage / 100) * price).toFixed(2)));
  } else if (mergeValue === 'CommissionFixed1') {
    return numToCurrencyDecimal.format(
      parseFloat(((realtor.coopRates[0].percentage / 100) * price - realtor.coopRates[0].fixedAmount).toFixed(2))
    );
  } else if (mergeValue === 'CommissionFixed2') {
    return numToCurrencyDecimal.format(
      parseFloat(((realtor.coopRates[1].percentage / 100) * price - realtor.coopRates[1].fixedAmount).toFixed(2))
    );
  } else if (mergeValue === 'CommissionFixed3') {
    return numToCurrencyDecimal.format(
      parseFloat(((realtor.coopRates[2].percentage / 100) * price - realtor.coopRates[2].fixedAmount).toFixed(2))
    );
  } else if (mergeValue === 'CommissionFixed4') {
    return numToCurrencyDecimal.format(
      parseFloat(((realtor.coopRates[3].percentage / 100) * price - realtor.coopRates[3].fixedAmount).toFixed(2))
    );
  } else if (mergeValue === 'CommissionFixed5') {
    return numToCurrencyDecimal.format(
      parseFloat(((realtor.coopRates[4].percentage / 100) * price - realtor.coopRates[4].fixedAmount).toFixed(2))
    );
  } else if (mergeValue === 'CommissionFixed6') {
    return numToCurrencyDecimal.format(
      parseFloat(((realtor.coopRates[5].percentage / 100) * price - realtor.coopRates[5].fixedAmount).toFixed(2))
    );
  }
};

export const getPurchaserInfo = (value: any, type: string) => {
  if (type === 'fullName') {
    return `${value.firstName} ${value.lastName}`;
  } else if (type === 'fullAddress') {
    return `${value.streetAddress}, ${value.city}, ${value.province}, ${value.postalCode ? value.postalCode : ''}`;
  } else if (type === 'dob') {
    return convertAllDates(value.dob, 'P');
  } else return value[type];
};

export const getMergeValues = (
  mergeValue: any,
  project: any,
  unit: any,
  mergeType: any = null,
  purchaser: any,
  deposit: any,
  options: any = null,
  realtor: any = null,
  toUnit: any = null,
  toOptions: any = null,
  toDeposits: any = null,
  mergeTemplates: any = null,
  deal: any = null,
  toPurchaser: any = [],
  colourSelectionChoices: any = null
) => {
  let totalOption = 0;
  let toTotalOption = 0;
  let schedule = '';
  let unitBasePrice = unit.basePrice;
  let commission: any = '';
  let soPurchaser: any = null;

  let newOptions = calculateOptions(mergeValue.key, project, options, toOptions);

  if (mergeValue.key === 'H' || mergeValue.key === 'J') {
    schedule = mergeTemplates.find((mergeTemplate: any) => mergeTemplate.name.indexOf(mergeValue.key));
    if (schedule) {
      return mergeValue.key;
    } else return '';
  }

  let upgradeSetOne = 0;
  let upgradeSetTwo = 0;
  let upgradeSetThree = 0;
  let upgradeCreditOne = 0;
  let upgradeCreditTwo = 0;
  let upgradeCreditThree = 0;
  let decorTotalSetOne = 0;
  let decorTotalSetTwo = 0;
  let decorTotalSetThree = 0;
  let toDecorTotalSetOne = 0;
  let toDecorTotalSetTwo = 0;
  let toDecorTotalSetThree = 0;

  totalOption = options.reduce((a: any, b: any) => {
    return a + b.amount;
  }, unit.basePrice);

  if (deal) {
    if (deal.upgradeSetOne) {
      upgradeSetOne = deal.upgradeSetOne.upgradeSet
        .filter((upgrade: IUpgrade) => upgrade.subCategory !== 'Credit')
        .reduce((a: any, b: any) => {
          return a + b.price;
        }, 0);
      let credit = deal.upgradeSetOne.upgradeSet.find((upgrade: IUpgrade) => upgrade.subCategory === 'Credit');
      upgradeCreditOne = credit ? credit.price : 0;
      upgradeSetOne -= upgradeCreditOne;

      decorTotalSetOne = totalOption + upgradeSetOne * 1.13;
    }
    if (deal.upgradeSetTwo) {
      upgradeSetTwo = deal.upgradeSetTwo.upgradeSet
        .filter((upgrade: IUpgrade) => upgrade.subCategory !== 'Credit')
        .reduce((a: any, b: any) => {
          return a + b.price;
        }, 0);
      let credit = deal.upgradeSetTwo.upgradeSet.find((upgrade: IUpgrade) => upgrade.subCategory === 'Credit');
      upgradeCreditTwo = credit ? credit.price : 0;
      upgradeSetTwo -= upgradeCreditTwo;

      decorTotalSetTwo = totalOption + upgradeSetTwo * 1.13;
    }
    if (deal.upgradeSetThree) {
      upgradeSetThree = deal.upgradeSetThree.upgradeSet
        .filter((upgrade: IUpgrade) => upgrade.subCategory !== 'Credit')
        .reduce((a: any, b: any) => {
          return a + b.price;
        }, 0);
      let credit = deal.upgradeSetThree.upgradeSet.find((upgrade: IUpgrade) => upgrade.subCategory === 'Credit');
      upgradeCreditThree = credit ? credit.price : 0;
      upgradeSetThree -= upgradeCreditThree;

      decorTotalSetThree = totalOption + upgradeSetThree * 1.13;
    }

    totalOption += upgradeSetOne * 1.13 + upgradeSetTwo * 1.13 + upgradeSetThree * 1.13;
  }

  if (realtor && realtor.coopRates && realtor.coopRates.length) {
    commission = calculateCommission(mergeValue.key, project, totalOption, unit, realtor);
  }

  if (toUnit) {
    unitBasePrice = toUnit.basePrice;
    toTotalOption = toOptions.reduce((a: any, b: any) => {
      return a + b.amount;
    }, unitBasePrice);
  } else {
    if (toOptions) {
      toTotalOption = toOptions.reduce((a: any, b: any) => {
        return a + b.amount;
      }, unitBasePrice);

      if (deal.upgradeSetOne) {
        upgradeSetOne = deal.upgradeSetOne.upgradeSet
          .filter((upgrade: IUpgrade) => upgrade.subCategory !== 'Credit')
          .reduce((a: any, b: any) => {
            return a + b.price;
          }, 0);
        let credit = deal.upgradeSetOne.upgradeSet.find((upgrade: IUpgrade) => upgrade.subCategory === 'Credit');
        upgradeCreditOne = credit ? credit.price : 0;
        upgradeSetOne -= upgradeCreditOne;

        toDecorTotalSetOne = toTotalOption + upgradeSetOne * 1.13;
      }
      if (deal.upgradeSetTwo) {
        upgradeSetTwo = deal.upgradeSetTwo.upgradeSet
          .filter((upgrade: IUpgrade) => upgrade.subCategory !== 'Credit')
          .reduce((a: any, b: any) => {
            return a + b.price;
          }, 0);
        let credit = deal.upgradeSetTwo.upgradeSet.find((upgrade: IUpgrade) => upgrade.subCategory === 'Credit');
        upgradeCreditTwo = credit ? credit.price : 0;
        upgradeSetTwo -= upgradeCreditTwo;

        toDecorTotalSetTwo = toTotalOption + upgradeSetTwo * 1.13;
      }
      if (deal.upgradeSetThree) {
        upgradeSetThree = deal.upgradeSetThree.upgradeSet
          .filter((upgrade: IUpgrade) => upgrade.subCategory !== 'Credit')
          .reduce((a: any, b: any) => {
            return a + b.price;
          }, 0);
        let credit = deal.upgradeSetThree.upgradeSet.find((upgrade: IUpgrade) => upgrade.subCategory === 'Credit');
        upgradeCreditThree = credit ? credit.price : 0;
        upgradeSetThree -= upgradeCreditThree;

        toDecorTotalSetThree = toTotalOption + upgradeSetThree * 1.13;
      }

      toTotalOption += upgradeSetOne * 1.13 + upgradeSetTwo * 1.13 + upgradeSetThree * 1.13;
    } else {
      toTotalOption = options.reduce((a: any, b: any) => {
        return a + b.amount;
      }, unitBasePrice);

      if (deal) {
        if (deal.upgradeSetOne) {
          upgradeSetOne = deal.upgradeSetOne.upgradeSet
            .filter((upgrade: IUpgrade) => upgrade.subCategory !== 'Credit')
            .reduce((a: any, b: any) => {
              return a + b.price;
            }, 0);
          let credit = deal.upgradeSetOne.upgradeSet.find((upgrade: IUpgrade) => upgrade.subCategory === 'Credit');
          upgradeCreditOne = credit ? credit.price : 0;
          upgradeSetOne -= upgradeCreditOne;
        }
        if (deal.upgradeSetTwo) {
          upgradeSetTwo = deal.upgradeSetTwo.upgradeSet
            .filter((upgrade: IUpgrade) => upgrade.subCategory !== 'Credit')
            .reduce((a: any, b: any) => {
              return a + b.price;
            }, 0);
          let credit = deal.upgradeSetTwo.upgradeSet.find((upgrade: IUpgrade) => upgrade.subCategory === 'Credit');
          upgradeCreditTwo = credit ? credit.price : 0;
          upgradeSetTwo -= upgradeCreditTwo;
        }
        if (deal.upgradeSetThree) {
          upgradeSetThree = deal.upgradeSetThree.upgradeSet
            .filter((upgrade: IUpgrade) => upgrade.subCategory !== 'Credit')
            .reduce((a: any, b: any) => {
              return a + b.price;
            }, 0);
          let credit = deal.upgradeSetThree.upgradeSet.find((upgrade: IUpgrade) => upgrade.subCategory === 'Credit');
          upgradeCreditThree = credit ? credit.price : 0;
          upgradeSetThree -= upgradeCreditThree;
        }
      }

      toTotalOption += upgradeSetOne * 1.13 + upgradeSetTwo * 1.13 + upgradeSetThree * 1.13;
    }
  }

  let differences: any[] = [];

  if (purchaser && toPurchaser) {
    differences = toPurchaser.map((newPurchaser: IPurchaserInfo, index: number) => {
      let oldPurchaser = purchaser[index];
      let difference: any = Object.keys(newPurchaser).filter(
        (k: any) => newPurchaser[k as keyof typeof newPurchaser] !== oldPurchaser[k as keyof typeof oldPurchaser]
      );
      let allDifferences = difference
        .map((difference: any) => {
          if (difference === 'firstName' || difference === 'lastName') {
            return {
              purchaserNumber: index + 1,
              type: 'fullName',
            };
          } else if (
            difference === 'streetAddress' ||
            difference === 'city' ||
            difference === 'province' ||
            difference === 'country' ||
            difference === 'postalCode'
          ) {
            return {
              purchaserNumber: index + 1,
              type: 'fullAddress',
            };
          } else if (difference === 'sin' || difference === 'email' || difference === 'primaryPhone' || difference === 'dob') {
            return {
              purchaserNumber: index + 1,
              type: difference,
            };
          } else return null;
        })
        .filter((difference: any) => difference !== null);

      let seen = new Set();
      let filteredDifferences = allDifferences.filter((difference: any) => {
        const key = difference.purchaserNumber + '\u0000' + difference.type;
        //                       ^---- a string you know won't be in either name or surname
        const keep = !seen.has(key);
        if (keep) {
          seen.add(key);
        }
        return keep;
      });
      return filteredDifferences;
    });
    differences = [].concat.apply([], differences);
  }

  if (purchaser) {
    soPurchaser = purchaser.find((purchaser: IPurchaserInfo) => purchaser.corp);
  }

  let checkP2 = false;
  let checkP3 = false;
  let checkP4 = false;
  let maintenance;

  if (deal) {
    if (purchaser.length > 1 && mergeType) {
      let addPurchaserDocument = deal.documents.find(
        (document: IDocuments) =>
          (document.type === 'AddP2' || document.type === 'AddP2P3') &&
          mergeType.type !== 'AddP2' &&
          mergeType.type !== 'AddP2P3' &&
          document.status !== 'Completed'
      );
      if (addPurchaserDocument) {
        checkP2 = true;
      }
    }
    if (purchaser.length > 2 && mergeType) {
      let addPurchaserDocument = deal.documents.find(
        (document: IDocuments) =>
          (document.type === 'AddP3' || document.type === 'AddP3P4' || document.type === 'AddP2P3') &&
          mergeType.type !== 'AddP3' &&
          mergeType.type !== 'AddP2P3' &&
          mergeType.type !== 'AddP3P4' &&
          document.status !== 'Completed'
      );
      if (addPurchaserDocument) {
        checkP3 = true;
      }
    }
    if (purchaser.length > 3 && mergeType) {
      let addPurchaserDocument = deal.documents.find(
        (document: IDocuments) =>
          (document.type === 'AddP3P4' || document.type === 'AddP4') &&
          mergeType.type !== 'AddP4' &&
          mergeType.type !== 'AddP3P4' &&
          document.status !== 'Completed'
      );
      if (addPurchaserDocument) {
        checkP4 = true;
      }
    }
    maintenance = deal.adjustments.find((adjustment: IDealAdjustment) => adjustment.adjustmentType === 'FreeMaintenance');
  }

  // Check Add Purchaser Amendments to see if it should include Additional Purchasers

  switch (mergeValue.key) {
    case 'signature':
      return 'Signature';
    case 'initial':
      return 'Initial';
    case 'date':
      return 'Date';
    case 'Suite':
      return unit?.suite;
    case 'Unit':
      return unit?.unit;
    case 'Level':
      return unit?.level;
    case 'ModelType':
      return unit?.modelType;
    case 'ModelName':
      return unit?.modelName;
    case 'Tier':
      return unit?.tier ? unit.tier : '';
    case 'SignDate':
      return deal && deal.signDate ? convertAllDates(deal.signDate, mergeValue.format) : '';
    case 'ExecuteDate':
      return deal && deal.executeDate ? convertAllDates(deal.executeDate, mergeValue.format) : '';
    case '1PFN':
      return purchaser[0].firstName;
    case '1PLN':
      return purchaser[0].lastName;
    case '1PLNCaps':
      return purchaser[0].lastName.toUpperCase();
    case '1PfullName':
      return `${purchaser[0].firstName} ${purchaser[0].lastName}`;
    case '1PfullNameLCaps':
      return `${purchaser[0].firstName} ${purchaser[0].lastName.toUpperCase()}`;
    case '&':
      return purchaser.length >= 2 ? '&' : '';
    case 'FullAddress':
      return `${purchaser[0].unit ? purchaser[0].unit : ''} ${purchaser[0].streetAddress}, ${purchaser[0].city}, ${
        purchaser[0].province
      }, ${purchaser[0].postalCode ? purchaser[0].postalCode : ''}`;
    case 'Address':
      return `${purchaser[0].unit ? purchaser[0].unit : ''} ${purchaser[0].streetAddress}`;
    case 'City':
      return purchaser[0].city;
    case 'Province':
      return purchaser[0].province;
    case 'Country':
      return purchaser[0].country;
    case 'PCode':
      return purchaser[0].postalCode;
    case 'Email':
      return purchaser[0].email;
    case 'Dob':
      return convertAllDates(purchaser[0].dob, mergeValue.format);
    case 'SIN':
      return purchaser[0].sin ? purchaser[0].sin : '';
    case 'Phone':
      return purchaser[0].primaryPhone;
    case 'Occupation':
      return purchaser[0].occupation;
    case 'Company':
      return purchaser[0].company ? purchaser[0].company : '';
    case 'IDType':
      return purchaser[0].idType;
    case 'IDJurisdiction':
      return purchaser[0].idJurisdiction;
    case 'IDNumber':
      return purchaser[0].idNumber;
    case 'IDExpiry':
      return convertAllDates(purchaser[0].idExpiry, mergeValue.format);
    case '2PDob':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 ? convertAllDates(purchaser[1].dob, mergeValue.format) : '';
    case '2PFN':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 ? purchaser[1].firstName : '';
    case '2PLN':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 ? purchaser[1].lastName : '';
    case '2PLNCaps':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 ? purchaser[1].lastName.toUpperCase() : '';
    case '2PfullName':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 ? `${purchaser[1].firstName} ${purchaser[1].lastName}` : '';
    case '2PfullNameLCaps':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 ? `${purchaser[1].firstName} ${purchaser[1].lastName.toUpperCase()}` : '';
    case '2PSIN':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 ? purchaser[1].sin : '';
    case '2PFullAddress':
      if (checkP2) {
        return '';
      } else
        return purchaser.length >= 2
          ? `${purchaser[1].unit ? purchaser[1].unit : ''} ${purchaser[1].streetAddress}, ${purchaser[1].city}, ${purchaser[1].province}, ${
              purchaser[1].postalCode ? purchaser[1].postalCode : ''
            }`
          : '';
    case '2PAddress':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 ? `${purchaser[1].unit ? purchaser[1].unit : ''} ${purchaser[1].streetAddress}` : '';
    case '2PCity':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 ? purchaser[1].city : '';
    case '2PProvince':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 ? purchaser[1].province : '';
    case '2PPCode':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 ? purchaser[1].postalCode : '';
    case '2PPhone':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 ? purchaser[1].primaryPhone : '';
    case '2PEmail':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 ? purchaser[1].email : '';
    case '2PIDNumber':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 ? purchaser[1].idNumber : '';
    case '1PNonCorpDob':
      return !purchaser[0].corp ? convertAllDates(purchaser[0].dob, mergeValue.format) : '';
    case '1PNonCorpFN':
      return !purchaser[0].corp ? purchaser[0].firstName : '';
    case '1PNonCorpLN':
      return !purchaser[0].corp ? purchaser[0].lastName : '';
    case '1PNonCorpLNCaps':
      return !purchaser[0].corp ? purchaser[0].lastName.toUpperCase() : '';
    case '1PNonCorpfullName':
      return !purchaser[0].corp ? `${purchaser[0].firstName} ${purchaser[0].lastName}` : '';
    case '1PfullNameNonCorpLCaps':
      return !purchaser[0].corp ? `${purchaser[0].firstName} ${purchaser[0].lastName.toUpperCase()}` : '';
    case '1PNonCorpSIN':
      return !purchaser[0].corp ? purchaser[0].sin : '';
    case '1PNonCorpFullAddress':
      return !purchaser[0].corp
        ? `${purchaser[0].unit ? purchaser[0].unit : ''} ${purchaser[0].streetAddress}, ${purchaser[0].city}, ${purchaser[0].province}, ${
            purchaser[0].postalCode ? purchaser[0].postalCode : ''
          }`
        : '';
    case '1PNonCorpAddress':
      return !purchaser[0].corp ? `${purchaser[0].unit ? purchaser[0].unit : ''} ${purchaser[0].streetAddress}` : '';
    case '1PNonCorpCity':
      return !purchaser[0].corp ? purchaser[0].city : '';
    case '1PNonCorpProvince':
      return !purchaser[0].corp ? purchaser[0].province : '';
    case '1PNonCorpCountry':
      return !purchaser[0].corp ? purchaser[0].country : '';
    case '1PNonCorpPCode':
      return !purchaser[0].corp ? purchaser[0].postalCode : '';
    case '1PNonCorpPhone':
      return !purchaser[0].corp ? purchaser[0].primaryPhone : '';
    case '1PNonCorpEmail':
      return !purchaser[0].corp ? purchaser[0].email : '';
    case '2PNonCorpDob':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 && !purchaser[1].corp ? convertAllDates(purchaser[1].dob, mergeValue.format) : '';
    case '2PNonCorpFN':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 && !purchaser[1].corp ? purchaser[1].firstName : '';
    case '2PNonCorpLN':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 && !purchaser[1].corp ? purchaser[1].lastName : '';
    case '2PNonCorpLNCaps':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 && !purchaser[1].corp ? purchaser[1].lastName.toUpperCase() : '';
    case '2PNonCorpfullName':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 && !purchaser[1].corp ? `${purchaser[1].firstName} ${purchaser[1].lastName}` : '';
    case '2PfullNameNonCorpLCaps':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 && !purchaser[1].corp ? `${purchaser[1].firstName} ${purchaser[1].lastName.toUpperCase()}` : '';
    case '2PNonCorpSIN':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 && !purchaser[1].corp ? purchaser[1].sin : '';
    case '2PNonCorpFullAddress':
      if (checkP2) {
        return '';
      } else
        return purchaser.length >= 2 && !purchaser[1].corp
          ? `${purchaser[1].unit ? purchaser[1].unit : ''} ${purchaser[1].streetAddress}, ${purchaser[1].city}, ${purchaser[1].province}, ${
              purchaser[1].postalCode ? purchaser[1].postalCode : ''
            }`
          : '';
    case '2PNonCorpAddress':
      if (checkP2) {
        return '';
      } else
        return purchaser.length >= 2 && !purchaser[1].corp
          ? `${purchaser[1].unit ? purchaser[1].unit : ''} ${purchaser[1].streetAddress}`
          : '';
    case '2PNonCorpCity':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 && !purchaser[1].corp ? purchaser[1].city : '';
    case '2PNonCorpProvince':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 && !purchaser[1].corp ? purchaser[1].province : '';
    case '2PNonCorpCountry':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 && !purchaser[1].corp ? purchaser[1].country : '';
    case '2PNonCorpPCode':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 && !purchaser[1].corp ? purchaser[1].postalCode : '';
    case '2PNonCorpPhone':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 && !purchaser[1].corp ? purchaser[1].primaryPhone : '';
    case '2PNonCorpEmail':
      if (checkP2) {
        return '';
      } else return purchaser.length >= 2 && !purchaser[1].corp ? purchaser[1].email : '';
    case '3PFN':
      if (checkP3) {
        return '';
      } else return purchaser.length >= 3 ? purchaser[2].firstName : '';
    case '3PLN':
      if (checkP3) {
        return '';
      } else return purchaser.length >= 3 ? purchaser[2].lastName : '';
    case '3PLNCaps':
      if (checkP3) {
        return '';
      } else return purchaser.length >= 3 ? purchaser[2].lastName.toUpperCase() : '';
    case '3PfullName':
      if (checkP3) {
        return '';
      } else return purchaser.length >= 3 ? `${purchaser[2].firstName} ${purchaser[2].lastName}` : '';
    case '3PfullNameLCaps':
      if (checkP3) {
        return '';
      } else return purchaser.length >= 3 ? `${purchaser[2].firstName} ${purchaser[2].lastName.toUpperCase()}` : '';
    case '3PDob':
      if (checkP3) {
        return '';
      } else return purchaser.length >= 3 ? convertAllDates(purchaser[2].dob, mergeValue.format) : '';
    case '3PSIN':
      if (checkP3) {
        return '';
      } else return purchaser.length >= 3 ? purchaser[2].sin : '';
    case '3PFullAddress':
      if (checkP3) {
        return '';
      } else
        return purchaser.length >= 3
          ? `${purchaser[2].unit ? purchaser[2].unit : ''} ${purchaser[2].streetAddress}, ${purchaser[2].city}, ${purchaser[2].province}, ${
              purchaser[2].postalCode ? purchaser[2].postalCode : ''
            }`
          : '';
    case '3PAddress':
      if (checkP3) {
        return '';
      } else return purchaser.length >= 3 ? `${purchaser[2].unit ? purchaser[2].unit : ''} ${purchaser[2].streetAddress}` : '';
    case '3PCity':
      if (checkP3) {
        return '';
      } else return purchaser.length >= 3 ? purchaser[2].city : '';
    case '3PProvince':
      if (checkP3) {
        return '';
      } else return purchaser.length >= 3 ? purchaser[2].province : '';
    case '3PPCode':
      if (checkP3) {
        return '';
      } else return purchaser.length >= 3 ? purchaser[2].postalCode : '';
    case '3PPhone':
      if (checkP3) {
        return '';
      } else return purchaser.length >= 3 ? purchaser[2].primaryPhone : '';
    case '3PEmail':
      if (checkP3) {
        return '';
      } else return purchaser.length >= 3 ? purchaser[2].email : '';
    case '3PIDNumber':
      if (checkP3) {
        return '';
      } else return purchaser.length >= 3 ? purchaser[2].idNumber : '';
    case '4PFN':
      if (checkP4) {
        return '';
      } else return purchaser.length >= 4 ? purchaser[3].firstName : '';
    case '4PLN':
      if (checkP4) {
        return '';
      } else return purchaser.length >= 4 ? purchaser[3].lastName : '';
    case '4PLNCaps':
      if (checkP4) {
        return '';
      } else return purchaser.length >= 4 ? purchaser[3].lastName.toUpperCase() : '';
    case '4PfullName':
      if (checkP4) {
        return '';
      } else return purchaser.length >= 4 ? `${purchaser[3].firstName} ${purchaser[3].lastName}` : '';
    case '4PfullNameLCaps':
      if (checkP4) {
        return '';
      } else return purchaser.length >= 4 ? `${purchaser[3].firstName} ${purchaser[3].lastName.toUpperCase()}` : '';
    case '4PDob':
      if (checkP4) {
        return '';
      } else return purchaser.length >= 4 ? convertAllDates(purchaser[3].dob, mergeValue.format) : '';
    case '4PSIN':
      if (checkP4) {
        return '';
      } else return purchaser.length >= 4 ? purchaser[3].sin : '';
    case '4PFullAddress':
      if (checkP4) {
        return '';
      } else
        return purchaser.length >= 4
          ? `${purchaser[3].unit ? purchaser[3].unit : ''} ${purchaser[3].streetAddress}, ${purchaser[3].city}, ${purchaser[3].province}, ${
              purchaser[3].postalCode ? purchaser[3].postalCode : ''
            }`
          : '';
    case '4PAddress':
      if (checkP4) {
        return '';
      } else return purchaser.length >= 4 ? `${purchaser[3].unit ? purchaser[3].unit : ''} ${purchaser[3].streetAddress}` : '';
    case '4PCity':
      if (checkP4) {
        return '';
      } else return purchaser.length >= 4 ? purchaser[3].city : '';
    case '4PProvince':
      if (checkP4) {
        return '';
      } else return purchaser.length >= 4 ? purchaser[3].province : '';
    case '4PPCode':
      if (checkP4) {
        return '';
      } else return purchaser.length >= 4 ? purchaser[3].postalCode : '';
    case '4PPhone':
      if (checkP4) {
        return '';
      } else return purchaser.length >= 4 ? purchaser[3].primaryPhone : '';
    case '4PEmail':
      if (checkP4) {
        return '';
      } else return purchaser.length >= 4 ? purchaser[3].email : '';
    case '4PIDNumber':
      if (checkP4) {
        return '';
      } else return purchaser.length >= 4 ? purchaser[3].idNumber : '';
    case 'SalesRep':
      return deal.salesRep ? deal.salesRep.fullName : 'Brian Shew';
    case 'ProjectAddress':
      return `${project.addresses[0].streetAddress}, ${project.addresses[0].city}, ${project.addresses[0].province}, ${
        project.addresses[0].postalCode ? project.addresses[0].postalCode : ''
      }`;
    case 'ProjectAddressSuite':
      return `${unit?.suite}-${project.addresses[0].streetAddress}, ${project.addresses[0].city}, ${project.addresses[0].province}, ${
        project.addresses[0].postalCode ? project.addresses[0].postalCode : ''
      }`;
    case 'ProjectStreetAddress':
      return `${project.addresses[0].streetAddress}`;
    case 'ProjectCity':
      return `${project.addresses[0].city}`;
    case 'ProjectProvince':
      return `${project.addresses[0].province}`;
    case 'ProjectPCode':
      return `${project.addresses[0].postalCode}`;
    case 'Directors':
      return `${purchaser[0].directors}`;
    case '1P2PfullName':
      if (checkP2) {
        return `${purchaser[0].firstName} ${purchaser[0].lastName}`;
      } else
        return purchaser.length >= 2
          ? `${purchaser[0].firstName} ${purchaser[0].lastName} & ${purchaser[1].firstName} ${purchaser[1].lastName}`
          : `${purchaser[0].firstName} ${purchaser[0].lastName}`;
    case '1P3PfullName':
      if (checkP3) {
        return `${purchaser[0].firstName} ${purchaser[0].lastName}`;
      } else
        return purchaser.length >= 3
          ? `${purchaser[0].firstName} ${purchaser[0].lastName} & ${purchaser[2].firstName} ${purchaser[2].lastName}`
          : `${purchaser[0].firstName} ${purchaser[0].lastName}`;
    case '2P3PfullName':
      if (checkP2 && checkP3) {
        return ``;
      } else
        return purchaser.length >= 3
          ? `${purchaser[1].firstName} ${purchaser[1].lastName} & ${purchaser[2].firstName} ${purchaser[2].lastName}`
          : `${purchaser[0].firstName} ${purchaser[0].lastName}`;
    case '1P2P3PfullName':
      if (checkP2 && checkP3) {
        return ``;
      } else
        return purchaser.length >= 3
          ? `${purchaser[0].firstName} ${purchaser[0].lastName} & ${purchaser[1].firstName} ${purchaser[1].lastName} & ${purchaser[2].firstName} ${purchaser[2].lastName}`
          : `${purchaser[0].firstName} ${purchaser[0].lastName}`;
    case '3P4PfullName':
      if (checkP3 && checkP4) {
        return ``;
      } else
        return purchaser.length >= 4
          ? `${purchaser[2].firstName} ${purchaser[2].lastName} & ${purchaser[3].firstName} ${purchaser[3].lastName}`
          : `${purchaser[0].firstName} ${purchaser[0].lastName}`;
    case '1P2PfullNameLCaps':
      if (checkP2) {
        return `${purchaser[0].firstName} ${purchaser[0].lastName.toUpperCase()}`;
      } else
        return purchaser.length >= 2
          ? `${purchaser[0].firstName} ${purchaser[0].lastName.toUpperCase()} & ${
              purchaser[1].firstName
            } ${purchaser[1].lastName.toUpperCase()}`
          : `${purchaser[0].firstName} ${purchaser[0].lastName.toUpperCase()}`;
    case 'AllPfullName':
      if (checkP3 && checkP4) {
        return `${purchaser[0].firstName} ${purchaser[0].lastName} & ${purchaser[1].firstName} ${purchaser[1].lastName}`;
      } else if (checkP4) {
        return `${purchaser[0].firstName} ${purchaser[0].lastName} & ${purchaser[1].firstName} ${purchaser[1].lastName} & ${purchaser[2].firstName} ${purchaser[2].lastName}`;
      } else if (checkP3 && checkP2) {
        return `${purchaser[0].firstName} ${purchaser[0].lastName}`;
      } else if (checkP3) {
        return `${purchaser[0].firstName} ${purchaser[0].lastName} & ${purchaser[1].firstName} ${purchaser[1].lastName}`;
      } else if (checkP2) {
        return `${purchaser[0].firstName} ${purchaser[0].lastName}`;
      } else return purchaser.map((purchaser: IPurchaserInfo) => `${purchaser.firstName} ${purchaser.lastName}`).join(' & ');
    case 'AllPfullNameLCaps':
      if (checkP4) {
        return `${purchaser[0].firstName} ${purchaser[0].lastName.toUpperCase()} & ${
          purchaser[1].firstName
        } ${purchaser[1].lastName.toUpperCase()} & ${purchaser[2].firstName} ${purchaser[2].lastName.toUpperCase()}`;
      } else if (checkP3) {
        return `${purchaser[0].firstName} ${purchaser[0].lastName.toUpperCase()} & ${
          purchaser[1].firstName
        } ${purchaser[1].lastName.toUpperCase()}`;
      } else if (checkP2) {
        return `${purchaser[0].firstName} ${purchaser[0].lastName.toUpperCase()}`;
      } else return purchaser.map((purchaser: IPurchaserInfo) => `${purchaser.firstName} ${purchaser.lastName.toUpperCase()}`).join(' & ');
    case 'SO1fullName':
      return soPurchaser && soPurchaser.signingOfficers.length > 0 ? `${soPurchaser.signingOfficers[0].fullName}` : '';
    case 'SO1FullAddress':
      return soPurchaser && soPurchaser.signingOfficers.length > 0 ? `${soPurchaser.signingOfficers[0].streetAddress}` : '';
    case 'SO1Dob':
      return soPurchaser && soPurchaser.signingOfficers.length > 0
        ? `${convertAllDates(soPurchaser.signingOfficers[0].dob, mergeValue.format)}`
        : '';
    case 'SO1Email':
      return soPurchaser && soPurchaser.signingOfficers.length > 0 ? `${soPurchaser.signingOfficers[0].email}` : '';
    case 'SO1SIN':
      return soPurchaser && soPurchaser.signingOfficers.length > 0 ? `${soPurchaser.signingOfficers[0].sin}` : '';
    case 'SO1Phone':
      return soPurchaser && soPurchaser.signingOfficers.length > 0 ? `${soPurchaser.signingOfficers[0].primaryPhone}` : '';
    case 'SO2fullName':
      return soPurchaser && soPurchaser.signingOfficers.length > 1 ? `${soPurchaser.signingOfficers[1].fullName}` : '';
    case 'SO2FullAddress':
      return soPurchaser && soPurchaser.signingOfficers.length > 1 ? `${soPurchaser.signingOfficers[1].streetAddress}` : '';
    case 'SO2Dob':
      return soPurchaser && soPurchaser.signingOfficers.length > 1
        ? `${convertAllDates(soPurchaser.signingOfficers[1].dob, mergeValue.format)}`
        : '';
    case 'SO2Email':
      return soPurchaser && soPurchaser.signingOfficers.length > 1 ? `${soPurchaser.signingOfficers[1].email}` : '';
    case 'SO2SIN':
      return soPurchaser && soPurchaser.signingOfficers.length > 1 ? `${soPurchaser.signingOfficers[1].sin}` : '';
    case 'SO2Phone':
      return soPurchaser && soPurchaser.signingOfficers.length > 1 ? `${soPurchaser.signingOfficers[1].primaryPhone}` : '';
    case 'SO1SO2fullName':
      return soPurchaser && soPurchaser.signingOfficers.length >= 2
        ? `${soPurchaser.signingOfficers[0].fullName} & ${soPurchaser.signingOfficers[1].fullName}`
        : `${soPurchaser.signingOfficers[0].fullName}`;
    case 'ConditionalP1CorpOrPurchaserFullName':
      return soPurchaser && purchaser[0].corp && soPurchaser.email === purchaser[0].email
        ? ''
        : `${purchaser[0].firstName} ${purchaser[0].lastName}`;
    case 'ConditionalP2CorpOrPurchaserFullName':
      return soPurchaser && purchaser.length > 0 && purchaser[1].corp && soPurchaser.email === purchaser[1].email
        ? ''
        : `${purchaser[1].firstName} ${purchaser[1].lastName}`;
    case 'ConditionalCorpName':
      return soPurchaser ? `${soPurchaser.firstName} ${soPurchaser.lastName}` : '';
    case 'ConditionalCorpDob':
      return soPurchaser ? `${convertAllDates(soPurchaser.dob, mergeValue.format)}` : '';
    case 'ConditionalCorpPhone':
      return soPurchaser ? `${soPurchaser.primaryPhone}` : '';
    case 'ConditionalCorpAddress':
      return soPurchaser
        ? `${soPurchaser.streetAddress}, ${soPurchaser.city}, ${soPurchaser.province}, ${
            soPurchaser.postalCode ? soPurchaser.postalCode : ''
          }`
        : '';
    case 'ConditionalCorpEmail':
      return soPurchaser ? `${soPurchaser.email}` : '';
    case 'ConditionalCorpNumber':
      return soPurchaser ? `${soPurchaser.idNumber}` : '';
    case 'MinMortgage':
      return numToCurrency.format(totalOption - deposit.reduce((accum: any, item: any) => accum + item.amount, 0));
    case 'Deposit1':
      return deposit.length >= 1 ? numToCurrency.format(deposit[0].amount).toString() : '';
    case 'Deposit1W':
      return deposit.length >= 1 ? numberToEnglish(deposit[0].amount) : '';
    case 'Deposit1Date':
      return deposit.length >= 1 ? convertAllDates(deposit[0].dueDate, mergeValue.format) : convertAllDates(new Date(), mergeValue.format);
    case 'Deposit1DateWords':
      return deposit.length >= 1 ? convertAllDates(deposit[0].dueDate, mergeValue.format) : convertAllDates(new Date(), mergeValue.format);
    case 'Deposit1%':
      return deposit.length >= 1 ? `${((deposit[0].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit2':
      return deposit.length >= 2 ? numToCurrency.format(deposit[1].amount).toString() : '';
    case 'Deposit2W':
      return deposit.length >= 2 ? numberToEnglish(deposit[1].amount) : '';
    case 'Deposit2Date':
      return deposit.length >= 2 ? convertAllDates(deposit[1].dueDate, mergeValue.format) : '';
    case 'Deposit2DateWords':
      return deposit.length >= 2 ? convertAllDates(deposit[1].dueDate, mergeValue.format) : convertAllDates(new Date(), mergeValue.format);
    case 'Deposit2%':
      return deposit.length >= 2 ? `${(((deposit[1].amount + deposit[0].amount) / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit3':
      return deposit.length >= 3 ? numToCurrency.format(deposit[2].amount).toString() : '';
    case 'Deposit3W':
      return deposit.length >= 3 ? numberToEnglish(deposit[2].amount) : '';
    case 'Deposit3Date':
      return deposit.length >= 3 ? convertAllDates(deposit[2].dueDate, mergeValue.format) : '';
    case 'Deposit3DateWords':
      return deposit.length >= 3 ? convertAllDates(deposit[2].dueDate, mergeValue.format) : convertAllDates(new Date(), mergeValue.format);
    case 'Deposit3%':
      return deposit.length >= 3 ? `${((deposit[2].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit4':
      return deposit.length >= 4 ? numToCurrency.format(deposit[3].amount).toString() : '';
    case 'Deposit4W':
      return deposit.length >= 4 ? numberToEnglish(deposit[3].amount) : '';
    case 'Deposit4Date':
      return deposit.length >= 4 ? convertAllDates(deposit[3].dueDate, mergeValue.format) : '';
    case 'Deposit4DateWords':
      return deposit.length >= 4 ? convertAllDates(deposit[3].dueDate, mergeValue.format) : convertAllDates(new Date(), mergeValue.format);
    case 'Deposit4%':
      return deposit.length >= 4 ? `${((deposit[3].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit5':
      return deposit.length >= 5 ? numToCurrency.format(deposit[4].amount).toString() : '';
    case 'Deposit5W':
      return deposit.length >= 5 ? numberToEnglish(deposit[4].amount) : '';
    case 'Deposit5Date':
      return deposit.length >= 5 ? convertAllDates(deposit[4].dueDate, mergeValue.format) : '';
    case 'Deposit5DateWords':
      return deposit.length >= 5 ? convertAllDates(deposit[4].dueDate, mergeValue.format) : convertAllDates(new Date(), mergeValue.format);
    case 'Deposit5%':
      return deposit.length >= 5 ? `${((deposit[4].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit6':
      return deposit.length >= 6 ? numToCurrency.format(deposit[5].amount).toString() : '';
    case 'Deposit6W':
      return deposit.length >= 6 ? numberToEnglish(deposit[5].amount) : '';
    case 'Deposit6Date':
      return deposit.length >= 6 ? convertAllDates(deposit[5].dueDate, mergeValue.format) : '';
    case 'Deposit6DateWords':
      return deposit.length >= 6 ? convertAllDates(deposit[5].dueDate, mergeValue.format) : convertAllDates(new Date(), mergeValue.format);
    case 'Deposit6%':
      return deposit.length >= 6 ? `${((deposit[5].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit7':
      return deposit.length >= 7 ? numToCurrency.format(deposit[6].amount).toString() : '';
    case 'Deposit7W':
      return deposit.length >= 7 ? numberToEnglish(deposit[6].amount) : '';
    case 'Deposit7Date':
      return deposit.length >= 7 ? convertAllDates(deposit[6].dueDate, mergeValue.format) : '';
    case 'Deposit7DateWords':
      return deposit.length >= 7 ? convertAllDates(deposit[6].dueDate, mergeValue.format) : convertAllDates(new Date(), mergeValue.format);
    case 'Deposit7%':
      return deposit.length >= 7 ? `${((deposit[6].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit8':
      return deposit.length >= 8 ? numToCurrency.format(deposit[7].amount).toString() : '';
    case 'Deposit8W':
      return deposit.length >= 8 ? numberToEnglish(deposit[7].amount) : '';
    case 'Deposit8Date':
      return deposit.length >= 8 ? convertAllDates(deposit[7].dueDate, mergeValue.format) : '';
    case 'Deposit8DateWords':
      return deposit.length >= 8 ? convertAllDates(deposit[7].dueDate, mergeValue.format) : convertAllDates(new Date(), mergeValue.format);
    case 'Deposit8%':
      return deposit.length >= 8 ? `${((deposit[7].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit9':
      return deposit.length >= 9 ? numToCurrency.format(deposit[8].amount).toString() : '';
    case 'Deposit9W':
      return deposit.length >= 9 ? numberToEnglish(deposit[8].amount) : '';
    case 'Deposit9Date':
      return deposit.length >= 9 ? convertAllDates(deposit[8].dueDate, mergeValue.format) : '';
    case 'Deposit9DateWords':
      return deposit.length >= 9 ? convertAllDates(deposit[8].dueDate, mergeValue.format) : convertAllDates(new Date(), mergeValue.format);
    case 'Deposit9%':
      return deposit.length >= 9 ? `${((deposit[8].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit10':
      return deposit.length >= 10 ? numToCurrency.format(deposit[9].amount).toString() : '';
    case 'Deposit10W':
      return deposit.length >= 10 ? numberToEnglish(deposit[9].amount) : '';
    case 'Deposit10Date':
      return deposit.length >= 10 ? convertAllDates(deposit[9].dueDate, mergeValue.format) : '';
    case 'Deposit10DateWords':
      return deposit.length >= 10 ? convertAllDates(deposit[9].dueDate, mergeValue.format) : convertAllDates(new Date(), mergeValue.format);
    case 'Deposit10%':
      return deposit.length >= 10 ? `${((deposit[9].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit11':
      return deposit.length >= 11 ? numToCurrency.format(deposit[10].amount).toString() : '';
    case 'Deposit11W':
      return deposit.length >= 11 ? numberToEnglish(deposit[10].amount) : '';
    case 'Deposit11Date':
      return deposit.length >= 11 ? convertAllDates(deposit[10].dueDate, mergeValue.format) : '';
    case 'Deposit11%':
      return deposit.length >= 11 ? `${((deposit[10].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit12':
      return deposit.length >= 12 ? numToCurrency.format(deposit[11].amount).toString() : '';
    case 'Deposit12W':
      return deposit.length >= 12 ? numberToEnglish(deposit[11].amount) : '';
    case 'Deposit12Date':
      return deposit.length >= 12 ? convertAllDates(deposit[11].dueDate, mergeValue.format) : '';
    case 'Deposit12%':
      return deposit.length >= 12 ? `${((deposit[11].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit13':
      return deposit.length >= 13 ? numToCurrency.format(deposit[12].amount).toString() : '';
    case 'Deposit13W':
      return deposit.length >= 13 ? numberToEnglish(deposit[12].amount) : '';
    case 'Deposit13Date':
      return deposit.length >= 13 ? convertAllDates(deposit[12].dueDate, mergeValue.format) : '';
    case 'Deposit13%':
      return deposit.length >= 13 ? `${((deposit[12].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit14':
      return deposit.length >= 14 ? numToCurrency.format(deposit[13].amount).toString() : '';
    case 'Deposit14W':
      return deposit.length >= 14 ? numberToEnglish(deposit[13].amount) : '';
    case 'Deposit14Date':
      return deposit.length >= 14 ? convertAllDates(deposit[13].dueDate, mergeValue.format) : '';
    case 'Deposit14%':
      return deposit.length >= 14 ? `${((deposit[13].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit15':
      return deposit.length >= 15 ? numToCurrency.format(deposit[14].amount).toString() : '';
    case 'Deposit15W':
      return deposit.length >= 15 ? numberToEnglish(deposit[14].amount) : '';
    case 'Deposit15Date':
      return deposit.length >= 15 ? convertAllDates(deposit[14].dueDate, mergeValue.format) : '';
    case 'Deposit15%':
      return deposit.length >= 15 ? `${((deposit[14].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit16':
      return deposit.length >= 16 ? numToCurrency.format(deposit[15].amount).toString() : '';
    case 'Deposit16W':
      return deposit.length >= 16 ? numberToEnglish(deposit[15].amount) : '';
    case 'Deposit16Date':
      return deposit.length >= 16 ? convertAllDates(deposit[15].dueDate, mergeValue.format) : '';
    case 'Deposit16%':
      return deposit.length >= 16 ? `${((deposit[15].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit17':
      return deposit.length >= 17 ? numToCurrency.format(deposit[16].amount).toString() : '';
    case 'Deposit17W':
      return deposit.length >= 17 ? numberToEnglish(deposit[16].amount) : '';
    case 'Deposit17Date':
      return deposit.length >= 17 ? convertAllDates(deposit[16].dueDate, mergeValue.format) : '';
    case 'Deposit17%':
      return deposit.length >= 17 ? `${((deposit[16].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit18':
      return deposit.length >= 18 ? numToCurrency.format(deposit[17].amount).toString() : '';
    case 'Deposit18W':
      return deposit.length >= 18 ? numberToEnglish(deposit[17].amount) : '';
    case 'Deposit18Date':
      return deposit.length >= 18 ? convertAllDates(deposit[17].dueDate, mergeValue.format) : '';
    case 'Deposit18%':
      return deposit.length >= 18 ? `${((deposit[17].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit19':
      return deposit.length >= 19 ? numToCurrency.format(deposit[18].amount).toString() : '';
    case 'Deposit19W':
      return deposit.length >= 19 ? numberToEnglish(deposit[18].amount) : '';
    case 'Deposit19Date':
      return deposit.length >= 19 ? convertAllDates(deposit[18].dueDate, mergeValue.format) : '';
    case 'Deposit19%':
      return deposit.length >= 19 ? `${((deposit[18].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit20':
      return deposit.length >= 20 && deposit[19].name !== 'Occupancy' ? numToCurrency.format(deposit[19].amount).toString() : '';
    case 'Deposit20W':
      return deposit.length >= 20 && deposit[19].name !== 'Occupancy' ? numberToEnglish(deposit[19].amount) : '';
    case 'Deposit20Date':
      return deposit.length >= 20 && deposit[19].name !== 'Occupancy' ? convertAllDates(deposit[19].dueDate, mergeValue.format) : '';
    case 'Deposit20%':
      return deposit.length >= 20 && deposit[19].name !== 'Occupancy' ? `${((deposit[19].amount / totalOption) * 100).toFixed(1)}%` : '';

    case 'Deposit21':
      return deposit.length >= 21 && deposit[20].name !== 'Occupancy' ? numToCurrency.format(deposit[20].amount).toString() : '';
    case 'Deposit21W':
      return deposit.length >= 21 && deposit[20].name !== 'Occupancy' ? numberToEnglish(deposit[20].amount) : '';
    case 'Deposit21Date':
      return deposit.length >= 21 && deposit[20].name !== 'Occupancy' ? convertAllDates(deposit[20].dueDate, mergeValue.format) : '';
    case 'Deposit21%':
      return deposit.length >= 21 && deposit[20].name !== 'Occupancy' ? `${((deposit[20].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit22':
      return deposit.length >= 22 && deposit[21].name !== 'Occupancy' ? numToCurrency.format(deposit[21].amount).toString() : '';
    case 'Deposit22W':
      return deposit.length >= 22 && deposit[21].name !== 'Occupancy' ? numberToEnglish(deposit[21].amount) : '';
    case 'Deposit22Date':
      return deposit.length >= 22 && deposit[21].name !== 'Occupancy' ? convertAllDates(deposit[21].dueDate, mergeValue.format) : '';
    case 'Deposit22%':
      return deposit.length >= 22 && deposit[21].name !== 'Occupancy'
        ? `${(((deposit[21].amount + deposit[0].amount) / totalOption) * 100).toFixed(1)}%`
        : '';
    case 'Deposit23':
      return deposit.length >= 23 && deposit[22].name !== 'Occupancy' ? numToCurrency.format(deposit[22].amount).toString() : '';
    case 'Deposit23W':
      return deposit.length >= 23 && deposit[22].name !== 'Occupancy' ? numberToEnglish(deposit[22].amount) : '';
    case 'Deposit23Date':
      return deposit.length >= 23 && deposit[22].name !== 'Occupancy' ? convertAllDates(deposit[22].dueDate, mergeValue.format) : '';
    case 'Deposit23%':
      return deposit.length >= 23 && deposit[22].name !== 'Occupancy' ? `${((deposit[22].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit24':
      return deposit.length >= 24 && deposit[23].name !== 'Occupancy' ? numToCurrency.format(deposit[23].amount).toString() : '';
    case 'Deposit24W':
      return deposit.length >= 24 && deposit[23].name !== 'Occupancy' ? numberToEnglish(deposit[23].amount) : '';
    case 'Deposit24Date':
      return deposit.length >= 24 && deposit[23].name !== 'Occupancy' ? convertAllDates(deposit[23].dueDate, mergeValue.format) : '';
    case 'Deposit24%':
      return deposit.length >= 24 && deposit[23].name !== 'Occupancy' ? `${((deposit[23].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit25':
      return deposit.length >= 25 && deposit[24].name !== 'Occupancy' ? numToCurrency.format(deposit[24].amount).toString() : '';
    case 'Deposit25W':
      return deposit.length >= 25 && deposit[24].name !== 'Occupancy' ? numberToEnglish(deposit[24].amount) : '';
    case 'Deposit25Date':
      return deposit.length >= 25 && deposit[24].name !== 'Occupancy' ? convertAllDates(deposit[24].dueDate, mergeValue.format) : '';
    case 'Deposit25%':
      return deposit.length >= 25 && deposit[24].name !== 'Occupancy' ? `${((deposit[24].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit26':
      return deposit.length >= 26 && deposit[25].name !== 'Occupancy' ? numToCurrency.format(deposit[25].amount).toString() : '';
    case 'Deposit26W':
      return deposit.length >= 26 && deposit[25].name !== 'Occupancy' ? numberToEnglish(deposit[25].amount) : '';
    case 'Deposit26Date':
      return deposit.length >= 26 && deposit[25].name !== 'Occupancy' ? convertAllDates(deposit[25].dueDate, mergeValue.format) : '';
    case 'Deposit26%':
      return deposit.length >= 26 && deposit[25].name !== 'Occupancy' ? `${((deposit[25].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit27':
      return deposit.length >= 27 && deposit[26].name !== 'Occupancy' ? numToCurrency.format(deposit[26].amount).toString() : '';
    case 'Deposit27W':
      return deposit.length >= 27 && deposit[26].name !== 'Occupancy' ? numberToEnglish(deposit[26].amount) : '';
    case 'Deposit27Date':
      return deposit.length >= 27 && deposit[26].name !== 'Occupancy' ? convertAllDates(deposit[26].dueDate, mergeValue.format) : '';
    case 'Deposit27%':
      return deposit.length >= 27 && deposit[26].name !== 'Occupancy' ? `${((deposit[26].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit28':
      return deposit.length >= 28 && deposit[27].name !== 'Occupancy' ? numToCurrency.format(deposit[27].amount).toString() : '';
    case 'Deposit28W':
      return deposit.length >= 28 && deposit[27].name !== 'Occupancy' ? numberToEnglish(deposit[27].amount) : '';
    case 'Deposit28Date':
      return deposit.length >= 28 && deposit[27].name !== 'Occupancy' ? convertAllDates(deposit[27].dueDate, mergeValue.format) : '';
    case 'Deposit28%':
      return deposit.length >= 28 && deposit[27].name !== 'Occupancy' ? `${((deposit[27].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit29':
      return deposit.length >= 29 && deposit[28].name !== 'Occupancy' ? numToCurrency.format(deposit[28].amount).toString() : '';
    case 'Deposit29W':
      return deposit.length >= 29 && deposit[28].name !== 'Occupancy' ? numberToEnglish(deposit[28].amount) : '';
    case 'Deposit29Date':
      return deposit.length >= 29 && deposit[28].name !== 'Occupancy' ? convertAllDates(deposit[28].dueDate, mergeValue.format) : '';
    case 'Deposit29%':
      return deposit.length >= 29 && deposit[28].name !== 'Occupancy' ? `${((deposit[28].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit30':
      return deposit.length >= 30 && deposit[29].name !== 'Occupancy' ? numToCurrency.format(deposit[29].amount).toString() : '';
    case 'Deposit30W':
      return deposit.length >= 30 && deposit[29].name !== 'Occupancy' ? numberToEnglish(deposit[29].amount) : '';
    case 'Deposit30Date':
      return deposit.length >= 30 && deposit[29].name !== 'Occupancy' ? convertAllDates(deposit[29].dueDate, mergeValue.format) : '';
    case 'Deposit30%':
      return deposit.length >= 30 && deposit[29].name !== 'Occupancy' ? `${((deposit[29].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit31':
      return deposit.length >= 31 && deposit[30].name !== 'Occupancy' ? numToCurrency.format(deposit[30].amount).toString() : '';
    case 'Deposit31W':
      return deposit.length >= 31 && deposit[30].name !== 'Occupancy' ? numberToEnglish(deposit[30].amount) : '';
    case 'Deposit31Date':
      return deposit.length >= 31 && deposit[30].name !== 'Occupancy' ? convertAllDates(deposit[30].dueDate, mergeValue.format) : '';
    case 'Deposit31%':
      return deposit.length >= 31 && deposit[30].name !== 'Occupancy' ? `${((deposit[30].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit32':
      return deposit.length >= 32 && deposit[31].name !== 'Occupancy' ? numToCurrency.format(deposit[31].amount).toString() : '';
    case 'Deposit32W':
      return deposit.length >= 32 && deposit[31].name !== 'Occupancy' ? numberToEnglish(deposit[31].amount) : '';
    case 'Deposit32Date':
      return deposit.length >= 32 && deposit[31].name !== 'Occupancy' ? convertAllDates(deposit[31].dueDate, mergeValue.format) : '';
    case 'Deposit32%':
      return deposit.length >= 32 && deposit[31].name !== 'Occupancy' ? `${((deposit[31].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit33':
      return deposit.length >= 33 && deposit[32].name !== 'Occupancy' ? numToCurrency.format(deposit[32].amount).toString() : '';
    case 'Deposit33W':
      return deposit.length >= 33 && deposit[32].name !== 'Occupancy' ? numberToEnglish(deposit[32].amount) : '';
    case 'Deposit33Date':
      return deposit.length >= 33 && deposit[32].name !== 'Occupancy' ? convertAllDates(deposit[32].dueDate, mergeValue.format) : '';
    case 'Deposit33%':
      return deposit.length >= 33 && deposit[32].name !== 'Occupancy' ? `${((deposit[32].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit34':
      return deposit.length >= 34 && deposit[33].name !== 'Occupancy' ? numToCurrency.format(deposit[33].amount).toString() : '';
    case 'Deposit34W':
      return deposit.length >= 34 && deposit[33].name !== 'Occupancy' ? numberToEnglish(deposit[33].amount) : '';
    case 'Deposit34Date':
      return deposit.length >= 34 && deposit[33].name !== 'Occupancy' ? convertAllDates(deposit[33].dueDate, mergeValue.format) : '';
    case 'Deposit34%':
      return deposit.length >= 34 && deposit[33].name !== 'Occupancy' ? `${((deposit[33].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit35':
      return deposit.length >= 35 && deposit[34].name !== 'Occupancy' ? numToCurrency.format(deposit[34].amount).toString() : '';
    case 'Deposit35W':
      return deposit.length >= 35 && deposit[34].name !== 'Occupancy' ? numberToEnglish(deposit[34].amount) : '';
    case 'Deposit35Date':
      return deposit.length >= 35 && deposit[34].name !== 'Occupancy' ? convertAllDates(deposit[34].dueDate, mergeValue.format) : '';
    case 'Deposit35%':
      return deposit.length >= 35 && deposit[34].name !== 'Occupancy' ? `${((deposit[34].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit36':
      return deposit.length >= 36 && deposit[35].name !== 'Occupancy' ? numToCurrency.format(deposit[35].amount).toString() : '';
    case 'Deposit36W':
      return deposit.length >= 36 && deposit[35].name !== 'Occupancy' ? numberToEnglish(deposit[35].amount) : '';
    case 'Deposit36Date':
      return deposit.length >= 36 && deposit[35].name !== 'Occupancy' ? convertAllDates(deposit[35].dueDate, mergeValue.format) : '';
    case 'Deposit36%':
      return deposit.length >= 36 && deposit[35].name !== 'Occupancy' ? `${((deposit[35].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit37':
      return deposit.length >= 37 && deposit[36].name !== 'Occupancy' ? numToCurrency.format(deposit[36].amount).toString() : '';
    case 'Deposit37W':
      return deposit.length >= 37 && deposit[36].name !== 'Occupancy' ? numberToEnglish(deposit[36].amount) : '';
    case 'Deposit37Date':
      return deposit.length >= 37 && deposit[36].name !== 'Occupancy' ? convertAllDates(deposit[36].dueDate, mergeValue.format) : '';
    case 'Deposit37%':
      return deposit.length >= 37 && deposit[36].name !== 'Occupancy' ? `${((deposit[36].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit38':
      return deposit.length >= 38 && deposit[37].name !== 'Occupancy' ? numToCurrency.format(deposit[37].amount).toString() : '';
    case 'Deposit38W':
      return deposit.length >= 38 && deposit[37].name !== 'Occupancy' ? numberToEnglish(deposit[37].amount) : '';
    case 'Deposit38Date':
      return deposit.length >= 38 && deposit[37].name !== 'Occupancy' ? convertAllDates(deposit[37].dueDate, mergeValue.format) : '';
    case 'Deposit38%':
      return deposit.length >= 38 && deposit[37].name !== 'Occupancy' ? `${((deposit[37].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit39':
      return deposit.length >= 39 && deposit[38].name !== 'Occupancy' ? numToCurrency.format(deposit[38].amount).toString() : '';
    case 'Deposit39W':
      return deposit.length >= 39 && deposit[38].name !== 'Occupancy' ? numberToEnglish(deposit[38].amount) : '';
    case 'Deposit39Date':
      return deposit.length >= 39 && deposit[38].name !== 'Occupancy' ? convertAllDates(deposit[38].dueDate, mergeValue.format) : '';
    case 'Deposit39%':
      return deposit.length >= 39 && deposit[38].name !== 'Occupancy' ? `${((deposit[38].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit40':
      return deposit.length >= 40 && deposit[39].name !== 'Occupancy' ? numToCurrency.format(deposit[39].amount).toString() : '';
    case 'Deposit40W':
      return deposit.length >= 40 && deposit[39].name !== 'Occupancy' ? numberToEnglish(deposit[39].amount) : '';
    case 'Deposit40Date':
      return deposit.length >= 40 && deposit[39].name !== 'Occupancy' ? convertAllDates(deposit[39].dueDate, mergeValue.format) : '';
    case 'Deposit40%':
      return deposit.length >= 40 && deposit[39].name !== 'Occupancy' ? `${((deposit[39].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit41':
      return deposit.length >= 41 && deposit[40].name !== 'Occupancy' ? numToCurrency.format(deposit[40].amount).toString() : '';
    case 'Deposit41W':
      return deposit.length >= 41 && deposit[40].name !== 'Occupancy' ? numberToEnglish(deposit[40].amount) : '';
    case 'Deposit41Date':
      return deposit.length >= 41 && deposit[40].name !== 'Occupancy' ? convertAllDates(deposit[40].dueDate, mergeValue.format) : '';
    case 'Deposit41%':
      return deposit.length >= 41 && deposit[40].name !== 'Occupancy' ? `${((deposit[40].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Deposit42':
      return deposit.length >= 42 && deposit[41].name !== 'Occupancy' ? numToCurrency.format(deposit[41].amount).toString() : '';
    case 'Deposit42W':
      return deposit.length >= 42 && deposit[41].name !== 'Occupancy' ? numberToEnglish(deposit[41].amount) : '';
    case 'Deposit42Date':
      return deposit.length >= 42 && deposit[41].name !== 'Occupancy' ? convertAllDates(deposit[41].dueDate, mergeValue.format) : '';
    case 'Deposit42%':
      return deposit.length >= 42 && deposit[41].name !== 'Occupancy' ? `${((deposit[41].amount / totalOption) * 100).toFixed(1)}%` : '';
    case '4thLastDeposit':
      return deposit.length >= 4 ? numToCurrency.format(deposit[deposit.length - 4].amount).toString() : '';
    case '4thLastDepositW':
      return deposit.length >= 4 ? numberToEnglish(deposit[deposit.length - 4].amount) : '';
    case '4thLastDepositDate':
      return deposit.length >= 4 ? convertAllDates(deposit[deposit.length - 4].dueDate, mergeValue.format) : '';
    case '4thLastDeposit%':
      return deposit.length >= 4 ? `${((deposit[deposit.length - 4].amount / totalOption) * 100).toFixed(1)}%` : '';
    case '3rdLastDeposit':
      return deposit.length >= 3 ? numToCurrency.format(deposit[deposit.length - 3].amount).toString() : '';
    case '3rdLastDepositW':
      return deposit.length >= 3 ? numberToEnglish(deposit[deposit.length - 3].amount) : '';
    case '3rdLastDepositDate':
      return deposit.length >= 3 ? convertAllDates(deposit[deposit.length - 3].dueDate, mergeValue.format) : '';
    case '3rdLastDeposit%':
      return deposit.length >= 3 ? `${((deposit[deposit.length - 3].amount / totalOption) * 100).toFixed(1)}%` : '';
    case '2ndLastDeposit':
      return deposit.length >= 2 ? numToCurrency.format(deposit[deposit.length - 2].amount).toString() : '';
    case '2ndLastDepositW':
      return deposit.length >= 2 ? numberToEnglish(deposit[deposit.length - 2].amount) : '';
    case '2ndLastDepositDate':
      return deposit.length >= 2 ? convertAllDates(deposit[deposit.length - 2].dueDate, mergeValue.format) : '';
    case '2ndLastDeposit%':
      return deposit.length >= 2 ? `${((deposit[deposit.length - 2].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'LastDeposit':
      return deposit.length >= 1 ? numToCurrency.format(deposit[deposit.length - 1].amount).toString() : '';
    case 'LastDepositW':
      return deposit.length >= 1 ? numberToEnglish(deposit[deposit.length - 1].amount) : '';
    case 'LastDepositDate':
      return deposit.length >= 1 ? convertAllDates(deposit[deposit.length - 1].dueDate, mergeValue.format) : '';
    case 'LastDeposit%':
      return deposit.length >= 1 ? `${((deposit[deposit.length - 1].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'NewDepositBasePrice':
      return deal && deal.pendingBasePrice ? numToCurrency.format(deal.pendingBasePrice) : '';
    case 'NewDepositTotalPrice':
      return deal && deal.pendingBasePrice
        ? numToCurrency.format(
            options.reduce(
              (a: any, b: any) => {
                return a + b.amount;
              },
              deal.pendingBasePrice ? parseInt(deal.pendingBasePrice, 10) : unit.basePrice
            )
          )
        : '';
    case 'UPrice':
      return numToCurrency.format(unit.basePrice).toString();
    case 'UPriceW':
      return numberToEnglish(unit.basePrice);
    case 'NetUPrice':
      return unit.type === 'commercial'
        ? numToCurrencyDecimal.format(parseFloat(unit.basePrice.toFixed(2)))
        : numToCurrencyDecimal.format(parseFloat(netHST(unit.basePrice).toFixed(2)));
    case 'TPrice':
      return numToCurrencyDecimal.format(totalOption).toString();
    case 'TPriceW':
      return numberToEnglish(totalOption);
    case 'NetTPrice':
      return unit.type === 'commercial'
        ? numToCurrencyDecimal.format(parseFloat(totalOption.toFixed(2)))
        : numToCurrencyDecimal.format(parseFloat(netHST(totalOption).toFixed(2)));
    case 'toPrice':
      return !toUnit && unit
        ? numToCurrencyDecimal.format(unit.basePrice).toString()
        : numToCurrencyDecimal.format(toUnit.basePrice).toString();
    case 'toPriceW':
      return numberToEnglish(toUnit.basePrice);
    case 'toTotalP':
      return numToCurrencyDecimal.format(toTotalOption).toString();
    case 'toTotalPW':
      return numberToEnglish(toTotalOption);
    case 'toSuite':
      return toUnit.suite;
    case 'toUnit':
      return toUnit.unit;
    case 'toLevel':
      return toUnit.level;
    case 'toTier':
      return toUnit.tier;
    case 'toRental':
      return toUnit?.rental ? numToCurrency.format(toUnit.rental) : '';
    case 'RealFN':
      return realtor ? realtor.firstName : '';
    case 'RealLN':
      return realtor ? realtor.lastName : '';
    case 'RealFullName':
      return realtor ? `${realtor.firstName} ${realtor.lastName}` : '';
    case 'RealEmail':
      return realtor ? realtor.email : '';
    case 'RealFullAddress':
      return realtor ? `${realtor.streetAddress}, ${realtor.city}, ${realtor.province}, ${realtor.postalCode}` : '';
    case 'RealAddress':
      return realtor ? realtor.streetAddress : '';
    case 'RealCity':
      return realtor ? realtor.city : '';
    case 'RealProvince':
      return realtor ? realtor.province : '';
    case 'RealPhone':
      return realtor ? realtor.directPhone : '';
    case 'RealPostCode':
      return realtor ? realtor.postalCode : '';
    case 'BrokeragePhone':
      return realtor ? realtor.brokeragePhone : '';
    case 'Brokerage':
      return realtor ? realtor.brokerage : '';
    case 'BrokerageFax':
      return realtor && realtor.brokerageFax ? realtor.brokerageFax : '';
    case 'CommissionPercent1':
      return realtor && realtor.coopRates.length
        ? `${numberToEnglish(realtor.coopRates[0].percentage)} (${realtor.coopRates[0].percentage}%)`
        : '';
    case 'CommissionPercent2':
      return realtor && realtor.coopRates.length > 1
        ? `${numberToEnglish(realtor.coopRates[1].percentage)} (${realtor.coopRates[1].percentage}%)`
        : '';
    case 'CommissionPercent3':
      return realtor && realtor.coopRates.length > 2
        ? `${numberToEnglish(realtor.coopRates[2].percentage)} (${realtor.coopRates[2].percentage}%)`
        : '';
    case 'CommissionPercent4':
      return realtor && realtor.coopRates.length > 3
        ? `${numberToEnglish(realtor.coopRates[3].percentage)} (${realtor.coopRates[3].percentage}%)`
        : '';
    case 'CommissionPercent5':
      return realtor && realtor.coopRates.length > 4
        ? `${numberToEnglish(realtor.coopRates[4].percentage)} (${realtor.coopRates[4].percentage}%)`
        : '';
    case 'CommissionPercent6':
      return realtor && realtor.coopRates.length > 5
        ? `${numberToEnglish(realtor.coopRates[5].percentage)} (${realtor.coopRates[5].percentage}%)`
        : '';
    case 'CommissionPercentAmount1':
      return deal ? commission : '';
    case 'CommissionPercentAmount2':
      return deal ? commission : '';
    case 'CommissionPercentAmount3':
      return deal ? commission : '';
    case 'CommissionPercentAmount4':
      return deal ? commission : '';
    case 'CommissionPercentAmount5':
      return deal ? commission : '';
    case 'CommissionPercentAmount6':
      return deal ? commission : '';
    case 'CommissionFixed1':
      return deal ? commission : '';
    case 'CommissionFixed2':
      return deal ? commission : '';
    case 'CommissionFixed3':
      return deal ? commission : '';
    case 'CommissionFixed4':
      return deal ? commission : '';
    case 'CommissionFixed5':
      return deal ? commission : '';
    case 'CommissionFixed6':
      return deal ? commission : '';
    case 'OP1':
      return newOptions?.purchaseAmount;
    case 'OP2':
      return newOptions?.purchaseAmount;
    case 'OP3':
      return newOptions?.purchaseAmount;
    case 'OP4':
      return newOptions?.purchaseAmount;
    case 'OP5':
      return newOptions?.purchaseAmount;
    case 'OP6':
      return newOptions?.purchaseAmount;
    case 'OP7':
      return newOptions?.purchaseAmount;
    case 'OP8':
      return newOptions?.purchaseAmount;
    case 'OP9':
      return newOptions?.purchaseAmount;
    case 'OP10':
      return newOptions?.purchaseAmount;
    case 'toOP1':
      return newOptions?.purchaseAmount;
    case 'toOP2':
      return newOptions?.purchaseAmount;
    case 'toOP3':
      return newOptions?.purchaseAmount;
    case 'toOP4':
      return newOptions?.purchaseAmount;
    case 'toOP5':
      return newOptions?.purchaseAmount;
    case 'toOP6':
      return newOptions?.purchaseAmount;
    case 'toOP7':
      return newOptions?.purchaseAmount;
    case 'toOP8':
      return newOptions?.purchaseAmount;
    case 'toOP9':
      return newOptions?.purchaseAmount;
    case 'toOP10':
      return newOptions?.purchaseAmount;
    case 'OP1Price':
      return newOptions?.amount;
    case 'OP2Price':
      return newOptions?.amount;
    case 'OP3Price':
      return newOptions?.amount;
    case 'OP4Price':
      return newOptions?.amount;
    case 'OP5Price':
      return newOptions?.amount;
    case 'OP6Price':
      return newOptions?.amount;
    case 'OP7Price':
      return newOptions?.amount;
    case 'OP8Price':
      return newOptions?.amount;
    case 'OP9Price':
      return newOptions?.amount;
    case 'OP10Price':
      return newOptions?.amount;
    case 'OP1Num':
      return newOptions?.purchaseNumber;
    case 'OP2Num':
      return newOptions?.purchaseNumber;
    case 'OP3Num':
      return newOptions?.purchaseNumber;
    case 'OP4Num':
      return newOptions?.purchaseNumber;
    case 'OP5Num':
      return newOptions?.purchaseNumber;
    case 'OP6Num':
      return newOptions?.purchaseNumber;
    case 'OP7Num':
      return newOptions?.purchaseNumber;
    case 'OP8Num':
      return newOptions?.purchaseNumber;
    case 'OP9Num':
      return newOptions?.purchaseNumber;
    case 'OP10Num':
      return newOptions?.purchaseNumber;
    case 'toOP1Price':
      return newOptions?.amount;
    case 'toOP2Price':
      return newOptions?.amount;
    case 'toOP3Price':
      return newOptions?.amount;
    case 'toOP4Price':
      return newOptions?.amount;
    case 'toOP5Price':
      return newOptions?.amount;
    case 'toOP6Price':
      return newOptions?.amount;
    case 'toOP7Price':
      return newOptions?.amount;
    case 'toOP8Price':
      return newOptions?.amount;
    case 'toOP9Price':
      return newOptions?.amount;
    case 'toOP10Price':
      return newOptions?.amount;
    case 'toOP1Num':
      return newOptions?.purchaseNumber;
    case 'toOP2Num':
      return newOptions?.purchaseNumber;
    case 'toOP3Num':
      return newOptions?.purchaseNumber;
    case 'toOP4Num':
      return newOptions?.purchaseNumber;
    case 'toOP5Num':
      return newOptions?.purchaseNumber;
    case 'toOP6Num':
      return newOptions?.purchaseNumber;
    case 'toOP7Num':
      return newOptions?.purchaseNumber;
    case 'toOP8Num':
      return newOptions?.purchaseNumber;
    case 'toOP9Num':
      return newOptions?.purchaseNumber;
    case 'toOP10Num':
      return newOptions?.purchaseNumber;
    case 'totalParkingPrice':
      return options.length && options.filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('parking')).length
        ? numToCurrency.format(
            options
              .filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('parking'))
              .reduce((a: any, b: any) => a + b.amount, 0)
          )
        : '';
    case 'totalParkingNum':
      return options.length && options.filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('parking')).length
        ? `${options
            .filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('parking'))
            .reduce((a: any, b: any) => a + b.purchaseAmount, 0)}`
        : '0';
    case 'totalParkingAmount':
      return options.length && options.filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('parking')).length
        ? `${numToCurrency.format(
            options
              .filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('parking'))
              .reduce((a: any, b: any) => a + b.purchaseAmount, 0)
          )} (${numberToEnglish(
            options
              .filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('parking'))
              .reduce((a: any, b: any) => a + b.purchaseAmount, 0)
          )})`
        : '';
    case 'totalLockerPrice':
      return options.length && options.filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('locker')).length
        ? numToCurrency.format(
            options
              .filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('locker'))
              .reduce((a: any, b: any) => a + b.amount, 0)
          )
        : '';
    case 'totalLockerNum':
      return options.length && options.filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('locker')).length
        ? `${options
            .filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('locker'))
            .reduce((a: any, b: any) => a + b.purchaseAmount, 0)}`
        : '0';
    case 'totalLockerAmount':
      return options.length && options.filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('locker')).length
        ? `${numToCurrency.format(
            options
              .filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('locker'))
              .reduce((a: any, b: any) => a + b.purchaseAmount, 0)
          )} (${numberToEnglish(
            options
              .filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('locker'))
              .reduce((a: any, b: any) => a + b.purchaseAmount, 0)
          )})`
        : '';

    case 'toTotalParkingPrice':
      return toOptions.length && toOptions.filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('parking')).length
        ? numToCurrency.format(
            toOptions
              .filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('parking'))
              .reduce((a: any, b: any) => a + b.amount, 0)
          )
        : '';
    case 'toTotalParkingNum':
      return toOptions.length && toOptions.filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('parking')).length
        ? `${toOptions
            .filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('parking'))
            .reduce((a: any, b: any) => a + b.purchaseAmount, 0)}`
        : '0';
    case 'toTotalParkingAmount':
      return toOptions.length && toOptions.filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('parking')).length
        ? `${numToCurrency.format(
            toOptions
              .filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('parking'))
              .reduce((a: any, b: any) => a + b.purchaseAmount, 0)
          )} (${numberToEnglish(
            toOptions
              .filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('parking'))
              .reduce((a: any, b: any) => a + b.purchaseAmount, 0)
          )})`
        : '';
    case 'toTotalLockerPrice':
      return toOptions.length && toOptions.filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('locker')).length
        ? numToCurrency.format(
            toOptions
              .filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('locker'))
              .reduce((a: any, b: any) => a + b.amount, 0)
          )
        : '';
    case 'toTotalLockerNum':
      return toOptions.length && toOptions.filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('locker')).length
        ? `${toOptions
            .filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('locker'))
            .reduce((a: any, b: any) => a + b.purchaseAmount, 0)}`
        : '0';
    case 'toTotalLockerAmount':
      return toOptions.length && toOptions.filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('locker')).length
        ? `${numToCurrency.format(
            toOptions
              .filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('locker'))
              .reduce((a: any, b: any) => a + b.purchaseAmount, 0)
          )} (${numberToEnglish(
            toOptions
              .filter((option: IDealOption) => option.name?.toLocaleLowerCase().includes('locker'))
              .reduce((a: any, b: any) => a + b.purchaseAmount, 0)
          )})`
        : '';
    case 'Rental':
      return deal && deal.pendingRental ? numToCurrency.format(deal.pendingRental) : unit?.rental ? numToCurrency.format(unit?.rental) : '';
    case 'toDeposit1':
      return toDeposits.length >= 1 ? numToCurrency.format(toDeposits[0].amount).toString() : '';
    case 'toDeposit1W':
      return toDeposits.length >= 1 ? numberToEnglish(toDeposits[0].amount) : '';
    case 'toDeposit1Date':
      return toDeposits.length >= 1
        ? convertAllDates(toDeposits[0].dueDate, mergeValue.format)
        : convertAllDates(new Date(), mergeValue.format);
    case 'toDeposit1DateWords':
      return toDeposits.length >= 1
        ? convertAllDates(toDeposits[0].dueDate, mergeValue.format)
        : convertAllDates(new Date(), mergeValue.format);
    case 'toDeposit1%':
      return toDeposits.length >= 1 ? `${((toDeposits[0].amount / toTotalOption) * 100).toFixed(1)}%` : '';
    case 'toDeposit2':
      return toDeposits.length >= 2 ? numToCurrency.format(toDeposits[1].amount).toString() : '';
    case 'toDeposit2W':
      return toDeposits.length >= 2 ? numberToEnglish(toDeposits[1].amount) : '';
    case 'toDeposit2Date':
      return toDeposits.length >= 2 ? convertAllDates(toDeposits[1].dueDate, mergeValue.format) : '';
    case 'toDeposit2DateWords':
      return toDeposits.length >= 2
        ? convertAllDates(toDeposits[1].dueDate, mergeValue.format)
        : convertAllDates(new Date(), mergeValue.format);
    case 'toDeposit2%':
      return toDeposits.length >= 2 ? `${(((toDeposits[1].amount + toDeposits[0].amount) / toTotalOption) * 100).toFixed(1)}%` : '';
    case 'toDeposit3':
      return toDeposits.length >= 3 ? numToCurrency.format(toDeposits[2].amount).toString() : '';
    case 'toDeposit3W':
      return toDeposits.length >= 3 ? numberToEnglish(toDeposits[2].amount) : '';
    case 'toDeposit3Date':
      return toDeposits.length >= 3 ? convertAllDates(toDeposits[2].dueDate, mergeValue.format) : '';
    case 'toDeposit3DateWords':
      return toDeposits.length >= 3
        ? convertAllDates(toDeposits[2].dueDate, mergeValue.format)
        : convertAllDates(new Date(), mergeValue.format);
    case 'toDeposit3%':
      return toDeposits.length >= 3 ? `${((toDeposits[2].amount / toTotalOption) * 100).toFixed(1)}%` : '';
    case 'toDeposit4':
      return toDeposits.length >= 4 ? numToCurrency.format(toDeposits[3].amount).toString() : '';
    case 'toDeposit4W':
      return toDeposits.length >= 4 ? numberToEnglish(toDeposits[3].amount) : '';
    case 'toDeposit4Date':
      return toDeposits.length >= 4 ? convertAllDates(toDeposits[3].dueDate, mergeValue.format) : '';
    case 'toDeposit4DateWords':
      return toDeposits.length >= 4
        ? convertAllDates(toDeposits[3].dueDate, mergeValue.format)
        : convertAllDates(new Date(), mergeValue.format);
    case 'toDeposit4%':
      return toDeposits.length >= 4 ? `${((toDeposits[3].amount / toTotalOption) * 100).toFixed(1)}%` : '';
    case 'toDeposit5':
      return toDeposits.length >= 5 ? numToCurrency.format(toDeposits[4].amount).toString() : '';
    case 'toDeposit5W':
      return toDeposits.length >= 5 ? numberToEnglish(toDeposits[4].amount) : '';
    case 'toDeposit5Date':
      return toDeposits.length >= 5 ? convertAllDates(toDeposits[4].dueDate, mergeValue.format) : '';
    case 'toDeposit5DateWords':
      return toDeposits.length >= 5
        ? convertAllDates(toDeposits[4].dueDate, mergeValue.format)
        : convertAllDates(new Date(), mergeValue.format);
    case 'toDeposit5%':
      return toDeposits.length >= 5 ? `${((toDeposits[4].amount / toTotalOption) * 100).toFixed(1)}%` : '';
    case 'toDeposit6':
      return toDeposits.length >= 6 ? numToCurrency.format(toDeposits[5].amount).toString() : '';
    case 'toDeposit6W':
      return toDeposits.length >= 6 ? numberToEnglish(toDeposits[5].amount) : '';
    case 'toDeposit6Date':
      return toDeposits.length >= 6 ? convertAllDates(toDeposits[5].dueDate, mergeValue.format) : '';
    case 'toDeposit6DateWords':
      return toDeposits.length >= 6
        ? convertAllDates(toDeposits[5].dueDate, mergeValue.format)
        : convertAllDates(new Date(), mergeValue.format);
    case 'toDeposit6%':
      return toDeposits.length >= 6 ? `${((toDeposits[5].amount / toTotalOption) * 100).toFixed(1)}%` : '';
    case 'toDeposit7':
      return toDeposits.length >= 7 ? numToCurrency.format(toDeposits[6].amount).toString() : '';
    case 'toDeposit7W':
      return toDeposits.length >= 7 ? numberToEnglish(toDeposits[6].amount) : '';
    case 'toDeposit7Date':
      return toDeposits.length >= 7 ? convertAllDates(toDeposits[6].dueDate, mergeValue.format) : '';
    case 'toDeposit7DateWords':
      return toDeposits.length >= 7
        ? convertAllDates(toDeposits[6].dueDate, mergeValue.format)
        : convertAllDates(new Date(), mergeValue.format);
    case 'toDeposit7%':
      return toDeposits.length >= 7 ? `${((toDeposits[6].amount / toTotalOption) * 100).toFixed(1)}%` : '';
    case 'toDeposit8':
      return toDeposits.length >= 8 ? numToCurrency.format(toDeposits[7].amount).toString() : '';
    case 'toDeposit8W':
      return toDeposits.length >= 8 ? numberToEnglish(toDeposits[7].amount) : '';
    case 'toDeposit8Date':
      return toDeposits.length >= 8 ? convertAllDates(toDeposits[7].dueDate, mergeValue.format) : '';
    case 'toDeposit8DateWords':
      return toDeposits.length >= 8
        ? convertAllDates(toDeposits[7].dueDate, mergeValue.format)
        : convertAllDates(new Date(), mergeValue.format);
    case 'toDeposit8%':
      return toDeposits.length >= 8 ? `${((toDeposits[8].amount / toTotalOption) * 100).toFixed(1)}%` : '';
    case 'toDeposit9':
      return toDeposits.length >= 9 ? numToCurrency.format(toDeposits[8].amount).toString() : '';
    case 'toDeposit9W':
      return toDeposits.length >= 9 ? numberToEnglish(toDeposits[8].amount) : '';
    case 'toDeposit9Date':
      return toDeposits.length >= 9 ? convertAllDates(toDeposits[8].dueDate, mergeValue.format) : '';
    case 'toDeposit9DateWords':
      return toDeposits.length >= 9
        ? convertAllDates(toDeposits[8].dueDate, mergeValue.format)
        : convertAllDates(new Date(), mergeValue.format);
    case 'toDeposit9%':
      return toDeposits.length >= 9 ? `${((toDeposits[8].amount / toTotalOption) * 100).toFixed(1)}%` : '';
    case 'toDeposit10':
      return toDeposits.length >= 10 ? numToCurrency.format(toDeposits[9].amount).toString() : '';
    case 'toDeposit10W':
      return toDeposits.length >= 10 ? numberToEnglish(toDeposits[9].amount) : '';
    case 'toDeposit10Date':
      return toDeposits.length >= 10 ? convertAllDates(toDeposits[9].dueDate, mergeValue.format) : '';
    case 'toDeposit10DateWords':
      return toDeposits.length >= 10
        ? convertAllDates(toDeposits[9].dueDate, mergeValue.format)
        : convertAllDates(new Date(), mergeValue.format);
    case 'toDeposit10%':
      return toDeposits.length >= 10 ? `${((toDeposits[9].amount / toTotalOption) * 100).toFixed(1)}%` : '';
    case 'toDeposit11':
      return toDeposits.length >= 11 ? numToCurrency.format(toDeposits[10].amount).toString() : '';
    case 'toDeposit11W':
      return toDeposits.length >= 11 ? numberToEnglish(toDeposits[10].amount) : '';
    case 'toDeposit11Date':
      return toDeposits.length >= 11 ? convertAllDates(toDeposits[10].dueDate, mergeValue.format) : '';
    case 'toDeposit11%':
      return toDeposits.length >= 11 ? `${((toDeposits[10].amount / toTotalOption) * 100).toFixed(1)}%` : '';
    case 'toDeposit12':
      return toDeposits.length >= 12 ? numToCurrency.format(toDeposits[11].amount).toString() : '';
    case 'toDeposit12W':
      return toDeposits.length >= 12 ? numberToEnglish(toDeposits[11].amount) : '';
    case 'toDeposit12Date':
      return toDeposits.length >= 12 ? convertAllDates(toDeposits[11].dueDate, mergeValue.format) : '';
    case 'toDeposit12%':
      return toDeposits.length >= 12 ? `${((toDeposits[11].amount / toTotalOption) * 100).toFixed(1)}%` : '';
    case 'to4thLastDeposit':
      return toDeposits.length >= 4 ? numToCurrency.format(toDeposits[toDeposits.length - 4].amount).toString() : '';
    case 'to4thLastDepositW':
      return toDeposits.length >= 4 ? numberToEnglish(toDeposits[toDeposits.length - 4].amount) : '';
    case 'to4thLastDepositDate':
      return toDeposits.length >= 4 ? convertAllDates(toDeposits[toDeposits.length - 4].dueDate, mergeValue.format) : '';
    case 'to4thLastDeposit%':
      return toDeposits.length >= 4 ? `${((toDeposits[toDeposits.length - 4].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'to3rdLastDeposit':
      return toDeposits.length >= 3 ? numToCurrency.format(toDeposits[toDeposits.length - 3].amount).toString() : '';
    case 'to3rdLastDepositW':
      return toDeposits.length >= 3 ? numberToEnglish(toDeposits[toDeposits.length - 3].amount) : '';
    case 'to3rdLastDepositDate':
      return toDeposits.length >= 3 ? convertAllDates(toDeposits[toDeposits.length - 3].dueDate, mergeValue.format) : '';
    case 'to3rdLastDeposit%':
      return toDeposits.length >= 3 ? `${((toDeposits[toDeposits.length - 3].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'to2ndLastDeposit':
      return toDeposits.length >= 2 ? numToCurrency.format(toDeposits[toDeposits.length - 2].amount).toString() : '';
    case 'to2ndLastDepositW':
      return toDeposits.length >= 2 ? numberToEnglish(toDeposits[toDeposits.length - 2].amount) : '';
    case 'to2ndLastDepositDate':
      return toDeposits.length >= 2 ? convertAllDates(toDeposits[toDeposits.length - 2].dueDate, mergeValue.format) : '';
    case 'to2ndLastDeposit%':
      return toDeposits.length >= 2 ? `${((toDeposits[toDeposits.length - 2].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'toLastDeposit':
      return toDeposits.length >= 1 ? numToCurrency.format(toDeposits[toDeposits.length - 1].amount).toString() : '';
    case 'toLastDepositW':
      return toDeposits.length >= 1 ? numberToEnglish(toDeposits[toDeposits.length - 1].amount) : '';
    case 'toLastDepositDate':
      return toDeposits.length >= 1 ? convertAllDates(toDeposits[toDeposits.length - 1].dueDate, mergeValue.format) : '';
    case 'toLastDeposit%':
      return toDeposits.length >= 1 ? `${((toDeposits[toDeposits.length - 1].amount / totalOption) * 100).toFixed(1)}%` : '';
    case 'Schedule A':
      return mergeValue.key;
    case 'H':
      return mergeValue.key;
    case 'J':
      return mergeValue.key;
    case 'K':
      return mergeValue.key;
    case 'firstOccupancy':
      return unit?.firstTentativeOccupancy
        ? convertAllDates(unit?.firstTentativeOccupancy, mergeValue.format)
        : project && project.firstTentativeOccupancy
        ? convertAllDates(project.firstTentativeOccupancy, mergeValue.format)
        : '';
    case 'firstOccupancyDay':
    case 'firstOccupancyMonth':
    case 'firstOccupancyYear':
      return unit?.firstTentativeOccupancy
        ? convertAllDates(unit?.firstTentativeOccupancy, mergeValue.format)
        : project && project.firstTentativeOccupancy
        ? convertAllDates(project.firstTentativeOccupancy, mergeValue.format)
        : '';
    case 'finalOccupancy':
      return unit?.finalTentativeOccupancy
        ? convertAllDates(unit?.finalTentativeOccupancy, mergeValue.format)
        : project && project.finalTentativeOccupancy
        ? convertAllDates(project.finalTentativeOccupancy, mergeValue.format)
        : '';
    case 'finalOccupancyDay':
    case 'finalOccupancyMonth':
    case 'finalOccupancyYear':
      return unit?.finalTentativeOccupancy
        ? convertAllDates(unit?.finalTentativeOccupancy, mergeValue.format)
        : project && project.finalTentativeOccupancy
        ? convertAllDates(project.finalTentativeOccupancy, mergeValue.format)
        : '';
    case 'firmOccupancy':
      return unit?.firmOccupancy
        ? convertAllDates(unit?.firmOccupancy, mergeValue.format)
        : project && project.firmOccupancy
        ? convertAllDates(project.firmOccupancy, mergeValue.format)
        : '';
    case 'firmOccupancyDay':
    case 'firmOccupancyMonth':
    case 'firmOccupancyYear':
      return unit?.firmOccupancy
        ? convertAllDates(unit?.firmOccupancy, mergeValue.format)
        : project && project.firmOccupancy
        ? convertAllDates(project.firmOccupancy, mergeValue.format)
        : '';
    case 'outsideOccupancy':
      return unit?.outsideOccupancy
        ? convertAllDates(unit?.outsideOccupancy, mergeValue.format)
        : project && project.outsideOccupancy
        ? convertAllDates(project.outsideOccupancy, mergeValue.format)
        : '';
    case 'outsideOccupancyDay':
    case 'outsideOccupancyMonth':
    case 'outsideOccupancyYear':
      return unit?.outsideOccupancy
        ? convertAllDates(unit?.outsideOccupancy, mergeValue.format)
        : project && project.outsideOccupancy
        ? convertAllDates(project.outsideOccupancy, mergeValue.format)
        : '';
    case 'noticePeriod':
      return unit?.firstTentativeOccupancy
        ? convertAllDates(subDays(new Date(unit?.firstTentativeOccupancy), 90), mergeValue.format)
        : project && project.firstTentativeOccupancy
        ? convertAllDates(subDays(new Date(project.firstTentativeOccupancy), 90), mergeValue.format)
        : '';
    case 'noticePeriodDay':
    case 'noticePeriodMonth':
    case 'noticePeriodYear':
      return unit?.firstTentativeOccupancy
        ? convertAllDates(subDays(new Date(unit?.firstTentativeOccupancy), 90), mergeValue.format)
        : project && project.firstTentativeOccupancy
        ? convertAllDates(subDays(new Date(project.firstTentativeOccupancy), 90), mergeValue.format)
        : '';
    case 'terminationPeriod':
      return unit?.outsideOccupancy
        ? convertAllDates(addDays(new Date(unit?.outsideOccupancy), 30), mergeValue.format)
        : project && project.outsideOccupancy
        ? convertAllDates(addDays(new Date(project.outsideOccupancy), 30), mergeValue.format)
        : '';
    case 'terminationPeriodDay':
    case 'terminationPeriodMonth':
    case 'terminationPeriodYear':
      return unit?.outsideOccupancy
        ? convertAllDates(addDays(new Date(unit?.outsideOccupancy), 30), mergeValue.format)
        : project && project.outsideOccupancy
        ? convertAllDates(addDays(new Date(project.outsideOccupancy), 30), mergeValue.format)
        : '';
    case '3rdPartyName':
      return deal.thirdParty.length > 0 ? deal.thirdParty[0].fullName : '';
    case '3rdPartyAddress':
      return deal.thirdParty.length > 0 ? deal.thirdParty[0].streetAddress : '';
    case '3rdPartyPhone':
      return deal.thirdParty.length > 0 ? deal.thirdParty[0].primaryPhone : '';
    case '3rdPartyDob':
      return deal.thirdParty.length > 0 ? convertAllDates(deal.thirdParty[0].dob, mergeValue.format) : '';
    case '3rdPartyOccupation':
      return deal.thirdParty.length > 0 ? deal.thirdParty[0].occupation : '';
    case '3rdPartyCorp':
      return deal.thirdParty.length > 0 ? deal.thirdParty[0].corpNumber : '';
    case '3rdPartyRelationship':
      return deal.thirdParty.length > 0 ? deal.thirdParty[0].relationship : '';
    case 'Adjustment1%':
      return deal && deal.adjustments.length >= 1 ? `${deal.adjustments[0].percentage}%` : '';
    case 'Adjustment1%Words':
      return deal && deal.adjustments.length >= 1 ? `${numberToEnglish(deal.adjustments[0].percentage)} PERCENT` : '';
    case 'Adjustment1$':
      return deal && deal.adjustments.length >= 1 ? `${numToCurrency.format(deal.adjustments[0].value)}` : '';
    case 'Adjustment1$Words':
      return deal && deal.adjustments.length >= 1 ? `${numberToEnglish(deal.adjustments[0].value)} DOLLARS` : '';
    case 'Adjustment1$WordsAndNumber':
      return deal && deal.adjustments.length >= 1
        ? `${numberToEnglish(deal.adjustments[0].value)} DOLLARS (${numToCurrency.format(deal.adjustments[0].value)})`
        : '';
    case 'Adjustment2%':
      return deal && deal.adjustments.length >= 2 ? `${deal.adjustments[1].percentage}%` : '';
    case 'Adjustment2%Words':
      return deal && deal.adjustments.length >= 2 ? `${numberToEnglish(deal.adjustments[1].percentage)} PERCENT` : '';
    case 'Adjustment2$':
      return deal && deal.adjustments.length >= 2 ? `${numToCurrency.format(deal.adjustments[1].value)}` : '';
    case 'Adjustment2$Words':
      return deal && deal.adjustments.length >= 2 ? `${numberToEnglish(deal.adjustments[1].value)} DOLLARS` : '';
    case 'Adjustment2$WordsAndNumber':
      return deal && deal.adjustments.length >= 2
        ? `${numberToEnglish(deal.adjustments[1].value)} DOLLARS (${numToCurrency.format(deal.adjustments[1].value)})`
        : '';
    case 'Adjustment3%':
      return deal && deal.adjustments.length >= 3 ? `${deal.adjustments[2].percentage}%` : '';
    case 'Adjustment3%Words':
      return deal && deal.adjustments.length >= 3 ? `${numberToEnglish(deal.adjustments[2].percentage)} PERCENT` : '';
    case 'Adjustment3$':
      return deal && deal.adjustments.length >= 3 ? `${numToCurrency.format(deal.adjustments[2].value)}` : '';
    case 'Adjustment3$Words':
      return deal && deal.adjustments.length >= 3 ? `${numberToEnglish(deal.adjustments[2].value)} DOLLARS` : '';
    case 'Adjustment3$WordsAndNumber':
      return deal && deal.adjustments.length >= 3
        ? `${numberToEnglish(deal.adjustments[2].value)} DOLLARS (${numToCurrency.format(deal.adjustments[2].value)})`
        : '';
    case 'Adjustment4%':
      return deal && deal.adjustments.length >= 4 ? `${deal.adjustments[3].percentage}%` : '';
    case 'Adjustment4%Words':
      return deal && deal.adjustments.length >= 4 ? `${numberToEnglish(deal.adjustments[3].percentage)} PERCENT` : '';
    case 'Adjustment4$':
      return deal && deal.adjustments.length >= 4 ? `${numToCurrency.format(deal.adjustments[3].value)}` : '';
    case 'Adjustment4$Words':
      return deal && deal.adjustments.length >= 4 ? `${numberToEnglish(deal.adjustments[3].value)} DOLLARS` : '';
    case 'Adjustment4$WordsAndNumber':
      return deal && deal.adjustments.length >= 4
        ? `${numberToEnglish(deal.adjustments[3].value)} DOLLARS (${numToCurrency.format(deal.adjustments[3].value)})`
        : '';
    case 'P1Passport#':
      return purchaser[0].idType === 'Passport'
        ? purchaser[0].idNumber
        : purchaser[0].proofType === 'Passport'
        ? purchaser[0].proofNumber
        : '';
    case 'P1PermanentResidence#':
      return purchaser[0].idType === 'Permanent Resident Card' ? purchaser[0].idNumber : '';
    case 'P1BirthCertificate#':
      return purchaser[0].proofType === 'Birth Certificate' ? purchaser[0].proofNumber : '';
    case 'P1IndianStatus#':
      return purchaser[0].proofType === 'Indian Status' ? purchaser[0].proofNumber : '';
    case 'P1WorkPermit#':
      return purchaser[0].proofType === 'Work Permit' ? purchaser[0].proofNumber : '';
    case 'P1WorkPermitExpiry':
      return purchaser[0].proofType === 'Work Permit' && purchaser[0].proofExpiry
        ? convertAllDates(purchaser[0].proofExpiry, mergeValue.format)
        : '';
    case 'P2Passport#':
      return purchaser.length >= 2 && purchaser[1].idType === 'Passport'
        ? purchaser[1].idNumber
        : purchaser[1].proofType === 'Passport'
        ? purchaser[1].proofNumber
        : '';
    case 'P2PermanentResidence#':
      return purchaser.length >= 2 && purchaser[1].idType === 'Permanent Resident Card' ? purchaser[1].idNumber : '';
    case 'P2BirthCertificate#':
      return purchaser.length >= 2 && purchaser[1].proofType === 'Birth Certificate' ? purchaser[1].proofNumber : '';
    case 'P2IndianStatus#':
      return purchaser.length >= 2 && purchaser[1].proofType === 'Indian Status' ? purchaser[1].proofNumber : '';
    case 'P2WorkPermit#':
      return purchaser.length >= 2 && purchaser[1].proofType === 'Work Permit' ? purchaser[1].proofNumber : '';
    case 'P2WorkPermitExpiry':
      return purchaser.length >= 2 && purchaser[1].proofType === 'Work Permit' && purchaser[1].proofExpiry
        ? convertAllDates(purchaser[1].proofExpiry, mergeValue.format)
        : '';
    case 'PadName':
      return deal && deal.debit.length >= 1 ? `${deal.debit[0].name}` : '';
    case 'PadInstitution':
      return deal && deal.debit.length >= 1 ? `${deal.debit[0].institution}` : '';
    case 'PadBranch':
      return deal && deal.debit.length >= 1 ? `${deal.debit[0].branch}` : '';
    case 'PadAccount':
      return deal && deal.debit.length >= 1 ? `${deal.debit[0].account}` : '';
    case 'MaintenanceFeeByMonths':
      return unit && unit.maintenanceFee && unit.days ? `${numToCurrencyDecimal.format(unit.maintenanceFee * unit?.days)}` : '';
    case 'MaintenanceFeeByMonthsWords':
      return unit && unit.maintenanceFee && unit.days ? `${numberToCents(unit.maintenanceFee * unit?.days)}` : '';
    case 'MaintenanceTermByMonths':
      return unit && unit.days ? `${unit.days}` : '';
    case 'MaintenanceTermByMonthsWords':
      return unit && unit.days ? `${numberToEnglish(parseInt(unit.days, 10))}` : '';
    case 'MaintenanceFeeByAdjustment':
      return maintenance ? `${numToCurrencyDecimal.format(maintenance.value)}` : '';
    case 'MaintenanceFeeByAdjustmentWords':
      return maintenance ? `${numberToCents(maintenance.value)}` : '';
    case 'toP1FName':
      return toPurchaser.length >= 1 ? toPurchaser[0].firstName : '';
    case 'toP1LName':
      return toPurchaser.length >= 1 ? toPurchaser[0].lastName : '';
    case 'toP1FullName':
      return toPurchaser.length >= 1 ? `${toPurchaser[0].firstName} ${toPurchaser[0].lastName}` : '';
    case 'toP1Sin':
      return toPurchaser.length >= 1 ? toPurchaser[0].sin : '';
    case 'toP1Dob':
      return toPurchaser.length >= 1 ? convertAllDates(toPurchaser[0].dob, mergeValue.format) : '';
    case 'toP1Address':
      return toPurchaser.length >= 1 ? toPurchaser[0].streetAddress : '';
    case 'toP1Phone':
      return toPurchaser.length >= 1 ? toPurchaser[0].primaryPhone : '';
    case 'toP1Email':
      return toPurchaser.length >= 1 ? toPurchaser[0].email : '';
    case 'toP2FName':
      return toPurchaser.length >= 2 ? toPurchaser[1].firstName : '';
    case 'toP2LName':
      return toPurchaser.length >= 2 ? toPurchaser[1].lastName : '';
    case 'toP2FullName':
      return toPurchaser.length >= 2 ? `${toPurchaser[1].firstName} ${toPurchaser[1].lastName}` : '';
    case 'toP2Sin':
      return toPurchaser.length >= 2 ? toPurchaser[1].sin : '';
    case 'toP2Dob':
      return toPurchaser.length >= 2 ? convertAllDates(toPurchaser[1].dob, mergeValue.format) : '';
    case 'toP2Address':
      return toPurchaser.length >= 2 ? toPurchaser[1].streetAddress : '';
    case 'toP2Phone':
      return toPurchaser.length >= 2 ? toPurchaser[1].primaryPhone : '';
    case 'toP2Email':
      return toPurchaser.length >= 2 ? toPurchaser[1].email : '';
    case 'oldPurchaserInformation1':
      return differences.length > 0
        ? `Purchaser ${differences[0].purchaserNumber} ${capitalizeDifference(differences[0].type)}: ${getPurchaserInfo(
            purchaser[differences[0].purchaserNumber - 1],
            differences[0].type
          )}`
        : '';
    case 'newPurchaserInformation1':
      return differences.length > 0
        ? `Purchaser ${differences[0].purchaserNumber} ${capitalizeDifference(differences[0].type)}: ${getPurchaserInfo(
            toPurchaser[differences[0].purchaserNumber - 1],
            differences[0].type
          )}`
        : '';
    case 'oldPurchaserInformation2':
      return differences.length > 1
        ? `Purchaser ${differences[1].purchaserNumber} ${capitalizeDifference(differences[1].type)}: ${getPurchaserInfo(
            purchaser[differences[1].purchaserNumber - 1],
            differences[1].type
          )}`
        : '';
    case 'newPurchaserInformation2':
      return differences.length > 1
        ? `Purchaser ${differences[1].purchaserNumber} ${capitalizeDifference(differences[1].type)}: ${getPurchaserInfo(
            toPurchaser[differences[1].purchaserNumber - 1],
            differences[1].type
          )}`
        : '';
    case 'oldPurchaserInformation3':
      return differences.length > 2
        ? `Purchaser ${differences[2].purchaserNumber} ${capitalizeDifference(differences[2].type)}: ${getPurchaserInfo(
            purchaser[differences[2].purchaserNumber - 1],
            differences[2].type
          )}`
        : '';
    case 'newPurchaserInformation3':
      return differences.length > 2
        ? `Purchaser ${differences[2].purchaserNumber} ${capitalizeDifference(differences[2].type)}: ${getPurchaserInfo(
            toPurchaser[differences[2].purchaserNumber - 1],
            differences[2].type
          )}`
        : '';
    case 'oldPurchaserInformation4':
      return differences.length > 3
        ? `Purchaser ${differences[3].purchaserNumber} ${capitalizeDifference(differences[3].type)}: ${getPurchaserInfo(
            purchaser[differences[3].purchaserNumber - 1],
            differences[3].type
          )}`
        : '';
    case 'newPurchaserInformation4':
      return differences.length > 3
        ? `Purchaser ${differences[3].purchaserNumber} ${capitalizeDifference(differences[3].type)}: ${getPurchaserInfo(
            toPurchaser[differences[3].purchaserNumber - 1],
            differences[3].type
          )}`
        : '';
    case 'oldPurchaserInformation5':
      return differences.length > 4
        ? `Purchaser ${differences[4].purchaserNumber} ${capitalizeDifference(differences[4].type)}: ${getPurchaserInfo(
            purchaser[differences[4].purchaserNumber - 1],
            differences[4].type
          )}`
        : '';
    case 'newPurchaserInformation5':
      return differences.length > 4
        ? `Purchaser ${differences[4].purchaserNumber} ${capitalizeDifference(differences[4].type)}: ${getPurchaserInfo(
            toPurchaser[differences[4].purchaserNumber - 1],
            differences[4].type
          )}`
        : '';
    case 'UPG1TPrice':
      return deal.upgradeSetOne ? numToCurrencyDecimal.format((upgradeSetOne - upgradeCreditOne) * 1.13).toString() : '';
    case 'UPG1TPriceNetHST':
      return deal.upgradeSetOne ? numToCurrencyDecimal.format(upgradeSetOne).toString() : '';
    case 'UPG1Credit':
      return upgradeCreditOne
        ? numToCurrencyDecimal.format(upgradeCreditOne).toString()
        : project.defaultZeroValue
        ? project.defaultZeroValue
        : '$0';
    case 'UPG2TPrice':
      return deal.upgradeSetTwo ? numToCurrencyDecimal.format((upgradeSetTwo - upgradeCreditTwo) * 1.13).toString() : '';
    case 'UPG2TPriceNetHST':
      return deal.upgradeSetTwo ? numToCurrencyDecimal.format(upgradeSetTwo).toString() : '';
    case 'UPG2Credit':
      return upgradeCreditTwo
        ? numToCurrencyDecimal.format(upgradeCreditTwo).toString()
        : project.defaultZeroValue
        ? project.defaultZeroValue
        : '$0';
    case 'UPG3TPrice':
      return deal.upgradeSetThree ? numToCurrencyDecimal.format((upgradeSetThree - upgradeCreditThree) * 1.13).toString() : '';
    case 'UPG3TPriceNetHST':
      return deal.upgradeSetThree ? numToCurrencyDecimal.format(upgradeSetThree).toString() : '';
    case 'UPG3Credit':
      return upgradeCreditThree
        ? numToCurrencyDecimal.format(upgradeCreditThree).toString()
        : project.defaultZeroValue
        ? project.defaultZeroValue
        : '$0';
    case 'UPG1UPG2Total':
      return deal.upgradeSetOne && deal.upgradeSetTwo
        ? numToCurrencyDecimal.format((upgradeSetOne - upgradeCreditOne) * 1.13 + (upgradeSetTwo - upgradeCreditTwo) * 1.13).toString()
        : '';
    case 'UPG1UPG2TotalNet':
      return deal.upgradeSetOne && deal.upgradeSetTwo
        ? numToCurrencyDecimal.format(upgradeSetOne - upgradeCreditOne + (upgradeSetTwo - upgradeCreditTwo)).toString()
        : '';
    case 'UPG1UPG2UPG3Total':
      return deal.upgradeSetOne && deal.upgradeSetTwo && deal.upgradeSetThree
        ? numToCurrencyDecimal
            .format(
              (upgradeSetOne - upgradeCreditOne) * 1.13 +
                (upgradeSetTwo - upgradeCreditTwo) * 1.13 +
                (upgradeSetThree - upgradeCreditThree) * 1.13
            )
            .toString()
        : '';
    case 'UPG1UPG2UPG3TotalNet':
      return deal.upgradeSetOne && deal.upgradeSetTwo && deal.upgradeSetThree
        ? numToCurrencyDecimal
            .format(upgradeSetOne - upgradeCreditOne + (upgradeSetTwo - upgradeCreditTwo) + (upgradeSetThree - upgradeCreditThree))
            .toString()
        : '';
    case 'DecorSelection1TPrice':
      return deal.upgradeSetOne ? numToCurrencyDecimal.format(decorTotalSetOne) : '';
    case 'DecorSelection1ToTPrice':
      return deal.upgradeSetOne ? numToCurrencyDecimal.format(toDecorTotalSetOne) : '';
    case 'DecorSelection2TPrice':
      return deal.upgradeSetTwo ? numToCurrencyDecimal.format(decorTotalSetTwo) : '';
    case 'DecorSelection2ToTPrice':
      return deal.upgradeSetTwo ? numToCurrencyDecimal.format(toDecorTotalSetTwo) : '';
    case 'DecorSelection3TPrice':
      return deal.upgradeSetThree ? numToCurrencyDecimal.format(decorTotalSetThree) : '';
    case 'DecorSelection3ToTPrice':
      return deal.upgradeSetThree ? numToCurrencyDecimal.format(toDecorTotalSetThree) : '';
    case 'DecorSelectionTotalNetHST':
      return colourSelectionChoices.length
        ? numToCurrencyDecimal.format(colourSelectionChoices.reduce((a: any, b: any) => a + b.price, 0))
        : '';
    case 'DecorSelectionTotal':
      return colourSelectionChoices.length
        ? numToCurrencyDecimal.format(colourSelectionChoices.reduce((a: any, b: any) => a + b.price, 0) * 1.13)
        : '';
    default:
      return mergeValue.key;
  }
};

export const exportCsv = (fileName: string, columnList: any, initialData: any) => {
  const columns = columnList.filter((columnDef: any) => {
    return !columnDef.hidden && columnDef.field && columnDef.export !== false;
  });

  const data = initialData.map((rowData: any) =>
    columns.map((columnDef: any) => {
      if (fileName === 'rangeReport.csv') {
        if (columnDef.field === 'range') {
          return `${numToCurrency.format(rowData.rangeMin)} to ${numToCurrency.format(rowData.rangeMax)}`;
        }
      }
      if (fileName === 'DailySummary.csv') {
        if (columnDef.field === 'status') {
          return `${rowData.status}`;
        }
      }
      if (fileName === 'allOutstandingRows.csv') {
        if (columnDef.field === 'suite') {
          return `${rowData.unit.suite}`;
        }
      }
      if (fileName === 'worksheets.csv') {
        if (columnDef.field === 'suite') {
          return `${rowData.unit.suite}`;
        }
        if (columnDef.field === 'purchaser') {
          let purchasers = rowData.purchasers.map((purchaser: any) => `${purchaser.firstName} ${purchaser.lastName}`);
          return purchasers.join(', ');
        }
        if (columnDef.field === 'options') {
          let options = rowData.options.map((option: any) => `${option.name} - x${option.purchaseAmount}`);
          return options.join(', ');
        }
        if (columnDef.field === 'approval') {
          if (rowData.managerApproval) {
            return 'Approved';
          } else return 'Not Approved';
        }
        if (!columnDef.field) {
          return '';
        }
      }
      if (fileName === 'dealWorksheets.csv') {
        if (columnDef.field === 'suite') {
          return `${rowData.unit.suite}`;
        }
        if (columnDef.field === 'purchaser') {
          let purchasers = rowData.purchasers.map((purchaser: any) => `${purchaser.firstName} ${purchaser.lastName}`);
          return purchasers.join(', ');
        }
        if (columnDef.field === 'options') {
          let options = rowData.options.map((option: any) => `${option.name} - x${option.purchaseAmount}`);
          return options.join(', ');
        }
        if (columnDef.field === 'joinWaitlist') {
          if (rowData.joinWaitlist) {
            return true;
          } else return false;
        }
        if (!columnDef.field) {
          return '';
        }
      }
      if (fileName === 'wishlists.csv') {
        if (columnDef.field === 'wishlistChoices') {
          let wishlistChoices = rowData.wishlistChoices.map((wishlist: any) => {
            let wishlistModelTypes = wishlist.modelTypes.map((models: string) => models);
            wishlistModelTypes.join(', ');
            return `${wishlist.unitType} - ${wishlistModelTypes} - ${wishlist.level} - ${wishlist.exposure}`;
          });
          return wishlistChoices.join(', ');
        }
        if (columnDef.field === 'purchaser') {
          let purchasers = rowData.purchasers.map((purchaser: any) => `${purchaser.firstName} ${purchaser.lastName}`);
          return purchasers.join(', ');
        }
        if (columnDef.field === 'options') {
          let options = rowData.options.map((option: any) => `${option.name} - x${option.purchaseAmount}`);
          return options.join(', ');
        }
        if (columnDef.field === 'joinWaitlist') {
          if (rowData.joinWaitlist) {
            return 'Yes';
          } else return 'No';
        }
      }
      return columnDef.render ? columnDef.render(rowData) : rowData[columnDef.field];
    })
  );

  const builder = new CsvBuilder(fileName);
  builder
    .setDelimeter(',')
    .setColumns(columns.map((columnDef: any) => columnDef.title))
    .addRows(data)
    .exportFile();
};

export const exportClosing = (fileName: string, columnList: any, initialData: any) => {
  const columns = columnList.filter((columnDef: any) => {
    return !columnDef.hidden && columnDef.export !== false;
  });

  const data = initialData.map((rowData: any, index: number) =>
    columns.map((columnDef: any) => {
      if (columnDef.Header === 'Suite') {
        return rowData.suite;
      }
      if (columnDef.Header === 'Count') {
        return index + 1;
      }
      return columnDef.accessor ? columnDef.accessor(rowData) : rowData[columnDef.Header];
    })
  );

  const builder = new CsvBuilder(fileName);
  builder
    .setDelimeter(',')
    .setColumns(columns.map((columnDef: any) => columnDef.Header))
    .addRows(data)
    .exportFile();
};

export const exportAllSelectedRows = (fileName: string, allColumns: any, selection: any) => {
  new CsvBuilder(fileName)
    .setColumns(allColumns.map((col: any) => col.title))
    .addRows(
      selection.map((rowData: any) =>
        allColumns.map((columnDef: any) => {
          return columnDef.render ? columnDef.render(rowData) : rowData[columnDef.field];
        })
      )
    )
    .exportFile();
};

export const unitContent = (updatedUnit: IUnit, unit: IUnit) => {
  let difference: any = Object.keys(updatedUnit).filter(
    (k: any) => updatedUnit[k as keyof typeof updatedUnit] !== unit[k as keyof typeof unit]
  );
  let string = '';

  if (difference.includes('status')) {
    string += `Status has been changed from ${unit.status} to ${updatedUnit.status}.\n`;
  }
  if (difference.includes('suite')) {
    string += `Suite has been changed from ${unit.suite} to ${updatedUnit.suite}.\n`;
  }
  if (difference.includes('level')) {
    string += `Level has been changed from ${unit.level} to ${updatedUnit.level}.\n`;
  }
  if (difference.includes('basePrice')) {
    string += `Base Price has been changed from ${numToCurrency.format(unit.basePrice)} to ${numToCurrency.format(
      updatedUnit.basePrice
    )}.\n`;
  }
  if (difference.includes('modelType')) {
    string += `Model Type has been changed from ${unit.modelType} to ${updatedUnit.modelType}.\n`;
  }
  if (difference.includes('unitType')) {
    string += `Unit Type has been changed from ${unit.unitType} to ${updatedUnit.unitType}.\n`;
  }
  if (difference.includes('bathroom')) {
    string += `Bathroom has been changed from ${unit.bathroom} to ${updatedUnit.bathroom}.\n`;
  }
  if (difference.includes('size')) {
    string += `Size has been changed from ${unit.size} to ${updatedUnit.size}.\n`;
  }
  if (difference.includes('outdoorSize')) {
    string += `Outdoor Size has been changed from ${unit.outdoorSize} to ${updatedUnit.outdoorSize}.\n`;
  }
  if (difference.includes('outdoorType')) {
    string += `Outdoor Type has been changed from ${unit.outdoorType} to ${updatedUnit.outdoorType}.\n`;
  }
  if (difference.includes('tempAllocation')) {
    string += `Temporary Allocation has been change from ${unit.tempAllocation ? unit.tempAllocation : 'None'} to ${
      updatedUnit.tempAllocation
    }.\n`;
  }
  if (difference.includes('allocation')) {
    let unitAllocation = '';
    let updatedAllocation = '';
    if (unit.allocation) {
      unitAllocation = unit?.allocation?.fullName!;
    } else {
      unitAllocation = 'None';
    }
    if (updatedUnit.allocation) {
      updatedAllocation = updatedUnit?.allocation?.fullName!;
    } else {
      updatedAllocation = 'None';
    }

    string += `Allocation has been changed from ${unitAllocation} to ${updatedAllocation ? updatedAllocation : 'None'}.\n`;
  }

  return string;
};

export const dealContent = (updatedDeal: IDeal, deal: IDeal) => {
  let difference: any = Object.keys(updatedDeal).filter(
    (k: any) => updatedDeal[k as keyof typeof updatedDeal] !== deal[k as keyof typeof deal]
  );
  let string = '';

  if (updatedDeal.salesRep && deal.salesRep.fullName !== updatedDeal.salesRep.fullName) {
    string += `Sales Rep has been changed from ${deal?.salesRep?.fullName!} to ${updatedDeal?.salesRep?.fullName!}.\n\n`;
  }
  if (difference.includes('signDate')) {
    string += `Sign Date has been changed from ${convertAllDates(deal.signDate, 'PPPP')} to ${convertAllDates(
      updatedDeal.signDate,
      'PPPP'
    )}.\n\n`;
  }
  if (difference.includes('executeDate')) {
    string += `Execute Date has been changed from ${convertAllDates(deal.executeDate, 'PPPP')} to ${convertAllDates(
      updatedDeal.executeDate,
      'PPPP'
    )}.\n\n`;
  }
  if (difference.includes('firmDate')) {
    string += `Firm Date has been changed from ${convertAllDates(deal.firmDate, 'PPPP')} to ${convertAllDates(
      updatedDeal.firmDate,
      'PPPP'
    )}.\n\n`;
  }
  if (difference.includes('basePrice')) {
    string += `Deal Base Price has been changed from ${numToCurrency.format(deal.basePrice)} to ${numToCurrency.format(
      updatedDeal.basePrice
    )}.\n\n`;
  }

  if (!string) {
    string = 'No Change';
  }

  return string;
};

export const purchaserContent = (updatedPurchaser: IPurchaserInfo, purchaser: IPurchaserInfo) => {
  let difference: any = Object.keys(updatedPurchaser).filter(
    (k: any) => updatedPurchaser[k as keyof typeof updatedPurchaser] !== purchaser[k as keyof typeof purchaser]
  );
  let string = '';

  if (difference.includes('firstName')) {
    string += `First Name has been changed from ${purchaser.firstName} to ${updatedPurchaser.firstName}.\n`;
  }
  if (difference.includes('lastName')) {
    string += `Last Name has been changed from ${purchaser.lastName} to ${updatedPurchaser.lastName}.\n`;
  }
  if (difference.includes('email')) {
    string += `Email has been changed from ${purchaser.email} to ${updatedPurchaser.email}.\n`;
  }
  if (difference.includes('dob')) {
    string += `Date of Birth has been changed from ${convertAllDates(purchaser.dob, 'P')} to ${convertAllDates(
      updatedPurchaser.dob,
      'P'
    )}.\n`;
  }
  if (difference.includes('streetAddress')) {
    string += `Street Address has been changed from ${purchaser.streetAddress} to ${updatedPurchaser.streetAddress}.\n`;
  }
  if (difference.includes('city')) {
    string += `City has been changed from ${purchaser.city} to ${updatedPurchaser.city}.\n`;
  }
  if (difference.includes('province')) {
    string += `Province has been changed from ${purchaser.province} to ${updatedPurchaser.province}.\n`;
  }
  if (difference.includes('postalCode')) {
    string += `Postal Code has been changed from ${purchaser.postalCode} to ${updatedPurchaser.postalCode}.\n`;
  }
  if (difference.includes('occupation')) {
    string += `Occupation has been changed from ${purchaser.occupation} to ${updatedPurchaser.occupation}.\n`;
  }
  if (difference.includes('employer')) {
    string += `Employer has been changed from ${purchaser.employer} to ${updatedPurchaser.employer}.\n`;
  }
  if (difference.includes('purchaserType')) {
    string += `PurchaserType has been changed from ${purchaser.purchaserType} to ${updatedPurchaser.purchaserType}.\n`;
  }
  if (difference.includes('primaryPhone')) {
    string += `Primary Phone has been changed from ${purchaser.primaryPhone} to ${updatedPurchaser.primaryPhone}.\n`;
  }
  if (difference.includes('idType')) {
    string += `ID Type has been changed from ${purchaser.idType} to ${updatedPurchaser.idType}.\n`;
  }
  if (difference.includes('idNumber')) {
    string += `ID Number has been changed from ${purchaser.idNumber} to ${updatedPurchaser.idNumber}.\n`;
  }
  if (difference.includes('idExpiry')) {
    string += `ID Expiry has been changed from ${purchaser.idExpiry} to ${updatedPurchaser.idExpiry}.\n`;
  }
  if (difference.includes('idJurisdiction')) {
    string += `ID Jurisdiction has been changed from ${purchaser.idJurisdiction} to ${updatedPurchaser.idJurisdiction}.\n`;
  }
  if (difference.includes('sin')) {
    string += `SIN has been changed from ${purchaser.sin} to ${updatedPurchaser.sin}.\n`;
  }

  if (!string) {
    string = 'No Change';
  }

  return string;
};

export const realtorContent = (updatedRealtor: IRealtorInfo, realtor: IRealtorInfo) => {
  let difference: any = Object.keys(updatedRealtor).filter(
    (k: any) => updatedRealtor[k as keyof typeof updatedRealtor] !== realtor[k as keyof typeof realtor]
  );
  let string = '';

  if (difference.includes('firstName')) {
    string += `First Name has been changed from ${realtor.firstName} to ${updatedRealtor.firstName}.\n`;
  }
  if (difference.includes('lastName')) {
    string += `Last Name has been changed from ${realtor.lastName} to ${updatedRealtor.lastName}.\n`;
  }
  if (difference.includes('email')) {
    string += `Email has been changed from ${realtor.email} to ${updatedRealtor.email}.\n`;
  }
  if (difference.includes('streetAddress')) {
    string += `Street Address has been changed from ${realtor.streetAddress} to ${updatedRealtor.streetAddress}.\n`;
  }
  if (difference.includes('city')) {
    string += `City has been changed from ${realtor.city} to ${updatedRealtor.city}.\n`;
  }
  if (difference.includes('province')) {
    string += `Province has been changed from ${realtor.province} to ${updatedRealtor.province}.\n`;
  }
  if (difference.includes('postalCode')) {
    string += `Postal Code has been changed from ${realtor.postalCode} to ${updatedRealtor.postalCode}.\n`;
  }
  if (difference.includes('brokeragePhone')) {
    string += `Brokerage Phone has been changed from ${realtor.brokeragePhone} to ${updatedRealtor.brokeragePhone}.\n`;
  }
  if (difference.includes('directPhone')) {
    string += `Direct Phone has been changed from ${realtor.directPhone} to ${updatedRealtor.directPhone}.\n`;
  }
  if (difference.includes('brokerageFax')) {
    string += `Brokerage Fax has been changed from ${realtor.brokerageFax} to ${updatedRealtor.brokerageFax}.\n`;
  }

  if (!string) {
    string = 'No Change';
  }

  return string;
};

export const depositContent = (updatedDeposit: IDealDeposit, deposit: IDealDeposit) => {
  let difference: any = Object.keys(updatedDeposit).filter(
    (k: any) => updatedDeposit[k as keyof typeof updatedDeposit] !== deposit[k as keyof typeof deposit]
  );
  let string = '';

  if (difference.includes('accountNumber')) {
    string += `Account Number has been changed from ${deposit.accountNumber} to ${updatedDeposit.accountNumber}.\n`;
  }
  if (difference.includes('chequeDate')) {
    let chequeDate: string = '';
    if (deposit.chequeDate) {
      chequeDate = `${convertDate(deposit.chequeDate)}`;
    } else {
      chequeDate = 'None';
    }
    string += `Cheque Date has been changed from ${chequeDate} to ${convertDate(updatedDeposit.chequeDate)}.\n`;
  }
  if (difference.includes('chequeType')) {
    let chequeType: string = '';
    if (deposit.chequeDate) {
      chequeType = `${deposit.chequeType}`;
    } else {
      chequeType = 'None';
    }
    string += `Cheque Type has been changed from ${chequeType} to ${updatedDeposit.chequeType}.\n`;
  }
  if (difference.includes('chequeNumber')) {
    string += `Cheque Number has been changed from ${deposit.chequeNumber} to ${updatedDeposit.chequeNumber}.\n`;
  }
  if (difference.includes('chequeAmount')) {
    string += `Cheque Amount has been changed from ${numToCurrency.format(deposit.chequeAmount!)} to ${numToCurrency.format(
      updatedDeposit.chequeAmount!
    )}.\n`;
  }

  return string;
};

export function numberToEnglish(n: number) {
  let string = n.toString(),
    units,
    tens,
    scales,
    start,
    end,
    chunks,
    chunksLen,
    chunk,
    ints,
    i,
    word,
    words;

  let and = 'AND';

  /* Is number zero? */
  if (string === '0.25') {
    return 'A QUARTER';
  }
  if (string === '0.5' || string === '0.50') {
    return 'A HALF';
  }
  if (parseInt(string) === 0) {
    return 'ZERO';
  }

  /* Array of units as words */
  units = [
    '',
    'ONE',
    'TWO',
    'THREE',
    'FOUR',
    'FIVE',
    'SIX',
    'SEVEN',
    'EIGHT',
    'NINE',
    'TEN',
    'ELEVEN',
    'TWELVE',
    'THIRTEEN',
    'FOURTEEN',
    'FIFTEEN',
    'SIXTEEN',
    'SEVENTEEN',
    'EIGHTEEN',
    'NINETEEN',
  ];

  /* Array of tens as words */
  tens = ['', '', 'TWENTY', 'THIRTY', 'FORTY', 'FIFTY', 'SIXTY', 'SEVENTY', 'EIGHTY', 'NINETY'];

  /* Array of scales as words */
  scales = [
    '',
    'THOUSAND',
    'MILLION',
    'BILLION',
    'TRILLION',
    'QUADRILLION',
    'QUINTILLION',
    'SEXTILLION',
    'SEPTILLION',
    'OCTILLION',
    'NONILLION',
    'DECILLION',
    'UNDECILLION',
    'DUODECILLION',
    'TREDECILLION',
  ];

  /* Split user arguemnt into 3 digit chunks from right to left */
  start = string.length;
  chunks = [];
  while (start > 0) {
    end = start;
    chunks.push(string.slice((start = Math.max(0, start - 3)), end));
  }

  /* Check if function has enough scale words to be able to stringify the user argument */
  chunksLen = chunks.length;
  if (chunksLen > scales.length) {
    return '';
  }

  /* Stringify each integer in each chunk */
  words = [];
  for (i = 0; i < chunksLen; i++) {
    chunk = parseInt(chunks[i]);

    if (chunk) {
      /* Split chunk into array of individual integers */
      ints = chunks[i].split('').reverse().map(parseFloat);

      /* If tens integer is 1, i.e. 10, then add 10 to units integer */
      if (ints[1] === 1) {
        ints[0] += 10;
      }

      /* Add scale word if chunk is not zero and array item exists */
      if ((word = scales[i])) {
        words.push(word);
      }

      /* Add unit word if array item exists */
      if ((word = units[ints[0]])) {
        words.push(word);
      }

      /* Add tens word if array item exists */
      if ((word = tens[ints[1]])) {
        words.push(word);
      }

      /* Add 'and' string after units or tens integer if: */
      if (ints.length > 1) {
        if (ints[0] || ints[1]) {
          /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
          if (ints[2] || (!i && chunksLen)) {
            words.push(and);
          }
        }
      }

      /* Add hundreds word if array item exists */
      if ((word = units[ints[2]])) {
        words.push(word + ' HUNDRED');
      }
    }
  }

  if (words[words.length - 1] === 'AND') {
    words.pop();
  }

  return words.reverse().join(' ');
}

export function numberToCents(n: number) {
  const ones = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine '];
  const teen = ['ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
  const tens = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
  const high = ['hundred ', 'thousand ', 'million ', 'billion '];

  const tensOnes = (t: any, o: any) =>
    +t == 0 ? ones[+o] : +t == 1 ? teen[+o] : +t > 1 && +o == 0 ? tens[+t - 2] : tens[+t - 2] + ' ' + ones[+o];
  const fltN = (float: any) => [...parseFloat(float).toFixed(2)];

  const stepper = (array: any) => {
    const D = array[0];
    const C = array[1];
    let size = D.length;
    let word;
    switch (size) {
      case 0:
        word = C;
        break;
      case 1:
        word = tensOnes(0, D[0]) + 'dollars ' + C;
        break;
      case 2:
        word = tensOnes(D[1], D[0]) + 'dollars ' + C;
        break;
      case 3:
        word = tensOnes(0, D[2]) + high[0] + 'and ' + tensOnes(D[1], D[0]) + 'dollars ' + C;
        break;
      case 4:
        word = tensOnes(0, D[3]) + high[1] + tensOnes(0, D[2]) + high[0] + 'and ' + tensOnes(D[1], D[0]) + 'dollars ' + C;
        break;
      case 5:
        word = tensOnes(D[4], D[3]) + high[1] + tensOnes(0, D[2]) + high[0] + 'and ' + tensOnes(D[1], D[0]) + 'dollars ' + C;
        break;
      case 6:
        word =
          tensOnes(0, D[5]) +
          high[0] +
          tensOnes(D[4], D[3]) +
          high[1] +
          tensOnes(0, D[2]) +
          high[0] +
          'and ' +
          tensOnes(D[1], D[0]) +
          'dollars ' +
          C;
        break;
      case 7:
        word =
          tensOnes(0, D[6]) +
          high[2] +
          tensOnes(0, D[5]) +
          high[0] +
          tensOnes(D[4], D[3]) +
          high[1] +
          tensOnes(0, D[2]) +
          high[0] +
          'and ' +
          tensOnes(D[1], D[0]) +
          'dollars ' +
          C;
        break;
      case 8:
        word =
          tensOnes(D[7], D[6]) +
          high[2] +
          tensOnes(0, D[5]) +
          high[0] +
          tensOnes(D[4], D[3]) +
          high[1] +
          tensOnes(0, D[2]) +
          high[0] +
          'and ' +
          tensOnes(D[1], D[0]) +
          'dollars ' +
          C;
        break;
      case 9:
        word =
          tensOnes(0, D[8]) +
          high[0] +
          tensOnes(D[7], D[6]) +
          high[2] +
          tensOnes(0, D[5]) +
          high[0] +
          tensOnes(D[4], D[3]) +
          high[1] +
          tensOnes(0, D[2]) +
          high[0] +
          'and ' +
          tensOnes(D[1], D[0]) +
          'dollars ' +
          C;
        break;
      case 10:
        word =
          tensOnes(0, D[9]) +
          high[3] +
          tensOnes(0, D[8]) +
          high[0] +
          tensOnes(D[7], D[6]) +
          high[2] +
          tensOnes(0, D[5]) +
          high[0] +
          tensOnes(D[4], D[3]) +
          high[1] +
          tensOnes(0, D[2]) +
          high[0] +
          'and ' +
          tensOnes(D[1], D[0]) +
          'dollars ' +
          C;
        break;
      default:
        break;
    }
    word = word.trim();
    word =
      word == 'one dollars'
        ? 'one dollar'
        : word == 'dollars and one cent'
        ? 'one cent'
        : word == 'one dollars and one cent'
        ? 'one dollar and one cent'
        : word == 'and undefined-undefinedcents'
        ? ''
        : word;
    word = word
      .replace(/(thousand|million)\s(hundred)/g, '$1')
      .replace(/(million)\s(thousand)/g, '$1')
      .replace(/(tycents)/g, 'ty cents')
      .replace(/(tydollars)/g, 'ty dollars');
    return word;
  };

  let R = fltN(n);
  let dec, c, cents;
  dec = R.splice(-3, 3);
  c = tensOnes(dec[1], dec[2]);
  cents = c == 'one ' ? 'and one cent' : c == '' ? '' : `and ${c}cents`;
  return stepper([R.reverse(), cents]).toUpperCase();
}

export const optionsLeft = (deals: any, option: any) => {
  const validDeals = deals.filter(
    (deal: any) =>
      !deal.draft &&
      !deal.cancelled.dateCancelled &&
      !deal.rescission.dateRescinded &&
      (deal.unit.status === 'F' || deal.unit.status === 'C')
  );
  const validDealOptions = Array.prototype.concat.apply(
    [],
    validDeals.map((deal: any) => deal.options)
  );

  const spent = validDealOptions
    .filter((dealOption) => dealOption.name === option)
    .map((dealOption) => parseInt(dealOption.purchaseAmount))
    .reduce((a, b) => a + b, 0);

  return spent;
};

export const createPdf = async (
  merge: any,
  project: any,
  unit: any,
  purchasers: any,
  dealDeposits: any,
  dealOptions: any,
  realtor: any = null,
  toUnit: any = null,
  options: any = null,
  deposits: any = null,
  deal: any = null,
  toPurchasers: any = null,
  fintrac: boolean = false,
  merges: any = [],
  colourSelectionChoices: any = [],
  removeFloorPlan: boolean = false
) => {
  const mergedPdf = await PDFDocument.create();

  const url = merge?.getUrl!;

  let pdf = await fetch(url).then(async (res) => await res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(pdf);
  let helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

  let pages = pdfDoc.getPages();

  // Draw Merge Fields

  await merge?.mergeFields.forEach(async (mergeField: any) => {
    let mergePages = pages[mergeField.pageNumber];

    let mergeValue = getMergeValues(
      mergeField,
      project,
      unit,
      merge,
      purchasers,
      dealDeposits,
      dealOptions,
      realtor,
      toUnit,
      options,
      deposits,
      null,
      deal,
      toPurchasers,
      colourSelectionChoices
    );

    if (
      mergeField.key === 'P1Check' ||
      (mergeField.key === 'P2Check' && purchasers.length > 1) ||
      (mergeField.key === 'P3Check' && purchasers.length > 2) ||
      (mergeField.key === 'P4Check' && purchasers.length > 3)
    ) {
      mergePages.drawLine({
        start: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
        end: { x: mergeField.x + 8, y: mergePages.getHeight() - mergeField.y + 4 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      mergePages.drawLine({
        start: { x: mergeField.x, y: mergePages.getHeight() - mergeField.y + 1 },
        end: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
    } else if (
      mergeField.key !== 'P1Check' &&
      mergeField.key !== 'P2Check' &&
      mergeField.key !== 'P3Check' &&
      mergeField.key !== 'P4Check'
    ) {
      let checkMediaBox = mergePages.getMediaBox();
      let x = mergeField.x;
      let y = mergePages.getHeight() - mergeField.y - 3;

      if (checkMediaBox && (checkMediaBox.x !== 0 || checkMediaBox.y !== 0)) {
        x = mergeField.x - Math.abs(checkMediaBox.x);
        y = mergePages.getHeight() - mergeField.y - 3 - Math.abs(checkMediaBox.y);
      }

      let textProperties: any = {
        x: x,
        y: y,
        size: mergeField.fontSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      };

      if (mergeField.wrap) {
        mergeValue = wrapText(mergeValue, mergeField.wrap * 2 + 75, helveticaFont, 9);
        if (mergeValue.lineHeight) {
          textProperties.lineHeight = mergeValue.lineHeight;
          textProperties.y = textProperties.y + 10;
        }
        mergeValue = mergeValue.text;
      }

      mergePages.drawText(mergeValue, textProperties);
    }
  });

  // Fintrac Details
  if (fintrac) {
    if (!purchasers[0].corp) {
      // B1 Fintrac
      pages[1].drawLine({
        start: { x: 68, y: 424 },
        end: { x: 73, y: 429 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pages[1].drawLine({
        start: { x: 65, y: 426 },
        end: { x: 68, y: 424 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      // Draw CheckBoxes
      if (!checkHighRisk(purchasers[0].country)) {
        pages[2].drawLine({
          start: { x: 75, y: 584 },
          end: { x: 80, y: 589 },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75,
        });
        pages[2].drawLine({
          start: { x: 72, y: 586 },
          end: { x: 75, y: 584 },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75,
        });
      } else {
        pages[2].drawLine({
          start: { x: 75, y: 243 },
          end: { x: 80, y: 248 },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75,
        });
        pages[2].drawLine({
          start: { x: 72, y: 245 },
          end: { x: 75, y: 243 },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75,
        });
      }

      if (unit.type === 'residential') {
        // Page 3 Residential
        pages[3].drawLine({
          start: { x: 68, y: 613 },
          end: { x: 73, y: 618 },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75,
        });
        pages[3].drawLine({
          start: { x: 65, y: 615 },
          end: { x: 68, y: 613 },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75,
        });
      } else if (unit.type === 'commercial') {
        // Page 3 Commercial
        pages[3].drawLine({
          start: { x: 68, y: 598 },
          end: { x: 73, y: 603 },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75,
        });
        pages[3].drawLine({
          start: { x: 65, y: 600 },
          end: { x: 68, y: 598 },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75,
        });
      } else {
        // Page 3 Other
        pages[3].drawLine({
          start: { x: 68, y: 583 },
          end: { x: 73, y: 588 },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75,
        });
        pages[3].drawLine({
          start: { x: 65, y: 585 },
          end: { x: 68, y: 583 },
          thickness: 1,
          color: rgb(0, 0, 0),
          opacity: 0.75,
        });
      }
    } else {
      pages[1].drawLine({
        start: { x: 68, y: 399 },
        end: { x: 73, y: 404 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pages[1].drawLine({
        start: { x: 65, y: 401 },
        end: { x: 68, y: 399 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pages[1].drawLine({
        start: { x: 68, y: 369 },
        end: { x: 73, y: 374 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pages[1].drawLine({
        start: { x: 65, y: 371 },
        end: { x: 68, y: 369 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pages[1].drawLine({
        start: { x: 68, y: 312 },
        end: { x: 73, y: 317 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pages[1].drawLine({
        start: { x: 65, y: 314 },
        end: { x: 68, y: 312 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pages[1].drawLine({
        start: { x: 68, y: 272 },
        end: { x: 73, y: 277 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pages[1].drawLine({
        start: { x: 65, y: 274 },
        end: { x: 68, y: 272 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pages[2].drawLine({
        start: { x: 75, y: 598 },
        end: { x: 80, y: 603 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
      pages[2].drawLine({
        start: { x: 72, y: 600 },
        end: { x: 75, y: 598 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });
    }
  }

  let copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
  copiedPages.forEach((page) => mergedPdf.addPage(page));

  if (toUnit) {
    if (!removeFloorPlan) {
      if (!toUnit.floorPlan) {
        return;
      }
      let floorPlan = await fetch(toUnit.getUrl!).then(async (res) => await res.arrayBuffer());
      const floorPlanDoc = await PDFDocument.load(floorPlan);
      helveticaFont = await floorPlanDoc.embedFont(StandardFonts.Helvetica);

      let floorPlanPages = floorPlanDoc.getPages();
      toUnit.floorPlan.mergeFields.forEach(async (mergeField: any) => {
        let mergePages = floorPlanPages[0];
        let mergeValue = getMergeValues(
          mergeField,
          project,
          toUnit,
          merge,
          purchasers,
          dealDeposits,
          dealOptions,
          realtor,
          toUnit,
          options,
          deposits,
          null,
          deal,
          toPurchasers
        );

        mergePages.drawText(mergeValue!, {
          x: mergeField.x,
          y: mergePages.getHeight() - mergeField.y - 3,
          size: mergeField.fontSize,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        });
      });

      copiedPages = await mergedPdf.copyPages(floorPlanDoc, floorPlanDoc.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }
  }

  if (merges.length > 0) {
    let arrayOfPdfs: Array<any> = [];
    for (let file of merges) {
      let templateUrl = await fetch(file!.getUrl!).then(async (res) => await res.arrayBuffer());
      arrayOfPdfs.push(templateUrl);
    }

    for (let [index, document] of arrayOfPdfs.entries()) {
      document = await PDFDocument.load(document);
      let pages = document.getPages();
      const helveticaFont = await document.embedFont(StandardFonts.Helvetica);

      await merges[index].mergeFields.forEach(async (mergeField: any) => {
        let mergePages = pages[mergeField.pageNumber];

        let mergeValue = getMergeValues(
          mergeField,
          project,
          toUnit,
          merge,
          purchasers,
          dealDeposits,
          dealOptions,
          realtor,
          toUnit,
          options,
          deposits,
          null,
          deal,
          toPurchasers
        );

        mergePages.drawText(mergeValue!, {
          x: mergeField.x,
          y: mergePages.getHeight() - mergeField.y - 3,
          size: mergeField.fontSize,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        });
      });

      let copiedPages = await mergedPdf.copyPages(document, document.getPageIndices());
      copiedPages.forEach((page: any) => mergedPdf.addPage(page));
    }
  }

  const pdfBytes: any = await mergedPdf.save();

  const blob = new Blob([pdfBytes]);
  const file = new File([blob], `${merge.name}.pdf`, { type: 'application/pdf' });

  return file;
};

export function download(file: any, filename: string, type: string) {
  const element = document.createElement('a');
  element.download = filename;
  let binaryData = [];
  binaryData.push(file);
  element.href = URL.createObjectURL(new Blob(binaryData, { type: type }));
  element.click();
}

export const combinePdfs = async (files: any[]) => {
  const mergedPdf = await PDFDocument.create();
  await Promise.all(
    files.map(async (url: any) => {
      let pdfDoc: any = url;
      if (pdfDoc.name) {
        pdfDoc = await readFileAsync(url);
      }
      const fintracDoc = await PDFDocument.load(pdfDoc);
      let copiedPages = await mergedPdf.copyPages(fintracDoc, fintracDoc.getPageIndices());
      copiedPages.forEach((page: any) => mergedPdf.addPage(page));
    })
  );
  const mergedPdfFile = await mergedPdf.save();
  return mergedPdfFile;
};

const readFileAsync = (file: any) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

export const downloadDistributionFiles = async (files: any, project: string, setLoading: any = null) => {
  const zip = new JSZip();

  //fetches the files and saves the files as blobs in an array

  const addFilesToZip: any = async (fileBlobs: any) => {
    let suiteZip = zip.folder(`${fileBlobs.suite}`);
    if (fileBlobs.depositImages.length) {
      await fileBlobs.depositImages.forEach((deposit: any) => {
        if (deposit.pdf.type === 'image/jpeg') {
          suiteZip?.file(`${fileBlobs.suite} - ${deposit.name}.jpeg`, deposit.pdf);
        } else if (deposit.pdf.type === 'application/pdf') {
          suiteZip?.file(`${fileBlobs.suite} - ${deposit.name}.pdf`, deposit.pdf);
        } else if (deposit.pdf.type === 'image/png') {
          suiteZip?.file(`${fileBlobs.suite} - ${deposit.name}.png`, deposit.pdf);
        }
      });
    }

    if (fileBlobs.documents.length) {
      await fileBlobs.documents.forEach((document: any) => {
        let blob = new Blob([document.pdf]);
        suiteZip?.file(`${fileBlobs.suite} - ${document.name}.pdf`, blob);
      });
    }

    if (fileBlobs.purchasers.length) {
      await fileBlobs.purchasers.forEach((purchaser: any) => {
        if (purchaser.pdf.type === 'image/jpeg') {
          suiteZip?.file(`${fileBlobs.suite} - ${purchaser.name}.jpeg`, purchaser.pdf);
        } else if (purchaser.pdf.type === 'application/pdf') {
          suiteZip?.file(`${fileBlobs.suite} - ${purchaser.name}.pdf`, purchaser.pdf);
        } else if (purchaser.pdf.type === 'image/png') {
          suiteZip?.file(`${fileBlobs.suite} - ${purchaser.name}.png`, purchaser.pdf);
        }
      });
    }

    if (fileBlobs.mortgage && fileBlobs.mortgage.pdf) {
      if (fileBlobs.mortgage.pdf.type === 'image/jpeg') {
        suiteZip?.file(`${fileBlobs.suite} - Mortgage.jpeg`, fileBlobs.mortgage.pdf);
      } else if (fileBlobs.mortgage.pdf.type === 'application/pdf') {
        suiteZip?.file(`${fileBlobs.suite} - Mortgage.pdf`, fileBlobs.mortgage.pdf);
      } else if (fileBlobs.mortgage.pdf.type === 'image/png') {
        suiteZip?.file(`${fileBlobs.suite} - Mortgage.png`, fileBlobs.mortgage.pdf);
      }
    }
  };

  //downloads the zip
  const downloadZip = (zip: JSZip) => {
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `${project}.zip`);
    });
  };

  for (let file of files) {
    await addFilesToZip(file);
  }

  await downloadZip(zip);
};

export const downloadDocuments = async (files: any, project: string, setLoading: any = null) => {
  const zip = new JSZip();

  //fetches the files and saves the files as blobs in an array

  const addFilesToZip: any = async (fileBlobs: any) => {
    await fileBlobs.forEach((fileBlob: any, index: number) => {
      let blob = new Blob([fileBlob.pdf]);
      zip.file(`${fileBlob.suite}.pdf`, blob);
    });
  };
  //downloads the zip
  const downloadZip = (zip: JSZip) => {
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `${project}.zip`);
    });
    if (setLoading) {
      setLoading(false);
    }
  };

  await addFilesToZip(files);
  await downloadZip(zip);
};

export const netHST = (price: number) => {
  if (price > 484500) {
    return (price + 24000) / 1.13;
  } else if (price > 424850 && price < 484500) {
    return (price + 52350) / 1.193;
  } else if (price > 368200 && price < 424850) {
    return (price + 28350) / 1.133;
  } else return price / 1.052;
};

export const pageRotate = (pageRotation: any, x: any, y: any, scale: any, dimensions: any) => {
  let rotationRads = (pageRotation.angle * Math.PI) / 180;
  //These coords are now from bottom/left
  let coordsFromBottomLeft: any = {
    x: x / scale,
  };
  if (pageRotation.angle === 90 || pageRotation.angle === 270) {
    coordsFromBottomLeft.y = dimensions.width - y / scale;
  } else {
    coordsFromBottomLeft.y = dimensions.height - y / scale;
  }

  var drawX = null;
  var drawY = null;
  if (pageRotation.angle === 90) {
    drawX = coordsFromBottomLeft.x * Math.cos(rotationRads) - coordsFromBottomLeft.y * Math.sin(rotationRads) + dimensions.width;
    drawY = coordsFromBottomLeft.x * Math.sin(rotationRads) + coordsFromBottomLeft.y * Math.cos(rotationRads);
  } else if (pageRotation.angle === 180) {
    drawX = coordsFromBottomLeft.x * Math.cos(rotationRads) - coordsFromBottomLeft.y * Math.sin(rotationRads) + dimensions.width;
    drawY = coordsFromBottomLeft.x * Math.sin(rotationRads) + coordsFromBottomLeft.y * Math.cos(rotationRads) + dimensions.height;
  } else if (pageRotation.angle === 270) {
    drawX = coordsFromBottomLeft.x * Math.cos(rotationRads) - coordsFromBottomLeft.y * Math.sin(rotationRads);
    drawY = coordsFromBottomLeft.x * Math.sin(rotationRads) + coordsFromBottomLeft.y * Math.cos(rotationRads) + dimensions.height;
  } else {
    //no rotation
    drawX = coordsFromBottomLeft.x;
    drawY = coordsFromBottomLeft.y;
  }

  return { x: drawX, y: drawY };
};

export function typedArrayToBuffer(array: any): ArrayBuffer {
  return array.buffer.slice(array.byteOffset, array.byteLength + array.byteOffset);
}

export const downloadExcel = (
  data: any[],
  headers: any[],
  backgrounds: any[],
  columnWidths: any[],
  sheetName: string[],
  fileName: string
) => {
  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const workbook = new ExcelJS.Workbook(); // Create Workbook

  data.forEach((values, index) => {
    const worksheet = workbook.addWorksheet(sheetName[index]); // Create Worksheet

    let headerIds = headers[index].map((header: any) => header.id);
    let headerLabels = headers[index].map((header: any) => header.label);

    worksheet.columns = headerIds.map((header: any, index: number) => ({
      header: headerLabels[index],
      key: header,
      width: columnWidths[0][0][header],
    })); //
    worksheet.columns.forEach((column) => {
      column.border = {
        top: { style: 'thick' },
        left: { style: 'thick' },
        bottom: { style: 'thick' },
        right: { style: 'thick' },
      };
    });
    // Headers
    const headerRow = worksheet.getRow(1);
    headerIds.forEach((header: any) => {
      headerRow.getCell(header).fill = {
        // Add the background color if it is defined
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00142a' },
      };
      headerRow.getCell(header).font = {
        color: { argb: 'ffffff' },
      };
    });

    values.forEach((value: any, index: any) => {
      const row = worksheet.getRow(index + 2);
      row.values = value;
      headerIds.forEach((header: any) => {
        row.getCell(header).fill = {
          // Add the background color if it is defined
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'ffffff' },
        };
      });
      row.height = 20;
      row.commit();
    });
  });

  workbook.xlsx.writeBuffer().then((file: any) => {
    const blob = new Blob([file], { type: EXCEL_TYPE });
    saveAs(blob, fileName);
  });
};

export const downloadDecorExcel = (data: any[], headers: any[], fileName: string) => {
  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const workbook = new ExcelJS.Workbook(); // Create Workbook

  for (const [index, header] of headers.entries()) {
    if (header.length) {
      let upgradeType;
      if (index === 0) {
        upgradeType = 'Upgrade Set One';
      }
      if (index === 1) {
        upgradeType = 'Upgrade Set Two';
      }
      if (index === 2) {
        upgradeType = 'Upgrade Set Three';
      }
      const worksheet: any = workbook.addWorksheet(upgradeType);
      worksheet.addRow(['', '', '', '', ...header.map((header: any) => header.mainCategory)]);
      let excelMainHeaderLetter = 5;
      let excelSubHeaderLetter = 5;

      let mainCategories: any = Object.entries(
        header.reduce((acc: any, o: any) => ((acc[o.mainCategory] = (acc[o.mainCategory] || 0) + 1), acc), {})
      );
      for (const [index, data] of mainCategories.entries()) {
        worksheet.mergeCells(`${columnToLetter(excelMainHeaderLetter)}1:${columnToLetter(excelMainHeaderLetter + data[1] - 1)}1`);
        let cell = worksheet.getCell(`${columnToLetter(excelMainHeaderLetter)}1`);
        cell.value = {
          richText: [{ font: { bold: true }, text: `${data[0]}` }],
        };
        cell.alignment = {
          horizontal: 'center',
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: randomColors[index].substring(1),
          },
        };
        excelMainHeaderLetter += data[1];
      }
      let subCategoryHeaders = [...new Set(data.map((item) => item.group))];
      worksheet.getRow(2).values = ['', '', '', ...subCategoryHeaders];
      let subCategories: any = {};
      for (let { mainCategory, subCategory } of header)
        subCategories[`${mainCategory}${subCategory}`] = {
          mainCategory,
          subCategory,
          count: subCategories[`${mainCategory}${subCategory}`] ? subCategories[`${mainCategory}${subCategory}`].count + 1 : 1,
        };

      let result: any = Object.values(subCategories);
      for (const data of result) {
        worksheet.mergeCells(`${columnToLetter(excelSubHeaderLetter)}2:${columnToLetter(excelSubHeaderLetter + data.count - 1)}2`);
        let cell = worksheet.getCell(`${columnToLetter(excelSubHeaderLetter)}2`);
        cell.value = {
          richText: [{ font: { bold: true }, text: `${data.subCategory}` }],
        };
        cell.alignment = {
          horizontal: 'center',
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'dfdfdf',
          },
        };
        cell.border = {
          left: { style: 'thin' },
          right: { style: 'thin' },
          bottom: { style: 'thin' },
          top: { style: 'thin' },
        };
        excelSubHeaderLetter += data.count;
      }
      let upgradeRow = worksheet.getRow(3);
      let values = ['Suite', 'Date', 'Sales Rep', 'Total', ...header.map((header: any) => header.name)];
      upgradeRow.values = values;
      values.forEach((header: any, index) => {
        upgradeRow.getCell(index + 1).alignment = {
          horizontal: 'center',
        };
        upgradeRow.getCell(index + 1).fill = {
          // Add the background color if it is defined
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '000000' },
        };
        upgradeRow.getCell(index + 1).font = {
          color: { argb: 'ffffff' },
        };
      });
      if (index === 0) {
        data.forEach((value: any, index: any) => {
          const row = worksheet.getRow(index + 4);
          row.getCell(1).value = value.suite;
          row.getCell(2).value = value.upgradeSetOne.upgrades.length ? convertAllDates(new Date(value.upgradeSetOne.date), 'PPpp') : '-';
          row.getCell(3).value = value.upgradeSetOne.upgrades.length ? value.upgradeSetOne.salesRep : '-';
          row.getCell(4).value = value.upgradeSetOne.upgrades.length ? numToCurrency.format(value.upgradeSetOne.totalPrice) : '-';
          header.forEach((header: any, numIndex: number) => {
            let mainCategory: any = worksheet.getCell(`${columnToLetter(numIndex + 5)}1`).value.richText[0].text;
            let subCategory: any = worksheet.getCell(`${columnToLetter(numIndex + 5)}2`).value.richText[0].text;
            let name: any = worksheet.getCell(`${columnToLetter(numIndex + 5)}3`).value;
            let upgrade = value.upgradeSetOne.upgrades.find(
              (upgrade: IUpgrade) => upgrade.mainCategory === mainCategory && upgrade.subCategory === subCategory && upgrade.name === name
            );
            if (upgrade) {
              row.getCell(numIndex + 5).value = `${numToCurrency.format(upgrade.price)} (${upgrade.quantity})`;
            } else {
              row.getCell(numIndex + 5).value = '-';
            }
          });
          row.height = 20;
          row.commit();
        });
        worksheet.getCell(`D1`).value = numToCurrency.format(
          data.filter((data: any) => data.upgradeSetOne.totalPrice !== '-').reduce((a: any, b: any) => a + b.upgradeSetOne.totalPrice, 0)
        );
      }
      if (index === 1) {
        data.forEach((value: any, index: any) => {
          const row = worksheet.getRow(index + 4);
          row.getCell(1).value = value.suite;
          row.getCell(2).value = value.upgradeSetTwo.upgrades.length ? convertAllDates(new Date(value.upgradeSetTwo.date), 'PPpp') : '-';
          row.getCell(3).value = value.upgradeSetTwo.upgrades.length ? value.upgradeSetTwo.salesRep : '-';
          row.getCell(4).value = value.upgradeSetTwo.upgrades.length ? numToCurrency.format(value.upgradeSetTwo.totalPrice) : '-';
          header.forEach((header: any, numIndex: number) => {
            let mainCategory: any = worksheet.getCell(`${columnToLetter(numIndex + 5)}1`).value.richText[0].text;
            let subCategory: any = worksheet.getCell(`${columnToLetter(numIndex + 5)}2`).value.richText[0].text;
            let name: any = worksheet.getCell(`${columnToLetter(numIndex + 5)}3`).value;
            let upgrade = value.upgradeSetTwo.upgrades.find(
              (upgrade: IUpgrade) => upgrade.mainCategory === mainCategory && upgrade.subCategory === subCategory && upgrade.name === name
            );
            if (upgrade) {
              row.getCell(numIndex + 5).value = `${numToCurrency.format(upgrade.price)} (${upgrade.quantity})`;
            } else {
              row.getCell(numIndex + 5).value = '-';
            }
          });
          row.height = 20;
          row.commit();
        });
        worksheet.getCell(`D1`).value = numToCurrency.format(
          data.filter((data: any) => data.upgradeSetTwo.totalPrice !== '-').reduce((a: any, b: any) => a + b.upgradeSetTwo.totalPrice, 0)
        );
      }
      if (index === 2) {
        data.forEach((value: any, index: any) => {
          const row = worksheet.getRow(index + 4);
          row.getCell(1).value = value.suite;
          row.getCell(2).value = value.upgradeSetThree.upgrades.length
            ? convertAllDates(new Date(value.upgradeSetThree.date), 'PPpp')
            : '-';
          row.getCell(3).value = value.upgradeSetThree.upgrades.length ? value.upgradeSetThree.salesRep : '-';
          row.getCell(4).value = value.upgradeSetThree.upgrades.length ? numToCurrency.format(value.upgradeSetThree.totalPrice) : '-';
          header.forEach((header: any, numIndex: number) => {
            let mainCategory: any = worksheet.getCell(`${columnToLetter(numIndex + 5)}1`).value.richText[0].text;
            let subCategory: any = worksheet.getCell(`${columnToLetter(numIndex + 5)}2`).value.richText[0].text;
            let name: any = worksheet.getCell(`${columnToLetter(numIndex + 5)}3`).value;
            let upgrade = value.upgradeSetThree.upgrades.find(
              (upgrade: IUpgrade) => upgrade.mainCategory === mainCategory && upgrade.subCategory === subCategory && upgrade.name === name
            );
            if (upgrade) {
              row.getCell(numIndex + 5).value = `${numToCurrency.format(upgrade.price)} (${upgrade.quantity})`;
            } else {
              row.getCell(numIndex + 5).value = '-';
            }
          });
          row.height = 20;
          row.commit();
        });
        worksheet.getCell(`D1`).value = numToCurrency.format(
          data
            .filter((data: any) => data.upgradeSetThree.totalPrice !== '-')
            .reduce((a: any, b: any) => a + b.upgradeSetThree.totalPrice, 0)
        );
      }

      excelAutoWidth(worksheet);
    }
  }

  workbook.xlsx.writeBuffer().then((file: any) => {
    const blob = new Blob([file], { type: EXCEL_TYPE });
    saveAs(blob, fileName);
  });
};

const hexToPdfRgb = (hex: string | undefined) => {
  if (!hex || hex.length !== 7) return rgb(1, 1, 1);
  return rgb(parseInt(hex.substring(1, 3), 16) / 255, parseInt(hex.substring(3, 5), 16) / 255, parseInt(hex.substring(5, 7), 16) / 255);
};

/**
 * Downloads an excel spreadsheet from data
 * @param data: an array of objects
 * @param headers: an array of the keys of the object
 * @param formattedHeaders: an array of the formatted keys of the object
 * @param backgrounds: color codes for the backgrounds of each cell
 * @param columnWidths: widths of each column
 * @param fileName: name of the downloaded file
 *
 */
export const downloadPDF = async (
  data: any[],
  headers: string[],
  formattedHeaders: string[],
  backgrounds: any[],
  columnWidths: any,
  fileName: string,
  hotlistInfo: any = null
) => {
  if (!data.length) return;
  // Constants
  const padding = 20;
  const fontSize = 12;
  const realtorFontSize = 100;
  const textOffset = 5;
  const cellPadding = 5;
  const rowHeight = cellPadding * 6 + fontSize * 3 + textOffset;
  const headerHeight = cellPadding * 2 + fontSize + textOffset;
  const maxRowPerPage = 15;
  const logoLength = 50;
  const today = new Date();
  const time = today.toLocaleTimeString('en-us');
  let hotlistMessage =
    'All Prices, Incentives and Specifications are subject to change without notice. *Please speak with an RDS Sales Representative for details.\nE. & O.E. ';
  hotlistMessage += today.toLocaleDateString('en-us', { month: 'long', day: 'numeric', year: 'numeric' });
  const registeredText = 'Residential Development Services ® ' + today.getFullYear();

  // Get text width
  const context = document.createElement('canvas').getContext('2d');
  context && (context.font = fontSize + 'px sans-serif');
  const timeWidth = context?.measureText(time).width ?? 0;
  const registeredWidth = context?.measureText(registeredText).width ?? 0;
  const realtorContext = document.createElement('canvas').getContext('2d');
  realtorContext && (realtorContext.font = realtorFontSize + 'px sans-serif');
  const realtorWidth = realtorContext?.measureText(hotlistInfo?.realtor ?? '').width ?? 0;

  // Get Page Dimensions

  const tableWidth = headers.reduce((prevValue, key) => prevValue + columnWidths[key] + cellPadding * 2, 0);
  const width = padding * 2 + tableWidth;
  const height = padding * 2 + rowHeight * maxRowPerPage + headerHeight + (hotlistInfo ? fontSize * 3 + logoLength : 0);

  const pdf = await PDFDocument.create();
  const numPages = Math.ceil(data.length / maxRowPerPage);

  // Embed images
  let projectLogoImage;
  if (hotlistInfo?.projectLogo) {
    const projectLogoBytes = await fetch(hotlistInfo.projectLogo).then((res) => res.arrayBuffer());
    projectLogoImage = await pdf.embedJpg(projectLogoBytes);
  }

  //const logoImage = await pdf.embedPng(logoBytes);

  for (let i = 0; i < numPages; ++i) {
    let page = pdf.addPage([width, height]); // Get page
    page.moveTo(padding, height - padding); // Add padding

    if (hotlistInfo) {
      page.moveDown(logoLength); // Make space for logos
      //page.drawImage(logoImage, { width: logoLength, height: logoLength });
      //page.moveRight(logoLength);
      projectLogoImage && page.drawImage(projectLogoImage, { height: logoLength, width: width * (logoLength / projectLogoImage.height) });
      //page.moveLeft(logoLength);
    }

    // Add headers
    page.moveDown(headerHeight);
    headers.forEach((header, index) => {
      page.drawRectangle({ height: headerHeight, width: columnWidths[header] + cellPadding * 2, color: rgb(1, 1, 1) });
      page.drawText(formattedHeaders[index], {
        x: page.getX() + cellPadding,
        y: page.getY() + headerHeight - fontSize - cellPadding,
        size: fontSize,
        lineHeight: fontSize / 2,
      });
      page.moveRight(columnWidths[header] + cellPadding * 2);
    });
    page.moveLeft(tableWidth);

    // Add rows
    for (let j = i * maxRowPerPage; j < data.length && j < i * maxRowPerPage + maxRowPerPage; ++j) {
      page.moveDown(rowHeight);
      headers.forEach((header) => {
        page.drawRectangle({
          height: rowHeight,
          width: columnWidths[header] + cellPadding * 2,
          color: backgrounds[j] ? hexToPdfRgb(backgrounds[j][`${header}Background`]) : rgb(1, 1, 1),
        });
        page.drawText(data[j][header] ? data[j][header].toString() : 'N/A', {
          x: page.getX() + cellPadding,
          y: page.getY() + rowHeight - fontSize - cellPadding,
          size: fontSize,
          lineHeight: fontSize / 2,
        });
        page.moveRight(columnWidths[header] + cellPadding * 2);
      });
      page.moveLeft(tableWidth);
    }

    if (hotlistInfo) {
      page.drawText(time, { x: width - padding - timeWidth, y: height - padding - fontSize, size: fontSize });
      page.drawText(hotlistMessage, { x: padding, y: padding + fontSize * 2, size: fontSize, lineHeight: fontSize });
      page.drawText(registeredText, { x: (width - registeredWidth) / 2, y: padding, size: fontSize });
      if (hotlistInfo.realtor) {
        const long = Math.sqrt(Math.pow(realtorWidth, 2) / 2);
        const short = Math.sqrt(Math.pow(realtorFontSize, 2) / 2);
        const realtorSideLength = long + short;
        page.drawText(hotlistInfo.realtor, {
          x: (width - realtorSideLength) / 2,
          y: height - (height - realtorSideLength) / 2 - short,
          size: realtorFontSize,
          color: rgb(0.5, 0.5, 0.5),
          rotate: degrees(-45),
          opacity: 0.3,
        });
      }
    }
  }

  const pdfFile = await pdf.save();
  download(pdfFile, fileName, 'application/pdf');
};

export const downloadPdf = async (
  data: any[],
  headers: any[],
  backgrounds: any[],
  columnWidths: any,
  pdfTitles: string[],
  fileName: string,
  projectLogo: any = null
) => {
  if (!data.length) return;
  // Constants
  const padding = 20;
  const fontSize = 12;
  const realtorFontSize = 100;
  const textOffset = 5;
  const cellPadding = 5;
  const rowHeight = cellPadding * 2 + fontSize * 3 + textOffset;
  const headerHeight = cellPadding * 2 + fontSize + textOffset;

  // Get text width
  const context = document.createElement('canvas').getContext('2d');
  context && (context.font = fontSize + 'px sans-serif');
  const realtorContext = document.createElement('canvas').getContext('2d');
  realtorContext && (realtorContext.font = realtorFontSize + 'px sans-serif');

  // Get Page Dimensions

  const pdf = await PDFDocument.create();

  for (const [index, values] of data.entries()) {
    let headerIds = headers[index].map((header: any) => header.id);
    let headerLabels = headers[index].map((header: any) => header.label);

    const tableWidth = headerIds.reduce((prevValue: any, key: number) => prevValue + columnWidths[index][key] + cellPadding * 2, 0);
    const width = padding * 2 + tableWidth;
    const height = padding * 2 + rowHeight * values.length + headerHeight * 3;

    let page = pdf.addPage([width, height]); // Get page
    page.moveTo(padding, height - padding); // Add padding
    page.moveDown(headerHeight);

    let projectLogoImage;
    if (projectLogo) {
      const projectLogoBytes = await fetch(projectLogo).then((res) => res.arrayBuffer());
      projectLogoImage = await pdf.embedJpg(projectLogoBytes);
      page.drawImage(projectLogoImage, {
        x: page.getX() + cellPadding,
        y: page.getY() + headerHeight - fontSize - cellPadding,
        height: 25,
        width: 40,
      });
    }

    let rdsLogoImage;
    // const rdsLogoBytes = await fetch("https://rdsre.s3.ca-central-1.amazonaws.com/assets/logo.jpg").then((res) => res.arrayBuffer());
    // rdsLogoImage = await pdf.embedJpg(rdsLogoBytes);

    if (rdsLogoImage) {
      page.drawImage(rdsLogoImage, {
        x: page.getWidth() - page.getX() - 25,
        y: page.getY() + headerHeight - fontSize - cellPadding,
        height: 25,
        width: 25,
      });
      page.moveDown(headerHeight);
    }

    page.drawText(pdfTitles[index], {
      x: page.getX() + cellPadding + 50,
      y: page.getY() + headerHeight - fontSize - cellPadding,
      size: fontSize + 4,
      lineHeight: fontSize / 2,
    });

    page.drawText(convertAllDates(new Date(), 'PPpp'), {
      x: page.getWidth() - page.getX() - 200,
      y: page.getY() + headerHeight - fontSize - cellPadding,
      size: fontSize + 4,
      lineHeight: fontSize / 2,
    });

    page.moveDown(headerHeight);

    for (const [i, header] of headerIds.entries()) {
      page.drawRectangle({ height: headerHeight, width: columnWidths[index][header] + cellPadding * 2, color: hexToPdfRgb('#00142a') });
      page.drawText(headerLabels[i], {
        x: page.getX() + cellPadding,
        y: page.getY() + headerHeight - fontSize - cellPadding,
        size: fontSize,
        color: rgb(1, 1, 1),
        lineHeight: fontSize / 2,
      });
      page.moveRight(columnWidths[index][header] + cellPadding * 2);
    }
    page.moveLeft(tableWidth);

    // eslint-disable-next-line
    for (const [i, value] of values.entries()) {
      // Add rows
      page.moveDown(rowHeight);
      headerIds.forEach((header: any) => {
        page.drawRectangle({
          height: rowHeight,
          width: columnWidths[index][header] + cellPadding * 2,
          borderWidth: 1,
          borderColor: grayscale(0.5),
        });
        page.drawText(value[header] ? value[header].toString() : '0', {
          x: page.getX() + cellPadding,
          y: page.getY() + rowHeight - fontSize - cellPadding,
          size: fontSize,
          lineHeight: fontSize / 2,
        });
        page.moveRight(columnWidths[index][header] + cellPadding * 2);
      });
      page.moveLeft(tableWidth);
    }
    page.moveDown(headerHeight);
  }

  const pdfFile = await pdf.save();
  download(pdfFile, fileName, 'application/pdf');
};

export const downloadMultiplePdf = async (
  data: any,
  headers: any,
  backgrounds: any,
  columnWidths: any,
  pdfTitles: string[],
  fileName: string,
  projectLogo: any = null
) => {
  if (!data.length) return;
  // Constants
  const padding = 20;
  const fontSize = 12;
  const realtorFontSize = 100;
  const textOffset = 5;
  const cellPadding = 5;
  const rowHeight = cellPadding * 2 + fontSize * 3 + textOffset;
  const headerHeight = cellPadding * 2 + fontSize + textOffset;
  const logoLength = 50;

  // Get text width
  const context = document.createElement('canvas').getContext('2d');
  context && (context.font = fontSize + 'px sans-serif');
  const realtorContext = document.createElement('canvas').getContext('2d');
  realtorContext && (realtorContext.font = realtorFontSize + 'px sans-serif');

  // Get Page Dimensions

  const pdf = await PDFDocument.create();

  for (const [index, values] of data.entries()) {
    let headerIds = headers[index].map((header: any) => header.id);
    let headerLabels = headers[index].map((header: any) => header.label);

    const tableWidth = headerIds.reduce((prevValue: any, key: number) => prevValue + columnWidths[index][key] + cellPadding * 2, 0);
    const width = padding * 2 + tableWidth;
    const height = padding * 2 + rowHeight * values.length + headerHeight * 3;

    let page = pdf.addPage([width, height]); // Get page
    page.moveTo(padding, height - padding); // Add padding
    page.moveDown(headerHeight);

    let rdsLogoImage;
    // const rdsLogoBytes = await fetch("https://rdsre.s3.ca-central-1.amazonaws.com/assets/logo.jpg").then((res) => res.arrayBuffer());
    // rdsLogoImage = await pdf.embedJpg(rdsLogoBytes);

    let projectLogoImage;
    if (projectLogo) {
      const projectLogoBytes = await fetch(projectLogo).then((res) => res.arrayBuffer());
      projectLogoImage = await pdf.embedJpg(projectLogoBytes);
      page.drawImage(projectLogoImage, {
        x: page.getX() + cellPadding + 50,
        y: page.getY() + headerHeight - fontSize - cellPadding,
        height: 25,
        width: 40,
      });
    }

    if (rdsLogoImage) {
      page.drawImage(rdsLogoImage, {
        x: page.getWidth() - page.getX() - 25,
        y: page.getY() + headerHeight - fontSize - cellPadding,
        height: 25,
        width: 25,
      });
      page.moveDown(headerHeight);
    }

    page.drawText(pdfTitles[index], {
      x: page.getX() + cellPadding,
      y: page.getY() + headerHeight - fontSize - cellPadding,
      size: fontSize + 4,
      lineHeight: fontSize / 2,
    });

    page.drawText(convertAllDates(new Date(), 'PPpp'), {
      x: page.getWidth() - page.getX() - 200,
      y: page.getY() + headerHeight - fontSize - cellPadding,
      size: fontSize + 4,
      lineHeight: fontSize / 2,
    });

    page.moveDown(headerHeight);

    for (const [i, header] of headerIds.entries()) {
      page.drawRectangle({ height: headerHeight, width: columnWidths[index][header] + cellPadding * 2, color: hexToPdfRgb('#00142a') });
      page.drawText(headerLabels[i], {
        x: page.getX() + cellPadding,
        y: page.getY() + headerHeight - fontSize - cellPadding,
        size: fontSize,
        color: rgb(1, 1, 1),
        lineHeight: fontSize / 2,
      });
      page.moveRight(columnWidths[index][header] + cellPadding * 2);
    }
    page.moveLeft(tableWidth);

    for (const [i, value] of values.entries()) {
      // Add rows
      page.moveDown(rowHeight);
      headerIds.forEach((header: any) => {
        page.drawRectangle({
          height: rowHeight,
          width: columnWidths[index][header] + cellPadding * 2,
          borderWidth: 1,
          borderColor: grayscale(0.5),
        });
        page.drawText(value[header] ? value[header].toString() : '0', {
          x: page.getX() + cellPadding,
          y: page.getY() + rowHeight - fontSize - cellPadding,
          size: fontSize,
          lineHeight: fontSize / 2,
        });
        page.moveRight(columnWidths[index][header] + cellPadding * 2);
      });
      page.moveLeft(tableWidth);
    }
    page.moveDown(headerHeight);
  }

  const pdfFile = await pdf.save();
  download(pdfFile, fileName, 'application/pdf');
};

/**
 * Validates an array
 * @param arr
 * @param validator
 * @param defaultValue
 * @returns
 */
export function validateArray<T>(arr: T[], validator: (entry: T) => boolean, defaultValue: boolean) {
  return arr.length ? arr.every(validator) : defaultValue;
}

export const getDoubleDigitNumber = (num: number | string) => {
  let numeral = typeof num === 'string' ? parseInt(num) : num;
  return numeral < 10 ? '0' + numeral : numeral + '';
};

export const getInnerText: (elem: React.ReactElement | string) => string = (elem: React.ReactElement | string) => {
  if (!elem) return '';
  if (typeof elem === 'string') return elem;
  const children = elem.props.children;
  if (children instanceof Array) return children.map(getInnerText).join('');
  return getInnerText(children);
};

export const sortSuites = (data: any, type: string) => {
  data.sort((a: any, b: any) => {
    //a and b are IUnits
    let keyA = a[type];
    let keyB = b[type];

    if (keyA === null && keyB === null) return 0;
    else if (keyA === null) return 1;
    else if (keyB === null) return -1;

    if (typeof keyA === 'number' && typeof keyB === 'number') return keyA - keyB;

    typeof keyA === 'string' ? keyA.toLowerCase() : (keyA = React.isValidElement(keyA) ? getInnerText(keyA) : keyA.toString());
    typeof keyB === 'string' ? keyB.toLowerCase() : (keyB = React.isValidElement(keyB) ? getInnerText(keyB) : keyB.toString());

    return keyA.localeCompare(keyB, 'en', { numeric: true });
  });

  return data;
};

export const sortNestedSuites = (data: any, type: string, type2: string) => {
  data.sort((a: any, b: any) => {
    //a and b are IUnits
    let keyA = a[type][type2];
    let keyB = b[type][type2];

    if (keyA === null && keyB === null) return 0;
    else if (keyA === null) return 1;
    else if (keyB === null) return -1;

    if (typeof keyA === 'number' && typeof keyB === 'number') return keyA - keyB;

    typeof keyA === 'string' ? keyA.toLowerCase() : (keyA = React.isValidElement(keyA) ? getInnerText(keyA) : keyA.toString());
    typeof keyB === 'string' ? keyB.toLowerCase() : (keyB = React.isValidElement(keyB) ? getInnerText(keyB) : keyB.toString());

    return keyA.localeCompare(keyB, 'en', { numeric: true });
  });

  return data;
};

export const getInitials = (string: string) => {
  var names = string.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const blobToBase64 = async (blobs: Blob[]) => {
  let base64 = await Promise.all(
    blobs.map(async (blob: Blob) => {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    })
  );
  return base64;
};

export const downloadImages = async (files: any[], fileName: string, projectId: string, projectEmail: string, projectName: string) => {
  interface IMarketingBlob {
    modelType: string;
    suite: string;
    basePrice: string;
    rental: number;
    blob: any;
    size: number;
  }

  let marketingFloorPlansBlobs: IMarketingBlob[] = [];

  await Promise.all(
    files.map(async (unit: any, index: number, self: any[]) => {
      let response = await fetch(unit.pdfFloorPlan ? unit.pdfFloorPlan : unit.marketingFloorPlan!, { cache: 'no-cache' });
      let blob = await response.blob();
      marketingFloorPlansBlobs.push({
        rental: unit.rental ? unit.rental : 0,
        suite: unit.suite,
        basePrice: unit.basePrice,
        modelType: unit.modelType,
        blob: blob,
        size: unit.size,
      });
    })
  );

  let allBlobs = marketingFloorPlansBlobs
    .filter((blob: IMarketingBlob) => blob.blob)
    .sort((a, b) => a.size - b.size)
    .map((blobs: IMarketingBlob) => blobs.blob);

  let renderingsBase64 = await blobToBase64(allBlobs);

  marketingFloorPlansBlobs = marketingFloorPlansBlobs
    .filter((blob: IMarketingBlob) => blob.blob)
    .sort((a, b) => a.size - b.size)
    .map((marketing: IMarketingBlob, index: number) => {
      return {
        ...marketing,
        blob: renderingsBase64[index],
      };
    });

  const mergedPdf = await PDFDocument.create();
  if (files.length > 0) {
    await Promise.all(
      marketingFloorPlansBlobs.map(async (file: any) => {
        let floorPlanDoc;
        let page: any;
        let fontSize = 14;
        let y = 80;
        let yIncrease = 10;
        if (!file.blob.includes('application/pdf')) {
          floorPlanDoc = await PDFDocument.create();
          const response = await fetch(file.blob!, { cache: 'no-cache' }).then((response) => response.arrayBuffer());
          const jpgImage = await mergedPdf.embedJpg(response);
          page = mergedPdf.addPage();

          if (jpgImage.width > jpgImage.height) {
            fontSize = 40;
            yIncrease = 20;
            page.setSize(jpgImage.width, jpgImage.height + 300);
            jpgImage.scale(0.9);
            page.drawImage(jpgImage, {
              y: 140,
              width: jpgImage.width,
              height: jpgImage.height,
            });
          } else {
            page.drawImage(jpgImage, {
              y: 140,
              width: page.getWidth(),
              height: page.getHeight(),
            });
          }

          if (jpgImage.height > jpgImage.width) {
            page.setSize(612.0, 1008.0);
          }

          if (file.suite) {
            page.drawText(`Suite: ${file.suite}`, {
              x: 35,
              y: y + yIncrease * 2,
              size: fontSize,
              color: rgb(0, 0, 0),
            });
          }
          if (file.basePrice) {
            page.drawText(`Price: ${numToCurrency.format(file.basePrice)}`, {
              x: 35,
              y: y + yIncrease / 4,
              size: fontSize,
              color: rgb(0, 0, 0),
            });
          }
          if (file.rental) {
            page.drawText(`RENTAL GUARANTEE AMOUNT: ${numToCurrency.format(file.rental)}`, {
              x: 35,
              y: y / 2,
              size: fontSize,
              color: rgb(1, 0, 0),
            });
          }
          page.drawText(`Please consult a RDS Representative for details. (e) ${projectEmail}; E&OE. ${new Date().toLocaleDateString()}`, {
            x: 35,
            y: 20,
            size: 9,
            color: rgb(0, 0, 0),
          });
          // Draw Suite and BasePrice

          let copiedPages = await mergedPdf.copyPages(floorPlanDoc, floorPlanDoc.getPageIndices());
          await copiedPages.forEach((page: any) => mergedPdf.addPage(page));
        } else {
          floorPlanDoc = await PDFDocument.load(file.blob);
          page = floorPlanDoc.getPage(0);
          page.setHeight(page.getHeight() + 100);
          await page.translateContent(0, 100);

          if (file.suite) {
            page.drawText(`Suite: ${file.suite}`, {
              x: 35,
              y: -20,
              size: fontSize,
              color: rgb(0, 0, 0),
            });
          }
          if (file.basePrice) {
            page.drawText(`Price: ${numToCurrency.format(file.basePrice)}`, {
              x: 35,
              y: -40,
              size: fontSize,
              color: rgb(0, 0, 0),
            });
          }
          if (file.rental) {
            page.drawText(`RENTAL GUARANTEE AMOUNT: ${numToCurrency.format(file.rental)}`, {
              x: 35,
              y: -60,
              size: fontSize,
              color: rgb(1, 0, 0),
            });
          }
          page.drawText(`Please consult a RDS Representative for details. (e) ${projectEmail}; E&OE. ${new Date().toLocaleDateString()}`, {
            x: 35,
            y: -90,
            size: 9,
            color: rgb(0, 0, 0),
          });

          let copiedPages = await mergedPdf.copyPages(floorPlanDoc, floorPlanDoc.getPageIndices());
          await copiedPages.forEach((page: any) => mergedPdf.addPage(page));
        }
      })
    );
    const mergedPdfFile = await mergedPdf.save();

    const pdf = await new File([mergedPdfFile], `${projectName} Floorplans.pdf`, { type: 'application/pdf' });

    const element = document.createElement('button');
    element.addEventListener('click', async () => {
      let navigator: any = window.navigator;
      try {
        const userAgent = navigator.userAgent;
        if (/iPad|iPhone|Safari|Mac|iPod/i.test(userAgent)) {
          await navigator.share({ files: [pdf] });
        } else {
          await navigator.share({ files: [pdf], title: `${projectName} Floorplans`, text: `${projectName} Floorplans` });
        }
      } catch (err) {
        console.log(err, 'er');
      }
    });
    element.click();
  }
};

export const reverseCamelCase = (string: string) => {
  if (!string) return string;
  let newString = string.charAt(0).toUpperCase();
  for (let i = 1; i < string.length; ++i) {
    if ('A'.charCodeAt(0) <= string.charCodeAt(i) && string.charCodeAt(i) <= 'Z'.charCodeAt(0)) newString += ' ';
    newString += string.charAt(i);
  }
  return newString;
};

export const checkRealtorPublic = (tags: string[]) => {
  if (tags.includes('Friends and Family')) {
    return 'F&F';
  } else if (tags.includes('Public')) {
    return 'Public';
  } else return 'N/A';
};

// DASHBOARD EXCEL TABLES

export const unitCells = [
  { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
  { id: 'suites', numeric: true, disablePadding: false, label: 'Number of Suites' },
  { id: 'size', numeric: true, disablePadding: false, label: 'Size (sq/ft)' },
  { id: 'soldPrice', numeric: true, disablePadding: false, label: 'Revenue' },
  { id: 'PPSF', numeric: true, disablePadding: false, label: 'PPSF' },
  { id: 'soldPriceNet', numeric: true, disablePadding: false, label: 'Revenue Net' },
  { id: 'PPSFNet', numeric: true, disablePadding: false, label: 'PPSF Net' },
];

export const columnWidths = {
  status: 15,
  suites: 15,
  size: 15,
  soldPrice: 30,
  PPSF: 15,
  soldPriceNet: 30,
  PPSFNet: 15,
};

export const pdfColumnWidths = {
  status: 110,
  suites: 100,
  suitesPercentage: 50,
  size: 100,
  sizePercentage: 50,
  soldPrice: 100,
  soldPricePercentage: 100,
  PPSF: 100,
  soldPriceNet: 100,
  PPSFNet: 100,
};

export const unitRows = (rows: any, totalRow: any) => {
  let excel = rows.map((row: any) => {
    return {
      ...row,
      PPSF: numToCurrency.format(row.PPSF),
      PPSFNet: numToCurrency.format(row.PPSFNet),
      sizePercentage: `${row.suitesPercentage}%`,
      soldPrice: numToCurrency.format(row.soldPrice),
      soldPriceNet: numToCurrency.format(row.soldPriceNet),
      suitesPercentage: `${row.suitesPercentage}%`,
    };
  });
  let totalExcelRows = {
    ...totalRow,
    PPSF: totalRow.soldPrice ? numToCurrency.format(totalRow.soldPrice / totalRow.size) : '0(0%)',
    PPSFNet: totalRow.soldPrice ? numToCurrency.format(totalRow.soldPriceNet / totalRow.size) : '0(0%)',
    sizePercentage: `${totalRow.sizePercentage}%}`,
    soldPrice: numToCurrency.format(totalRow.soldPrice),
    soldPriceNet: numToCurrency.format(totalRow.soldPriceNet),
    suitesPercentage: `${totalRow.suitesPercentage}%}`,
  };

  return [...excel, totalExcelRows];
};

export const optionCells = [
  { id: 'optionName', numeric: false, disablePadding: true, label: 'Option' },
  { id: 'totalAvailableOptions', numeric: true, disablePadding: false, label: 'Total Options' },
  { id: 'available', numeric: true, disablePadding: false, label: 'Available' },
  { id: 'availableRevenue', numeric: true, disablePadding: false, label: 'Available Revenue' },
  { id: 'availableRevenueNetHST', numeric: true, disablePadding: false, label: 'Available Revenue Net HST' },
  { id: 'pendingOptions', numeric: true, disablePadding: false, label: 'Pending Options (O, S)' },
  { id: 'pendingRevenue', numeric: true, disablePadding: false, label: 'Pending Revenue (O, S)' },
  { id: 'soldOptions', numeric: true, disablePadding: false, label: 'Sold Options (C, F)' },
  { id: 'soldRevenue', numeric: true, disablePadding: false, label: 'Sold Revenue (C, F)' },
  { id: 'totalOptions', numeric: true, disablePadding: false, label: 'Combined Options (Sold + Pending)' },
  { id: 'combinedRevenue', numeric: true, disablePadding: false, label: 'Combined Revenue (Sold + Pending)' },
  { id: 'totalPPSF', numeric: true, disablePadding: false, label: 'Total PPSF' },
  { id: 'totalRevenue', numeric: true, disablePadding: false, label: 'Total Revenue' },
  { id: 'totalRevenueNetHST', numeric: true, disablePadding: false, label: 'Total Revenue Net HST' },
];

export const pdfOptionWidths = {
  available: 50,
  availableRevenue: 150,
  availableRevenueNetHST: 200,
  combinedRevenue: 200,
  optionName: 150,
  optionPrice: 100,
  pendingOptions: 150,
  pendingOptionsPercentage: 100,
  pendingRevenue: 150,
  pendingRevenuePercentage: 100,
  soldOptions: 100,
  soldOptionsPercentage: 50,
  soldRevenue: 150,
  soldRevenuePercentage: 50,
  totalAvailableOptions: 100,
  totalOptions: 200,
  totalOptionsPercentage: 100,
  totalPPSF: 50,
  totalRevenue: 100,
  totalRevenueNetHST: 200,
};

export const optionWidths = {
  optionName: 15,
  totalAvailableOptions: 15,
  available: 15,
  availableRevenue: 15,
  availableRevenueNetHST: 15,
  pendingOptions: 15,
  pendingRevenue: 15,
  soldOptions: 15,
  soldRevenue: 15,
  totalOptions: 15,
  combinedRevenue: 15,
  totalPPSF: 15,
  totalRevenue: 15,
  totalRevenueNetHST: 15,
};

export const excelOptionRows = (rows: any, totalRow: any) => {
  let excel = rows.map((row: any) => {
    return {
      ...row,
      available: row.totalAvailableOptions - row.totalOptions,
      availableRevenue: numToCurrency.format((row.totalAvailableOptions - row.totalOptions) * row.optionPrice),
      availableRevenueNetHST: numToCurrency.format((row.totalAvailableOptions - row.totalOptions) * row.optionPrice * 0.93),
      pendingOptions: row.pendingOptions + ' (' + row.pendingOptionsPercentage + '%)',
      pendingRevenue:
        numToCurrency.format(row.pendingRevenue) +
        ' (' +
        ((row.pendingRevenue / (row.totalRevenue + (row.totalAvailableOptions - row.totalOptions) * row.optionPrice)) * 100).toFixed(2) +
        '%)',
      soldOptions: row.soldOptions + ' (' + row.soldOptionsPercentage + '%)',
      soldRevenue: numToCurrency.format(row.soldRevenue) + ' (' + row.soldRevenuePercentage + '%)',
      totalOptions: row.totalOptions + ' (' + row.totalOptionsPercentage + '%)',
      combinedRevenue: numToCurrency.format(row.combinedRevenue),
      totalPPSF: numToCurrency.format(row.totalPPSF),
      totalRevenue: numToCurrency.format(row.totalRevenue + (row.totalAvailableOptions - row.totalOptions) * row.optionPrice),
      totalRevenueNetHST: numToCurrency.format(
        (row.totalRevenue + (row.totalAvailableOptions - row.totalOptions) * row.optionPrice) * 0.93
      ),
    };
  });
  let totalExcelRows = {
    ...totalRow,
    available: totalRow.available,
    availableRevenue: numToCurrency.format(totalRow.availableRevenue * 0.93),
    availableRevenueNetHST: numToCurrency.format(totalRow.availableRevenue * 0.93),
    pendingOptions: totalRow.pendingOptions,
    pendingRevenue: numToCurrency.format(totalRow.pendingRevenue),
    soldOptions: totalRow.soldOptions,
    soldRevenue: numToCurrency.format(totalRow.soldRevenue),
    totalOptions: totalRow.totalOptions,
    combinedRevenue: numToCurrency.format(totalRow.combinedRevenue),
    totalPPSF: numToCurrency.format(totalRow.totalPPSF),
    totalRevenue: numToCurrency.format(totalRow.totalRevenue),
    totalRevenueNetHST: numToCurrency.format(totalRow.totalRevenue * 0.93),
  };

  return [...excel, totalExcelRows];
};

export const totalCells = [
  { id: 'optionName', numeric: false, disablePadding: true, label: '' },
  { id: 'soldUnitRevenue', numeric: true, disablePadding: false, label: 'Sold Unit Revenue' },
  { id: 'soldOptionsRevenue', numeric: true, disablePadding: false, label: 'Sold Options Revenue' },
  { id: 'overallSoldRevenue', numeric: true, disablePadding: false, label: 'Overall Sold Revenue' },
  { id: 'soldUnitRevenueHST', numeric: true, disablePadding: false, label: 'Sold Unit Revenue (Net HST)' },
  { id: 'overallSoldRevenueHST', numeric: true, disablePadding: false, label: 'Overall Sold Revenue (Net HST)' },
  { id: 'overallRevenue', numeric: true, disablePadding: false, label: 'Overall Total Revenue' },
  { id: 'overallRevenuePPSF', numeric: true, disablePadding: false, label: 'Overall Total Revenue PPSF' },
  { id: 'overallRevenueHST', numeric: true, disablePadding: false, label: 'Overall Total Revenue (Net of HST)' },
];

export const totalWidths = {
  soldUnitRevenue: 15,
  soldOptionsRevenue: 15,
  overallSoldRevenue: 15,
  soldUnitRevenueHST: 15,
  overallSoldRevenueHST: 15,
  overallRevenue: 15,
  overallRevenuePPSF: 15,
  overallRevenueHST: 15,
};

export const pdfTotalWidths = {
  optionName: 100,
  overallRevenue: 150,
  overallRevenueHST: 210,
  overallRevenuePPSF: 150,
  overallSoldRevenue: 150,
  overallSoldRevenueHST: 200,
  soldOptionsRevenue: 150,
  soldOptionsRevenueHST: 200,
  soldUnitRevenue: 150,
  soldUnitRevenueHST: 200,
};

export const excelTotalRows = (rows: any, totalRow: any) => {
  let excel = rows.map((row: any) => {
    return {
      ...row,
      optionName: capitalizeFirstLetter(row.optionName),
      soldUnitRevenue: numToCurrency.format(row.soldUnitRevenue),
      soldOptionsRevenue: numToCurrency.format(row.overallSoldRevenue),
      overallSoldRevenue: numToCurrency.format(row.soldUnitRevenueHST),
      soldUnitRevenueHST: numToCurrency.format(row.soldUnitRevenueHST),
      overallSoldRevenueHST: numToCurrency.format(row.overallSoldRevenueHST),
      overallRevenue: numToCurrency.format(row.overallRevenue),
      overallRevenuePPSF: numToCurrency.format(row.overallRevenuePPSF),
      overallRevenueHST: numToCurrency.format(row.overallRevenueHST),
    };
  });
  let totalExcelRows = {
    ...totalRow,
    soldUnitRevenue: numToCurrency.format(totalRow.soldUnitRevenue),
    soldOptionsRevenue: numToCurrency.format(totalRow.soldOptionsRevenue),
    overallSoldRevenue: numToCurrency.format(totalRow.overallSoldRevenue),
    soldUnitRevenueHST: numToCurrency.format(totalRow.soldUnitRevenueHST),
    overallSoldRevenueHST: numToCurrency.format(totalRow.overallSoldRevenueHST),
    overallRevenue: numToCurrency.format(totalRow.overallRevenue),
    overallRevenuePPSF: numToCurrency.format(totalRow.overallRevenuePPSF),
    overallRevenueHST: numToCurrency.format(totalRow.overallRevenueHST),
  };

  return [...excel, totalExcelRows];
};

export const customSort = (rows: any, columnToSort: any, subColumn: any, sortAsc: boolean) => {
  const sortedRows = rows?.slice();
  sortedRows.sort((a: any, b: any) => {
    //a and b are IUnits
    let keyA = a[columnToSort];
    let keyB = b[columnToSort];

    if (subColumn) {
      keyA = a[columnToSort][subColumn];
      keyB = b[columnToSort][subColumn];
    }

    if (columnToSort === 'realtor' || columnToSort === 'purchaser') {
      if (keyA === 'Public' || keyB === 'Public') {
        return -1;
      }
      if (keyA < keyB) {
        return -1;
      }
      if (keyA > keyB) {
        return 1;
      }
      return 0;
    }

    if (keyA === null && keyB === null) return 0;
    else if (keyA === null) return 1;
    else if (keyB === null) return -1;

    if (typeof keyA === 'number' && typeof keyB === 'number') return sortAsc ? keyA - keyB : keyB - keyA;

    typeof keyA === 'string' ? keyA.toLowerCase() : (keyA = keyA.toString());
    typeof keyB === 'string' ? keyB.toLowerCase() : (keyB = keyB.toString());

    return sortAsc ? keyA.localeCompare(keyB, 'en', { numeric: true }) : -keyA.localeCompare(keyB, 'en', { numeric: true });
  });
  return sortedRows;
};

export const downloadAllFloorplans = async (files: any[], fileName: string, setLoading: any = null) => {
  const zip = new JSZip();

  //fetches the files and saves the files as blobs in an array

  const addFilesToZip: any = async (fileBlobs: any) => {
    if (fileBlobs) {
      if (fileBlobs.pdf.type === 'image/jpeg' || fileBlobs.file.includes('jpg') || fileBlobs.file.includes('jpeg')) {
        zip?.file(`${fileBlobs.name}.jpeg`, fileBlobs.pdf);
      } else if (fileBlobs.pdf.type === 'application/pdf' || fileBlobs.pdf.type === 'application/octet-stream') {
        zip?.file(`${fileBlobs.name}.pdf`, fileBlobs.pdf);
      } else if (fileBlobs.pdf.type === 'image/png') {
        zip?.file(`${fileBlobs.name}.png`, fileBlobs.pdf);
      }
    }
  };

  //downloads the zip
  const downloadZip = (zip: JSZip) => {
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `${fileName}.zip`);
    });
    if (setLoading) {
      setLoading(false);
    }
  };

  for (let file of files) {
    await addFilesToZip(file);
  }

  await downloadZip(zip);
};

export const downloadAllDocuments = async (files: any[], fileName: string) => {
  const zip = new JSZip();

  //fetches the files and saves the files as blobs in an array

  const addFilesToZip: any = async (fileBlobs: any) => {
    let suiteZip = zip.folder(`${fileBlobs.suite}`);
    if (fileBlobs.documents.length) {
      fileBlobs.documents.forEach((document: any) => {
        let blob = new Blob([document.pdf]);
        suiteZip?.file(`${fileBlobs.suite} - ${document.name}.pdf`, blob);
      });
    }
  };

  //downloads the zip
  const downloadZip = (zip: JSZip) => {
    zip.generateAsync({ type: 'blob' }).then(async (content) => {
      await saveAs(content, `${fileName}.zip`);
    });
    return 'Download Complete';
  };

  for (let file of files) {
    await addFilesToZip(file);
  }

  return downloadZip(zip);
};

export const uploadUpgrades = async (file: File) => {
  const workbook = new ExcelJS.Workbook();
  const fileBuffer = await file.arrayBuffer();
  await workbook.xlsx.load(fileBuffer);
  let headerOrder: string[] = [];
  let upgrades: any[] = [];
  let errorMessage = 'The file uploaded does not contain the following headers: ';
  let missingHeaders = [];

  let thisFile = workbook.model.worksheets[0] as any;

  for (let headerCell of thisFile.rows[0].cells) {
    if (headerCell.value) {
      headerOrder.push(normalToCamel(headerCell.value));
    } else {
      headerOrder.push('');
    }
  }

  const hasName = headerOrder.some((e) => e === 'name');
  const hasPrice = headerOrder.some((e) => e === 'price');
  const hasCostPrice = headerOrder.some((e) => e === 'costPrice');
  const hasMainCategory = headerOrder.some((e) => e === 'mainCategory');
  const hasSubCategory = headerOrder.some((e) => e === 'subCategory');
  const hasUpgradeType = headerOrder.some((e) => e === 'upgradeType');
  const hasDescription = headerOrder.some((e) => e === 'description');
  const hasModelsAllowed = headerOrder.some((e) => e === 'modelsAllowed');
  const hasUnitsAllowed = headerOrder.some((e) => e === 'unitsAllowed');
  const hasFreeUpgrades = headerOrder.some((e) => e === 'freeUpgrades');

  const error = !hasName || !hasPrice || !hasCostPrice;

  if (!hasName) {
    missingHeaders.push('Name');
  }
  if (!hasPrice) {
    missingHeaders.push('Price');
  }
  if (!hasCostPrice) {
    missingHeaders.push('Cost Price');
  }
  if (!hasMainCategory) {
    missingHeaders.push('Main Category');
  }
  if (!hasSubCategory) {
    missingHeaders.push('Sub Category');
  }
  if (!hasUpgradeType) {
    missingHeaders.push('Upgrade Type');
  }
  if (!hasDescription) {
    missingHeaders.push('Description');
  }
  if (!hasModelsAllowed) {
    missingHeaders.push('Models Allowed');
  }
  if (!hasUnitsAllowed) {
    missingHeaders.push('Units Allowed');
  }
  if (!hasFreeUpgrades) {
    missingHeaders.push('Free Upgrades');
  }

  if (error) {
    for (let [index, missingHeader] of missingHeaders.entries()) {
      if (index !== missingHeaders.length - 1) {
        errorMessage += missingHeader + ', ';
      } else {
        errorMessage += missingHeader;
      }
    }

    return { error: errorMessage, upgrades: [] };
  }

  const followingRows = thisFile.rows.slice(1);

  let errorValueMessage = '';

  followingRows.forEach((row: any, index: number) => {
    if (!row.cells[0].address.includes('A')) {
      errorValueMessage += `Row ${index + 1} is missing value in Name `;
      return;
    }
    if (!row.cells[1].address.includes('B')) {
      errorValueMessage += `Row ${index + 1} is missing value in Price `;
      return;
    }
    if (!row.cells[2].address.includes('C')) {
      errorValueMessage += `Row ${index + 1} is missing value in Cost Price `;
      return;
    }
    if (!row.cells[3].address.includes('D')) {
      errorValueMessage += `Row ${index + 1} is missing value in Main Category `;
      return;
    }
    if (!row.cells[4].address.includes('E')) {
      errorValueMessage += `Row ${index + 1} is missing value in Sub Category `;
      return;
    }
    if (!row.cells[5].address.includes('F')) {
      errorValueMessage += `Row ${index + 1} is missing value in Upgrade Type `;
      return;
    }
  });

  if (errorValueMessage) {
    return { error: errorValueMessage, upgrades: [] };
  }

  for (let row of thisFile.rows) {
    let newUpgrade: any = {};
    for (let i = 0; i < thisFile.rows[0].cells.length; ++i) {
      if (headerOrder[i] && headerOrder[i].length) {
        if (i <= 5) {
          newUpgrade[headerOrder[i]] = row.cells[i].value;
        } else {
          if (i === 6) {
            if (row.cells[i] && row.cells[i].address.includes('G')) {
              newUpgrade[headerOrder[i]] = row.cells[i].value;
            } else {
              let cell = row.cells.find((cell: any) => cell.address.includes('G'));
              if (cell) {
                newUpgrade[headerOrder[i]] = cell.value;
              } else {
                newUpgrade[headerOrder[i]] = '';
              }
            }
          }
          if (i === 7) {
            if (row.cells[i] && row.cells[i].address.includes('H')) {
              newUpgrade[headerOrder[i]] = row.cells[i].value;
            } else {
              let cell = row.cells.find((cell: any) => cell.address.includes('H'));
              if (cell) {
                newUpgrade[headerOrder[i]] = cell.value;
              }
            }
          }
          if (i === 8) {
            if (row.cells[i] && row.cells[i].address.includes('I')) {
              newUpgrade[headerOrder[i]] = row.cells[i].value;
            } else {
              let cell = row.cells.find((cell: any) => cell.address.includes('I'));
              if (cell) {
                newUpgrade[headerOrder[i]] = cell.value;
              }
            }
          }
          if (i === 9) {
            if (row.cells[i] && row.cells[i].address.includes('J')) {
              newUpgrade[headerOrder[i]] = row.cells[i].value;
            } else {
              let cell = row.cells.find((cell: any) => cell.address.includes('J'));
              if (cell) {
                newUpgrade[headerOrder[i]] = cell.value;
              }
            }
          }
        }
      } else {
        continue;
      }
    }

    if (Object.values(newUpgrade).every((val) => !val)) {
      continue;
    } else {
      upgrades.push(newUpgrade);
    }
  }

  return { error: '', upgrades: upgrades.slice(1) };
};
//

export const uniqueArrayOfObjects = (arr: any[], props: any[] = []) => [
  ...new Map(arr.map((entry) => [props.map((k) => entry[k]).join('|'), entry])).values(),
];

export const createDecorTablePdf = async (
  deal: IDeal,
  unit: IUnit,
  data: any[],
  categories: any,
  type: string,
  project: string,
  all: boolean
) => {
  if (!data.length) return;
  // Constants
  const padding = 20;
  const fontSize = 12;
  const realtorFontSize = 100;
  const textOffset = 5;
  let cellPadding = 5;
  const rowHeight = cellPadding * 6 + fontSize * 3 + textOffset;
  const headerHeight = cellPadding * 2 + fontSize + textOffset;
  const maxRowPerPage = 15;

  // Get text width
  const context = document.createElement('canvas').getContext('2d');
  context && (context.font = fontSize + 'px sans-serif');
  const realtorContext = document.createElement('canvas').getContext('2d');
  realtorContext && (realtorContext.font = realtorFontSize + 'px sans-serif');

  // Get Page Dimensions

  let headers = ['selected', 'component', 'selection', 'description', 'price'];

  const tableWidth = headers.reduce((prevValue, key) => prevValue + 100 + cellPadding * 2, 0);

  const pdf = await PDFDocument.create();
  const helveticaBold = await pdf.embedFont(StandardFonts.HelveticaBold);
  let helveticaFont = await pdf.embedFont(StandardFonts.Helvetica);

  // Embed images

  //const logoImage = await pdf.embedPng(logoBytes);

  let upgradeCategories = categories
    .filter((category: any) => category.mainCategory !== 'Miscellaneous')
    .sort((a: any, b: any) => {
      return mainCategories.indexOf(a.mainCategory) - mainCategories.indexOf(b.mainCategory);
    });
  let miscCategories = categories.find((category: any) => category.mainCategory === 'Miscellaneous');

  pdf.addPage(PageSizes.Legal); // Get page

  let page = pdf.getPages()[0];

  page.moveTo(padding + 10, page.getHeight() - padding); // Add padding
  let rowCount = 0;

  let pageY = page.getHeight();
  if (rowCount === 0) {
    page.moveDown(headerHeight);
    page.drawText('SCHEDULE A', {
      x: page.getX() + cellPadding + 220,
      y: page.getY(),
      size: fontSize,
      lineHeight: fontSize / 2,
      font: helveticaBold,
    });

    page.moveDown(headerHeight);
    page.drawText("PURCHASER'S UPGRADES & EXTRAS FORM", {
      x: page.getX() + cellPadding + 140,
      y: page.getY(),
      size: fontSize,
      lineHeight: fontSize / 2,
      font: helveticaBold,
    });

    page.moveDown(headerHeight);

    page.drawText(`Suite`, {
      x: page.getX() + cellPadding + 180,
      y: page.getY() + headerHeight - fontSize - cellPadding,
      size: 8,
      lineHeight: fontSize / 2,
    });

    page.drawText(`${unit.suite}`, {
      x: page.getX() + cellPadding + 200,
      y: page.getY() + headerHeight - fontSize - cellPadding,
      size: 8,
      lineHeight: fontSize / 2,
      font: helveticaBold,
    });

    page.drawText(`Unit`, {
      x: page.getX() + cellPadding + 250,
      y: page.getY() + headerHeight - fontSize - cellPadding,
      size: 8,
      lineHeight: fontSize / 2,
    });

    page.drawText(`${unit.unit}`, {
      x: page.getX() + cellPadding + 267,
      y: page.getY() + headerHeight - fontSize - cellPadding,
      size: 8,
      lineHeight: fontSize / 2,
      font: helveticaBold,
    });

    page.drawText(`Level`, {
      x: page.getX() + cellPadding + 310,
      y: page.getY() + headerHeight - fontSize - cellPadding,
      size: 8,
      lineHeight: fontSize / 2,
    });

    page.drawText(`${unit.level}`, {
      x: page.getX() + cellPadding + 332,
      y: page.getY() + headerHeight - fontSize - cellPadding,
      size: 8,
      lineHeight: fontSize / 2,
      font: helveticaBold,
    });

    page.moveDown(headerHeight);

    page.drawText(`SUITE`, {
      x: page.getWidth() - page.getWidth() / 2 - 200,
      y: page.getY() + headerHeight - fontSize - cellPadding,
      size: 8,
      lineHeight: fontSize / 2,
    });

    page.drawText(`${unit.suite}`, {
      x: page.getWidth() - page.getWidth() / 2 - 170,
      y: page.getY() + headerHeight - fontSize - cellPadding,
      size: 16,
      lineHeight: fontSize / 2,
      font: helveticaBold,
      color: rgb(0.95, 0.1, 0.1),
    });
    pageY = page.getY();
  }

  // Y value for next page

  for (let [index, category] of upgradeCategories.entries()) {
    let page = pdf.getPages()[0];

    if ((pageY < 300 && category.subCategories.length > 2) || pageY < 200) {
      page = pdf.addPage(PageSizes.Legal); // Get page
      page = pdf.getPages()[pdf.getPages().length - 1];
      pageY = page.getHeight();
      page.moveTo(padding + 10, pageY - padding); // Add padding
    } else {
      page = pdf.getPages()[pdf.getPages().length - 1];
      page.moveTo(padding + 10, page.getY() - padding); // Add padding
    }

    // Add Main Category
    page.moveDown(headerHeight);

    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: headerHeight,
      width: (100 + cellPadding * 2) * 5,
      color: rgb(1, 1, 1),
    });
    page.drawText(category.mainCategory, {
      x: page.getX() + cellPadding + 240,
      y: page.getY() + headerHeight - fontSize - cellPadding,
      size: fontSize,
      lineHeight: fontSize / 2,
    });

    // Add headers
    page.moveDown(headerHeight);
    headers.forEach((header, index) => {
      let width = 100 + cellPadding * 2;
      if (header === 'component') {
        if (type === 'preview') {
          width = 210 + cellPadding * 2;
        }
      }

      if (header === 'selected') {
        if (type === 'preview') {
          width = 100 + cellPadding * 2;
        } else {
          width = 50 + cellPadding * 2;
        }
      }

      if (header === 'selection') {
        if (type === 'preview') {
          width = 0 + cellPadding * 2;
        } else {
          width = 50 + cellPadding * 2;
        }
      }

      if (header === 'description') {
        if (type === 'preview') {
          width = 210 + cellPadding * 2;
        } else {
          width = 200 + cellPadding * 2;
        }
      }

      if (type === 'preview' && (header === 'price' || header === 'selection')) {
        return;
      }

      page.drawRectangle({
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        height: headerHeight,
        width: width,
        color: rgb(1, 1, 1),
      });
      page.drawText(header ? capitalizeFirstLetter(header) : '', {
        x: page.getX() + cellPadding,
        y: page.getY() + headerHeight - fontSize - cellPadding,
        size: fontSize,
        lineHeight: fontSize / 2,
      });

      page.moveRight(width);
    });
    page.moveLeft(tableWidth);

    rowCount += category.subCategories.length + 2;

    let allCategories = category.subCategories.entries();

    if (project === '61f9934d3ace1c59fcb6c5de' && type === 'preview') {
      allCategories = category.subCategories
        .filter((subCategory: any) => {
          if (category.mainCategory === 'Suite' && (subCategory.component === 'Door Handles' || subCategory.component === 'Paint')) {
            return false;
          } else if (category.mainCategory === 'Kitchen' && subCategory.component === 'Pulls') {
            return false;
          } else if (
            subCategory.component === 'Faucet' ||
            subCategory.component === 'Shower Faucet Set' ||
            subCategory.component === 'Vanity Faucet'
          ) {
            return false;
          } else return true;
        })
        .entries();
    }

    for (const [i, subCategory] of allCategories) {
      page.moveDown(headerHeight);
      headers.forEach((header, index) => {
        let text = subCategory[header];

        if (header === 'price') {
          if (subCategory[header] === 'Included') {
            text = 'Included';
          } else if (subCategory[header]) {
            text = numToCurrencyDecimal.format(subCategory[header]);
          } else {
            text = '';
          }
        } else {
          text = subCategory[header].toString();
        }

        if (header === 'description') {
          if (subCategory.quantity > 1) {
            text = `${subCategory[header]} (x${subCategory.quantity})`;
          }
        }

        let width = 100 + cellPadding * 2;

        if (header === 'component') {
          if (type === 'preview') {
            width = 210 + cellPadding * 2;
          }
        }

        if (header === 'selected') {
          if (type === 'preview') {
            width = 100 + cellPadding * 2;
          } else {
            width = 50 + cellPadding * 2;
          }
        }

        if (header === 'selection') {
          if (type === 'preview') {
            width = 0 + cellPadding * 2;
          } else {
            width = 50 + cellPadding * 2;
          }
        }

        if (header === 'description') {
          if (type === 'preview') {
            width = 210 + cellPadding * 2;
          } else {
            width = 200 + cellPadding * 2;
          }
        }

        if (type === 'preview' && (header === 'price' || header === 'selection')) {
          return;
        }

        page.drawRectangle({
          borderWidth: 1,
          borderColor: rgb(0, 0, 0),
          height: headerHeight,
          width: width,
          color: rgb(1, 1, 1),
        });

        if (header === 'description' && text.length > 60) {
          text = wrapText(text, 300, helveticaFont, 9);
        }
        if (header === 'component' && text.length > 30) {
          text = wrapText(text, 300, helveticaFont, 9);
        }

        page.drawText(text, {
          x: page.getX() + cellPadding,
          y: text.length > 60 ? page.getY() + headerHeight - fontSize : page.getY() + headerHeight - fontSize - cellPadding,
          size: text.length > 60 ? 6 : 7,
          lineHeight: fontSize / 2,
        });
        page.moveRight(width);
      });
      page.moveLeft(tableWidth);
    }

    page.moveDown(headerHeight);

    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: headerHeight,
      width: (100 + cellPadding * 2) * 5,
      color: rgb(1, 1, 1),
    });

    if (type !== 'preview') {
      page.drawText(
        `Subtotal: ${numToCurrencyDecimal.format(
          category.subCategories.reduce((a: any, b: any) => {
            if (typeof a === 'number' && typeof b.price === 'number') {
              return a + b.price;
            } else {
              return a;
            }
          }, 0)
        )}`,
        {
          x: page.getX() + cellPadding + 420,
          y: page.getY() + headerHeight - fontSize - cellPadding,
          size: fontSize,
          font: helveticaBold,
          lineHeight: fontSize / 2,
        }
      );
    }

    pageY = page.getY();
  }

  if (type === 'amendment') {
    let latestPage = pdf.getPages()[pdf.getPages().length - 1];

    if (pageY < 200) {
      page = pdf.addPage(PageSizes.Legal); // Get page
      page = pdf.getPages()[pdf.getPages().length - 1];
      latestPage = pdf.getPages()[pdf.getPages().length - 1];
      latestPage.moveTo(padding + 10, latestPage.getHeight() - padding); // Add padding
    }
    latestPage.moveDown(5);
    let allSubTotals = upgradeCategories.reduce((a: any, b: any) => {
      let subCategorySum = b.subCategories.reduce((a: any, b: any) => {
        if (typeof a === 'number' && typeof b.price === 'number') {
          return a + b.price;
        } else {
          return a;
        }
      }, 0);
      return a + subCategorySum;
    }, 0);

    latestPage.drawText(`Subtotal`, {
      x: page.getX() + (315 + cellPadding * 4),
      y: latestPage.getY() - fontSize - cellPadding,
      size: fontSize,
      font: helveticaBold,
      lineHeight: fontSize / 2,
    });

    latestPage.drawText(`${numToCurrencyDecimal.format(allSubTotals)}`, {
      x: page.getX() + (420 + cellPadding * 5),
      y: latestPage.getY() - fontSize - cellPadding,
      size: fontSize,
      font: helveticaBold,
      lineHeight: fontSize / 2,
    });

    latestPage.moveDown(15);
    let coordPrice = 0;
    let creditPrice = 0;
    if (miscCategories) {
      let coord = miscCategories.subCategories.find((subCategory: any) => subCategory.component === 'Coordination');
      let credit = miscCategories.subCategories.find((subCategory: any) => subCategory.component === 'Credit');

      if (coord) {
        latestPage.drawText(`Coordination Fee`, {
          x: page.getX() + (315 + cellPadding * 4),
          y: latestPage.getY() - fontSize - cellPadding,
          size: fontSize,
          font: helveticaBold,
          lineHeight: fontSize / 2,
        });

        latestPage.drawText(`${numToCurrencyDecimal.format(coord.price)}`, {
          x: page.getX() + (420 + cellPadding * 5),
          y: latestPage.getY() - fontSize - cellPadding,
          size: fontSize,
          font: helveticaBold,
          lineHeight: fontSize / 2,
        });

        coordPrice = coord.price;

        latestPage.moveDown(15);
      }

      if (credit) {
        latestPage.drawText(`Credit Balance`, {
          x: page.getX() + (315 + cellPadding * 4),
          y: latestPage.getY() - fontSize - cellPadding,
          size: fontSize,
          font: helveticaBold,
          lineHeight: fontSize / 2,
        });

        latestPage.drawText(`${numToCurrencyDecimal.format(credit.price)}`, {
          x: page.getX() + (420 + cellPadding * 5),
          y: latestPage.getY() - fontSize - cellPadding,
          size: fontSize,
          font: helveticaBold,
          lineHeight: fontSize / 2,
        });

        creditPrice = credit.price;

        latestPage.moveDown(15);
      }
    }

    latestPage.drawLine({
      start: { x: page.getX() + (315 + cellPadding * 4), y: latestPage.getY() - 10 },
      end: { x: page.getX() + cellPadding + 545, y: latestPage.getY() - 10 },
      thickness: 2,
      color: rgb(0, 0, 0),
      opacity: 0.75,
    });

    latestPage.drawLine({
      start: { x: page.getX() + (315 + cellPadding * 4), y: latestPage.getY() - 15 },
      end: { x: page.getX() + cellPadding + 545, y: latestPage.getY() - 15 },
      thickness: 2,
      color: rgb(0, 0, 0),
      opacity: 0.75,
    });

    latestPage.moveDown(15);

    latestPage.drawText(`Total Net of HST`, {
      x: page.getX() + (315 + cellPadding * 4),
      y: latestPage.getY() - fontSize - cellPadding,
      size: fontSize,
      font: helveticaBold,
      lineHeight: fontSize / 2,
    });

    latestPage.drawText(`${numToCurrencyDecimal.format(allSubTotals + coordPrice - creditPrice)}`, {
      x: page.getX() + (420 + cellPadding * 5),
      y: latestPage.getY() - fontSize - cellPadding,
      size: fontSize,
      font: helveticaBold,
      lineHeight: fontSize / 2,
    });

    latestPage.moveDown(15);

    latestPage.drawText(`HST`, {
      x: page.getX() + (315 + cellPadding * 4),
      y: latestPage.getY() - fontSize - cellPadding,
      size: fontSize,
      font: helveticaBold,
      lineHeight: fontSize / 2,
    });

    latestPage.drawText(`${numToCurrencyDecimal.format((allSubTotals + coordPrice - creditPrice) * 0.13)}`, {
      x: page.getX() + (420 + cellPadding * 5),
      y: latestPage.getY() - fontSize - cellPadding,
      size: fontSize,
      font: helveticaBold,
      lineHeight: fontSize / 2,
    });

    latestPage.drawLine({
      start: { x: page.getX() + (315 + cellPadding * 4), y: latestPage.getY() - 23 },
      end: { x: page.getX() + cellPadding + 545, y: latestPage.getY() - 23 },
      thickness: 2,
      color: rgb(0, 0, 0),
      opacity: 0.75,
    });

    latestPage.moveDown(20);

    latestPage.drawText(`Total`, {
      x: page.getX() + (315 + cellPadding * 4),
      y: latestPage.getY() - fontSize - cellPadding,
      size: fontSize,
      font: helveticaBold,
      lineHeight: fontSize / 2,
    });

    latestPage.drawText(`${numToCurrencyDecimal.format((allSubTotals + coordPrice - creditPrice) * 1.13)}`, {
      x: page.getX() + (420 + cellPadding * 5),
      y: latestPage.getY() - fontSize - cellPadding,
      size: fontSize,
      font: helveticaBold,
      lineHeight: fontSize / 2,
    });
    latestPage.moveDown(15);
  }

  const pdfFile = await pdf.save();
  const blob = new Blob([pdfFile]);
  const file = new File([blob], `decorAmendment.pdf`, { type: 'application/pdf' });

  if (!all && (type === 'preview' || type === 'previewPrice')) {
    download(pdfFile, type === 'preview' ? `${unit.suite} Decor Preview.pdf` : `${unit.suite} Decor Construction.pdf`, 'application/pdf');
  }

  return file;
};

const checkHighRisk = (country: string) => {
  if (
    country === 'Iran' ||
    country === 'Myanmar' ||
    country === 'Islamic State' ||
    country === 'Afghanistan' ||
    country === 'Ukraine' ||
    country === 'Russia'
  ) {
    return true;
  } else return false;
};

export const downloadDraft = (data: any) => {
  const groupByKey = (data: any, key: any) =>
    Object.values(
      data.reduce((res: any, item: any) => {
        const value = item[key];
        const existing = res[value] || { [key]: value, data: [] };
        return {
          ...res,
          [value]: {
            ...existing,
            data: [...existing.data, item],
          },
        };
      }, {})
    );

  const groupByKeys = (keys: any) => (array: any) =>
    array.reduce((objectsByKeyValue: any, obj: any) => {
      const value = keys.map((key: any) => (key === 'bathroom' ? `${obj[key]}W` : obj[key])).join('+');
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  let groupedData = groupByKey(data.draft, 'status');

  let groupedDataStatusTotals = groupedData.map((grouped: any) => {
    const validUnitPrices = grouped.data.map((unit: any) => unit.basePrice);
    const mid = Math.floor(validUnitPrices.length / 2);
    const nums = [...validUnitPrices].sort((a, b) => a - b);
    let statusName = unitStatusTitles.find((status: any) => status.code === grouped.status);
    return {
      status: statusName ? statusName.name : grouped.status,
      count: `${grouped.data.length} (${((grouped.data.length / data.draft.length) * 100).toFixed(2)}%)`,
      size: `${numberWithCommas(grouped.data.reduce((a: any, b: any) => a + b.size, 0))} (${(
        (grouped.data.reduce((a: any, b: any) => a + b.size, 0) / data.draft.reduce((a: any, b: any) => a + parseInt(b.size), 0)) *
        100
      ).toFixed(1)}%)`,
      averagePrice: `${numToCurrency.format(grouped.data.reduce((a: any, b: any) => a + b.basePrice, 0) / grouped.data.length)}`,
      revenue: `${numToCurrency.format(grouped.data.reduce((a: any, b: any) => a + b.basePrice, 0))} (${(
        (grouped.data.reduce((a: any, b: any) => a + b.basePrice, 0) /
          data.draft.reduce((a: any, b: any) => a + parseInt(b.basePrice), 0)) *
        100
      ).toFixed(1)}%)`,
      revenueNet: numToCurrency.format(grouped.data.reduce((a: any, b: any) => a + netHST(b.basePrice), 0)),
      ppsf: numToCurrency.format(
        grouped.data.reduce((a: any, b: any) => a + b.basePrice, 0) / grouped.data.reduce((a: any, b: any) => a + b.size, 0)
      ),
      ppsfNet: numToCurrency.format(
        grouped.data.reduce((a: any, b: any) => a + netHST(b.basePrice), 0) / grouped.data.reduce((a: any, b: any) => a + b.size, 0)
      ),
      lowestPrice: numToCurrency.format(Math.min(...validUnitPrices)),
      highestPrice: numToCurrency.format(Math.max(...validUnitPrices)),
      median: validUnitPrices.length % 2 !== 0 ? numToCurrency.format(nums[mid]) : numToCurrency.format((nums[mid - 1] + nums[mid]) / 2),
      averageSize: `${(grouped.data.reduce((a: any, b: any) => a + parseInt(b.size), 0) / grouped.data.length).toFixed(2)}`,
      outdoorSize: `${numberWithCommas(grouped.data.reduce((a: any, b: any) => a + parseInt(b.outdoorSize ? b.outdoorSize : 0), 0))}`,
      averageOutdoorSize: `${numberWithCommas(
        data.draft.reduce((a: any, b: any) => a + parseInt(b.outdoorSize ? b.outdoorSize : 0), 0) / grouped.data.length
      )}`,
    };
  });

  let groupedDataTotals = {
    status: 'Total',
    count: `${data.draft.length}`,
    size: `${numberWithCommas(data.draft.reduce((a: any, b: any) => a + parseInt(b.size), 0))}`,
    revenue: `${numToCurrency.format(data.draft.reduce((acc: any, o: any) => acc + o?.basePrice, 0))}`,
    averagePrice: numToCurrency.format(data.draft.reduce((acc: any, o: any) => acc + o?.basePrice, 0) / data.draft.length),
    revenueNet: `${numToCurrency.format(data.draft.reduce((acc: any, o: any) => acc + netHST(o?.basePrice), 0))}`,
    ppsf: numToCurrency.format(
      data.draft.reduce((acc: any, o: any) => acc + o?.basePrice, 0) / data.draft.reduce((acc: any, o: any) => acc + o?.size, 0)
    ),
    ppsfNet: numToCurrency.format(
      data.draft.reduce((acc: any, o: any) => acc + netHST(o?.basePrice), 0) / data.draft.reduce((acc: any, o: any) => acc + o?.size, 0)
    ),
    averageSize: `${data.draft.reduce((a: any, b: any) => a + parseInt(b.size), 0) / data.draft.length}`,
    outdoorSize: `${numberWithCommas(data.draft.reduce((a: any, b: any) => a + parseInt(b.outdoorSize ? b.outdoorSize : 0), 0))}`,
    averageOutdoorSize: `${numberWithCommas(
      data.draft.reduce((a: any, b: any) => a + parseInt(b.outdoorSize ? b.outdoorSize : 0), 0) / data.draft.length
    )}`,
  };

  let summaryHeaders = [
    {
      label: 'Status',
      id: 'status',
    },
    {
      label: 'Number of Suites',
      id: 'count',
    },
    {
      label: 'Revenue',
      id: 'revenue',
    },
    {
      label: 'Average Price',
      id: 'averagePrice',
    },
    {
      label: 'PPSF',
      id: 'ppsf',
    },
    {
      label: 'Revenue Net',
      id: 'revenueNet',
    },
    {
      label: 'PPSF Net',
      id: 'ppsfNet',
    },
    {
      label: 'Size',
      id: 'size',
    },
    {
      label: 'Average Size',
      id: 'averageSize',
    },
    {
      label: 'Total Outdoor Size',
      id: 'outdoorSize',
    },
    {
      label: 'Average Outdoor Size',
      id: 'averageOutdoorSize',
    },
    {
      label: 'Lowest Price',
      id: 'lowestPrice',
    },
    {
      label: 'Highest Price',
      id: 'highestPrice',
    },
    {
      label: 'Median',
      id: 'median',
    },
  ];

  let summaryWidths = {
    status: 15,
    count: 15,
    size: 15,
    revenue: 15,
    revenueNet: 15,
    ppsf: 15,
    ppsfNet: 15,
    median: 15,
    lowestPrice: 15,
    highestPrice: 15,
    averagePrice: 15,
    averageSize: 15,
    outdoorSize: 15,
    averageOutdoorSize: 15,
  };

  let unitsTotal = [...groupedDataStatusTotals, groupedDataTotals];

  // Unit Type

  const groupByUnitandBathroom = groupByKeys(['unitType', 'bathroom']);
  let groupedUnitTypeData = groupByUnitandBathroom(data.draft);
  groupedUnitTypeData = Object.entries(groupedUnitTypeData).map((unit: any) => {
    return {
      unitType: unit[0],
      data: unit[1],
    };
  });

  let groupedDataUnitType = groupedUnitTypeData.map((grouped: any) => {
    const validUnitPrices = grouped.data.map((unit: any) => unit.basePrice);
    const mid = Math.floor(validUnitPrices.length / 2);
    const nums = [...validUnitPrices].sort((a, b) => a - b);
    return {
      unitType: grouped.unitType,
      totalRevenue: `${numToCurrency.format(grouped.data.reduce((acc: any, o: any) => acc + o?.basePrice, 0))}`,
      averagePrice: `${numToCurrency.format(grouped.data.reduce((acc: any, o: any) => acc + o?.basePrice, 0) / grouped.data.length)}`,
      averageSize: `${(grouped.data.reduce((acc: any, o: any) => acc + o?.size, 0) / grouped.data.length).toFixed(0)}`,
      averageOutdoorSize: `${(
        grouped.data.reduce((acc: any, o: any) => acc + parseInt(o?.outdoorSize ? o.outdoorSize : 0), 0) / grouped.data.length
      ).toFixed(0)}`,
      averagePpsf: `${numToCurrency.format(
        grouped.data.reduce((acc: any, o: any) => acc + o?.basePrice, 0) / grouped.data.reduce((acc: any, o: any) => acc + o?.size, 0)
      )}`,
      lowestPrice: numToCurrency.format(Math.min(...validUnitPrices)),
      highestPrice: numToCurrency.format(Math.max(...validUnitPrices)),
      median: validUnitPrices.length % 2 !== 0 ? numToCurrency.format(nums[mid]) : numToCurrency.format((nums[mid - 1] + nums[mid]) / 2),
      unitsTotal: `${grouped.data.length} (${((grouped.data.length / data.draft.length) * 100).toFixed(2)}%)`,
    };
  });

  let unitColumns = [
    {
      label: 'Unit Types',
      id: 'unitType',
    },
    {
      label: 'Total Revenue',
      id: 'totalRevenue',
    },
    {
      label: 'Average Price',
      id: 'averagePrice',
    },
    {
      label: 'Average Size',
      id: 'averageSize',
    },
    {
      label: 'Average Outdoor Size',
      id: 'averageOutdoorSize',
    },
    {
      label: 'Average PPSF',
      id: 'averagePpsf',
    },
    {
      label: 'Lowest Price',
      id: 'lowestPrice',
    },
    {
      label: 'Highest Price',
      id: 'highestPrice',
    },
    {
      label: 'Median',
      id: 'median',
    },
    {
      label: 'Units Total',
      id: 'unitsTotal',
    },
  ];

  let widths = {
    unitType: 15,
    levels: 15,
    outdoorType: 15,
    averagePrice: 15,
    averageSize: 15,
    averageOutdoorSize: 15,
    averagePpsf: 15,
    lowestPrice: 15,
    highestPrice: 15,
    median: 15,
    unitsTotal: 15,
  };

  // Models

  let groupedModelTypeData = groupByKey(data.draft, 'modelType');
  let groupedDataModelType = groupedModelTypeData.map((grouped: any) => {
    const validUnitPrices = grouped.data.map((unit: any) => unit.basePrice);
    const mid = Math.floor(validUnitPrices.length / 2);
    const nums = [...validUnitPrices].sort((a, b) => a - b);

    const levels = grouped.data.map((grouped: any) => parseInt(grouped.level, 10));

    const levelRanges = findRanges([...new Set(levels)]);

    return {
      modelType: grouped.modelType,
      levels: levelRanges,
      totalRevenue: `${numToCurrency.format(grouped.data.reduce((acc: any, o: any) => acc + o?.basePrice, 0))}`,
      averagePrice: `${numToCurrency.format(grouped.data.reduce((acc: any, o: any) => acc + o?.basePrice, 0) / grouped.data.length)}`,
      averageSize: `${(grouped.data.reduce((acc: any, o: any) => acc + o?.size, 0) / grouped.data.length).toFixed(0)}`,
      averageOutdoorSize: `${(
        grouped.data.reduce((acc: any, o: any) => acc + parseInt(o?.outdoorSize ? o.outdoorSize : 0), 0) / grouped.data.length
      ).toFixed(0)}`,
      averagePpsf: `${numToCurrency.format(
        grouped.data.reduce((acc: any, o: any) => acc + o?.basePrice, 0) / grouped.data.reduce((acc: any, o: any) => acc + o?.size, 0)
      )}`,
      lowestPrice: numToCurrency.format(Math.min(...validUnitPrices)),
      highestPrice: numToCurrency.format(Math.max(...validUnitPrices)),
      median: validUnitPrices.length % 2 !== 0 ? numToCurrency.format(nums[mid]) : numToCurrency.format((nums[mid - 1] + nums[mid]) / 2),
      unitsTotal: `${grouped.data.length} (${((grouped.data.length / data.draft.length) * 100).toFixed(2)}%)`,
    };
  });

  let modelColumns = [
    {
      label: 'Model Types',
      id: 'modelType',
    },
    {
      label: 'Levels',
      id: 'levels',
    },
    {
      label: 'Total Revenue',
      id: 'totalRevenue',
    },
    {
      label: 'Average Price',
      id: 'averagePrice',
    },
    {
      label: 'Average Size',
      id: 'averageSize',
    },
    {
      label: 'Average Outdoor Size',
      id: 'averageOutdoorSize',
    },
    {
      label: 'Average PPSF',
      id: 'averagePpsf',
    },
    {
      label: 'Lowest Price',
      id: 'lowestPrice',
    },
    {
      label: 'Highest Price',
      id: 'highestPrice',
    },
    {
      label: 'Median',
      id: 'median',
    },
    {
      label: 'Units Total',
      id: 'unitsTotal',
    },
  ];

  // Closing

  let closingColumns = [
    {
      label: 'Suite',
      id: 'suite',
    },
    {
      label: 'Status',
      id: 'status',
    },
    {
      label: 'Price',
      id: 'basePrice',
    },
    {
      label: 'PPSF',
      id: 'ppsf',
    },
    {
      label: 'Unit',
      id: 'unit',
    },
    {
      label: 'Level',
      id: 'level',
    },
    {
      label: 'Model Type',
      id: 'modelType',
    },
    {
      label: 'Unit Type',
      id: 'unitType',
    },
    {
      label: 'Size',
      id: 'size',
    },
    {
      label: 'Bathroom',
      id: 'bathroom',
    },
    {
      label: 'Exposure',
      id: 'exposure',
    },
    {
      label: 'Outdoor Type',
      id: 'outdoorType',
    },
    {
      label: 'Outdoor Size',
      id: 'outdoorSize',
    },
  ];

  let closingWidths = {
    suite: 15,
    outdoorType: 15,
    basePrice: 15,
    ppsf: 15,
    bathroom: 15,
    exposure: 15,
    level: 15,
    modelType: 15,
    outdoorSize: 15,
    size: 15,
    status: 15,
    unit: 15,
    unitType: 15,
  };

  let closing = data.draft.map((unit: IUnit) => {
    return {
      ...unit,
      ppsf: numToCurrency.format(unit.basePrice / unit.size),
      basePrice: numToCurrency.format(unit.basePrice),
    };
  });

  let excelData = [unitsTotal, closing, sortSuites(groupedDataUnitType, 'unitType'), sortSuites(groupedDataModelType, 'modelType')];
  let excelHeaders = [summaryHeaders, closingColumns, unitColumns, modelColumns];
  let excelWidths = [summaryWidths, closingWidths, widths, widths];
  let titles = ['Overall Summary', 'Closing', 'Unit Summary', 'Model Summary'];

  downloadExcel(excelData, excelHeaders, [], [excelWidths], titles, `${data.name} Draft.xlsx`);
};

function findRanges(numbers: any[]) {
  return [...numbers]
    .sort((a, b) => a - b)
    .reduce(
      (acc, x, i) => {
        if (i === 0) {
          acc.ranges.push(x);
          acc.rangeStart = x;
        } else {
          if (x === acc.last + 1) {
            acc.ranges[acc.ranges.length - 1] = acc.rangeStart + '-' + x;
          } else {
            acc.ranges.push(x);
            acc.rangeStart = x;
          }
        }
        acc.last = x;
        return acc;
      },
      { ranges: [] }
    )
    .ranges.join(', ');
}

export const wrapText = (text: string, width: number, font: any, fontSize: number) => {
  const words = text.split(' ');
  let line = '';
  let result = '';
  let lineHeight = 0;
  for (let n = 0; n < words.length; n++) {
    const testLine = line + words[n] + ' ';
    const testWidth = font.widthOfTextAtSize(testLine, fontSize);
    if (testWidth > width) {
      result += line + '\n';
      line = words[n] + ' ';
      lineHeight = 10;
    } else {
      line = testLine;
    }
  }
  result += line;
  return {
    text: result,
    lineHeight: lineHeight,
  };
};

export const exportStack = (fileName: string, unitData: any, stackData: any, project: IProject) => {
  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const workbook = new ExcelJS.Workbook(); // Create Workbook
  const worksheet = workbook.addWorksheet('Stack'); // Create Worksheet

  const divmodExcel = (n: number) => {
    const a = Math.floor(n / 26);
    const b = n % 26;

    return b === 0 ? [a - 1, b + 26] : [a, b];
  };

  const uppercaseAlphas = Array.from({ length: 26 }, (_, i) => String.fromCodePoint(i + 'A'.codePointAt(0)!));

  const toExcelCol = (n: number) => {
    const chars = [];

    let d;
    while (n > 0) {
      [n, d] = divmodExcel(n);
      chars.unshift(uppercaseAlphas[d - 1]);
    }
    return chars.join('');
  };

  let stackSort = stackData.sort((a: any, b: any) => b.stackRow - a.stackRow);
  let unitSort = [...unitData].sort((a: any, b: any) => b.stackRow - a.stackRow);
  let uniqueStackRow = [...new Set(stackData.map((item: any) => item.stackRow))]; // [ 'A', 'B']
  let uniqueUnitRow = [...new Set([...unitData].map((item: any) => item.stackRow))].reverse(); // [ 'A', 'B']

  let stackRowDifference: any = uniqueStackRow.reduce((acc: any, curr: any, i, src: any) => {
    if (i !== 0) acc.push(src[i - 1] - curr - 1);
    return acc;
  }, []);

  let unitRowDifference: any = uniqueUnitRow.reduce((acc: any, curr: any, i, src: any) => {
    if (i !== 0) acc.push(src[i - 1] - curr - 1);
    return acc;
  }, []);

  const allStatus: IStatus[] = [...project.status, ...unitStatusTitles];

  for (const stack of stackSort) {
    let rowIndex = uniqueStackRow.indexOf(stack.stackRow);
    let sum = 0;
    let rowValue = 1;
    for (let i = 0; i < rowIndex; i++) {
      sum += stackRowDifference[i];
    }
    if (rowIndex) {
      rowValue = rowIndex * 8 + rowIndex * 1 + sum * 6 + 1;
    }

    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue}`).value = `${stack.unit}`;
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue}`).alignment = { horizontal: 'center' };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue}`).font = {
      color: { argb: 'ffffff', theme: 1 },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue}`).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '000000' },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue}`).border = {
      left: { style: 'thick' },
      right: { style: 'thick' },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 1}`).value = `${stack.exposure}`;
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 1}`).alignment = { horizontal: 'center' };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 1}`).font = {
      color: { argb: '000000', theme: 1 },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 1}`).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: unitTypesColour(stack)?.slice(1) },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 1}`).border = {
      left: { style: 'thick' },
      right: { style: 'thick' },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 2}`).value = `${stack.unitType}`;
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 2}`).alignment = { horizontal: 'center' };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 2}`).font = {
      color: { argb: '000000', theme: 1 },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 2}`).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: unitTypesColour(stack)?.slice(1) },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 2}`).border = {
      left: { style: 'thick' },
      right: { style: 'thick' },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 3}`).value = `${stack.modelType}`;
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 3}`).alignment = { horizontal: 'center' };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 3}`).font = {
      color: { argb: '000000', theme: 1 },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 3}`).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: unitTypesColour(stack)?.slice(1) },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 3}`).border = {
      left: { style: 'thick' },
      right: { style: 'thick' },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 4}`).value = `${stack.bathroom}`;
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 4}`).alignment = { horizontal: 'center' };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 4}`).font = {
      color: { argb: '000000', theme: 1 },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 4}`).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: unitTypesColour(stack)?.slice(1) },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 4}`).border = {
      left: { style: 'thick' },
      right: { style: 'thick' },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 5}`).value = `${stack.size}`;
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 5}`).alignment = { horizontal: 'center' };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 5}`).font = {
      color: { argb: '000000', theme: 1 },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 5}`).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: unitTypesColour(stack)?.slice(1) },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 5}`).border = {
      left: { style: 'thick' },
      right: { style: 'thick' },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 6}`).value = `${stack.outdoorSize}`;
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 6}`).alignment = { horizontal: 'center' };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 6}`).font = {
      color: { argb: '000000', theme: 1 },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 6}`).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: unitTypesColour(stack)?.slice(1) },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 6}`).border = {
      left: { style: 'thick' },
      right: { style: 'thick' },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 7}`).value = `${stack.size + stack.outdoorSize}`;
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 7}`).alignment = { horizontal: 'center' };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 7}`).font = {
      color: { argb: '000000', theme: 1 },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 7}`).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: unitTypesColour(stack)?.slice(1) },
    };
    worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 7}`).border = {
      left: { style: 'thick' },
      right: { style: 'thick' },
    };
  }

  for (const stack of unitSort) {
    let rowIndex = uniqueUnitRow.indexOf(stack.stackRow);
    let statusColor = allStatus.find((status: IStatus) => status.code === stack.status);
    let sum = 1;
    let rowValue = 0;
    for (let i = 0; i < rowIndex; i++) {
      sum += unitRowDifference[i];
    }

    if (rowIndex) {
      rowValue = rowIndex * 6 + sum * 8 + (sum - 1);
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue}`).value = `${stack.suite} (${stack.status})`;
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue}`).alignment = { horizontal: 'center' };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue}`).font = {
        color: { argb: 'ffffff', theme: 1 },
        bold: true,
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '000000' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue}`).border = {
        left: { style: 'thick' },
        right: { style: 'thick' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 1}`).value = `Level ${stack.level}`;
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 1}`).alignment = { horizontal: 'center' };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 1}`).font = {
        color: { argb: '000000', theme: 1 },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 1}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: statusColor ? statusColor.color.slice(1) : 'ffffff' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 1}`).border = {
        left: { style: 'thick' },
        right: { style: 'thick' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 2}`).value = `${numToCurrency.format(stack.basePrice / stack.size)}`;
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 2}`).alignment = { horizontal: 'center' };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 2}`).font = {
        color: { argb: '000000', theme: 1 },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 2}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: statusColor ? statusColor.color.slice(1) : 'ffffff' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 2}`).border = {
        left: { style: 'thick' },
        right: { style: 'thick' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 3}`).value = `${numToCurrency.format(stack.basePrice)}`;
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 3}`).alignment = { horizontal: 'center' };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 3}`).font = {
        color: { argb: '000000', theme: 1 },
        bold: true,
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 3}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: statusColor ? statusColor.color.slice(1) : 'ffffff' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 3}`).border = {
        left: { style: 'thick' },
        right: { style: 'thick' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 4}`).value = `${
        stack.allocation ? stack.allocation.fullName : stack.tempAllocation ? stack.tempAllocation : ''
      }`;
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 4}`).alignment = { horizontal: 'center' };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 4}`).font = {
        color: { argb: '000000', theme: 1 },
        bold: true,
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 4}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: statusColor ? statusColor.color.slice(1) : 'ffffff' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 4}`).border = {
        left: { style: 'thick' },
        right: { style: 'thick' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 5}`).value = `${stack.custom}`;
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 5}`).alignment = { horizontal: 'center' };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 5}`).font = {
        color: { argb: '000000', theme: 1 },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 5}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: statusColor ? statusColor.color.slice(1) : 'ffffff' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 5}`).border = {
        left: { style: 'thick' },
        right: { style: 'thick' },
        bottom: { style: 'thick' },
      };
    } else {
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 9}`).value = `${stack.suite} (${stack.status})`;
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 9}`).alignment = { horizontal: 'center' };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 9}`).font = {
        color: { argb: 'ffffff', theme: 1 },
        bold: true,
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 9}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '000000' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 9}`).border = {
        left: { style: 'thick' },
        right: { style: 'thick' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 10}`).value = `Level ${stack.level}`;
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 10}`).alignment = { horizontal: 'center' };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 10}`).font = {
        color: { argb: '000000', theme: 1 },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 10}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: statusColor ? statusColor.color.slice(1) : 'ffffff' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 10}`).border = {
        left: { style: 'thick' },
        right: { style: 'thick' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 11}`).value = `${numToCurrency.format(stack.basePrice / stack.size)}`;
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 11}`).alignment = { horizontal: 'center' };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 11}`).font = {
        color: { argb: '000000', theme: 1 },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 11}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: statusColor ? statusColor.color.slice(1) : 'ffffff' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 11}`).border = {
        left: { style: 'thick' },
        right: { style: 'thick' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 12}`).value = `${numToCurrency.format(stack.basePrice)}`;
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 12}`).alignment = { horizontal: 'center' };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 12}`).font = {
        color: { argb: '000000', theme: 1 },
        bold: true,
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 12}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: statusColor ? statusColor.color.slice(1) : 'ffffff' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 12}`).border = {
        left: { style: 'thick' },
        right: { style: 'thick' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 13}`).value = `${
        stack.allocation ? stack.allocation.fullName : stack.tempAllocation ? stack.tempAllocation : ''
      }`;
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 13}`).alignment = { horizontal: 'center' };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 13}`).font = {
        color: { argb: '000000', theme: 1 },
        bold: true,
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 13}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: statusColor ? statusColor.color.slice(1) : 'ffffff' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 13}`).border = {
        left: { style: 'thick' },
        right: { style: 'thick' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 14}`).value = `${stack.custom}`;
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 14}`).alignment = { horizontal: 'center' };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 14}`).font = {
        color: { argb: '000000', theme: 1 },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 14}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: statusColor ? statusColor.color.slice(1) : 'ffffff' },
      };
      worksheet.getCell(`${toExcelCol(stack.stackCol)}${rowValue + 14}`).border = {
        left: { style: 'thick' },
        right: { style: 'thick' },
        bottom: { style: 'thick' },
      };
    }
  }

  worksheet.columns.forEach((column) => {
    column.width = 15;
  });

  workbook.xlsx.writeBuffer().then((file: any) => {
    const blob = new Blob([file], { type: EXCEL_TYPE });
    saveAs(blob, fileName);
  });
};

export const columnToLetter = (column: number) => {
  var temp,
    letter = '';
  while (column > 0) {
    temp = (column - 1) % 26;
    letter = String.fromCharCode(temp + 65) + letter;
    column = (column - temp - 1) / 26;
  }
  return letter;
};

export const excelAutoWidth = (worksheet: any, minimalWidth = 10) => {
  worksheet.columns.forEach((column: any) => {
    let maxColumnLength = 0;
    column.eachCell({ includeEmpty: true }, (cell: any) => {
      maxColumnLength = Math.max(maxColumnLength, minimalWidth, cell.value ? cell.value.toString().length : 0);
    });
    column.width = maxColumnLength + 2;
  });
};

export const timeZoneDate = (date: Date) => {
  if (new Date(date).getTimezoneOffset() <= 0) {
    let hours = Math.abs(new Date(date).getTimezoneOffset()) / 60 + 5;
    return new Date(subHours(new Date(date), hours));
  } else {
    let hours = Math.abs(new Date(date).getTimezoneOffset()) / 60;
    if (hours <= 5) {
      return new Date(subHours(new Date(date), 5 - hours));
    } else {
      return new Date(addHours(new Date(date), hours - 5));
    }
  }
};

export const unitTypeText = (unitType: string) => {
  switch (unitType) {
    case 'JR1BR':
      return 'Junior 1 Bedroom';
    case '1BR':
      return 'One Bedroom';
    case '1BR(I)':
      return 'One Bedroom(I)';
    case '1BR+D':
      return 'One Bedroom + Den';
    case '1BR+M':
      return 'One Bedroom + Media';
    case '1BR+F':
      return 'One Bedroom + Flex';
    case '1BR+D(I)':
      return 'One Bedroom + Den(I)';
    case '1BR+M(I)':
      return 'One Bedroom + Den(M)';
    case '2BR':
      return 'Two Bedroom';
    case '2BR(I)':
      return 'Two Bedroom(I)';
    case '2BR+D':
      return 'Two Bedroom + Den';
    case '2BR+M':
      return 'Two Bedroom + Media';
    case '2BR+F':
      return 'Two Bedroom + Flex';
    case 'JR2':
      return 'Junior Two Bedroom';
    case '2BR+D(I)':
      return 'Two Bedroom + Den(I)';
    case '2BR+M(I)':
      return 'Two Bedroom + Media(I)';
    case '3BR':
      return 'Three Bedroom';
    case '3BR(I)':
      return 'Three Bedroom(I)';
    case '3BR+D':
      return 'Three Bedroom + Den';
    case '3BR+M':
      return 'Three Bedroom + Media';
    case '3BR+F':
      return 'Three Bedroom + Flex';
    case 'TH':
      return 'Townhome';
    default:
      return unitType;
  }
};

export const downloadPackage = async (
  data: any[],
  headers: any[],
  fileName: string,
  modelPackage: any[],
  floorplans: any[],
  qrCodes: any[],
  project: any
) => {
  const pdf = await PDFDocument.create();

  if (modelPackage.length && modelPackage[0].packageGetUrl) {
    const modelPackagePdf = await fetch(modelPackage[0].packageGetUrl).then((res) => res.arrayBuffer());
    const documentPdf = await PDFDocument.load(modelPackagePdf);
    let copiedPages = await pdf.copyPages(documentPdf, documentPdf.getPageIndices());
    copiedPages.forEach((page: any) => pdf.addPage(page));
  }

  const helvetica = await pdf.embedFont(StandardFonts.Helvetica);
  const helveticaBold = await pdf.embedFont(StandardFonts.HelveticaBold);
  const helveticaOblique = await pdf.embedFont(StandardFonts.HelveticaOblique);
  const helveticaBoldOblique = await pdf.embedFont(StandardFonts.HelveticaBoldOblique);

  let projectLogoImage: any;
  let developerLogoImage: any;
  let developerRatio: any;
  let partnerLogoImage: any;
  let partnerRatio: any;
  let rdsLogoImage: any;
  if (!project.logoGetUrl) return;

  if (project.developerLogoGetUrl) {
    let developerUrl = project.developerLogoGetUrl.split('?X')[0];
    const developerLogoBytes = await fetch(developerUrl, { cache: 'no-cache' }).then((res) => res.arrayBuffer());
    if (developerUrl.includes('jpg')) {
      developerLogoImage = await pdf.embedJpg(developerLogoBytes);
    } else if (developerUrl.includes('png')) {
      developerLogoImage = await pdf.embedPng(developerLogoBytes);
    }
    developerRatio = (developerLogoImage.width / developerLogoImage.height) * 30;
  }

  if (project.partnerLogoGetUrl) {
    let partnerUrl = project.partnerLogoGetUrl.split('?X')[0];
    const partnerLogoBytes = await fetch(partnerUrl, { cache: 'no-cache' }).then((res) => res.arrayBuffer());
    if (partnerUrl.includes('jpg')) {
      partnerLogoImage = await pdf.embedJpg(partnerLogoBytes);
    } else if (partnerUrl.includes('png')) {
      partnerLogoImage = await pdf.embedPng(partnerLogoBytes);
    }
    partnerRatio = (partnerLogoImage.width / partnerLogoImage.height) * 30;
  }

  const rdsLogoBytes = await fetch('https://rdsappimages.s3.ca-central-1.amazonaws.com/assets/rds/logo.png', { cache: 'no-cache' }).then(
    (res) => res.arrayBuffer()
  );

  rdsLogoImage = await pdf.embedPng(rdsLogoBytes);
  let rdsRatio = (rdsLogoImage.width / rdsLogoImage.height) * 30;

  let logoUrl = project.logoGetUrl.split('?X')[0];

  const projectLogoBytes = await fetch(logoUrl, { cache: 'no-cache' }).then((res) => res.arrayBuffer());
  projectLogoImage = await pdf.embedJpg(projectLogoBytes);

  let logoRatio = (projectLogoImage.width / projectLogoImage.height) * 100;

  for (const [index, item] of data.entries()) {
    let letterSize = item.length <= 7 ? true : false;
    const page = letterSize ? pdf.addPage(PageSizes.Letter) : pdf.addPage(PageSizes.Legal);

    if (project.developerLogoGetUrl) {
      page.drawImage(developerLogoImage!, {
        x: 50,
        y: 55,
        height: 30,
        width: developerRatio,
      });
    }

    if (project.partnerLogoGetUrl) {
      page.drawImage(partnerLogoImage!, {
        x: 250,
        y: 55,
        height: 30,
        width: partnerRatio,
      });
    }

    if (rdsLogoImage) {
      page.drawImage(rdsLogoImage, {
        x: 527,
        y: 55,
        height: 30,
        width: rdsRatio,
      });
    }

    // Draw Border
    page.drawRectangle({
      x: 30,
      y: 30,
      width: 550,
      height: letterSize ? 725 : 950,
      borderWidth: 5,
      borderColor: rgb(0, 0, 0),
    });

    // Logo
    page.drawImage(projectLogoImage, {
      x: page.getWidth() / 2 - logoRatio / 2,
      y: letterSize ? 625 : 850,
      height: 100,
      width: logoRatio,
    });

    if (qrCodes[index]) {
      let qrCodeImage = await pdf.embedJpg(await qrCodes[index]);
      page.drawImage(qrCodeImage, {
        x: 50,
        y: letterSize ? 650 : 875,
        height: 75,
        width: 75,
      });
      page.drawText('Portal', {
        font: helveticaOblique,
        x: 82,
        y: 870,
        size: 4,
      });

      page.drawImage(qrCodeImage, {
        x: 485,
        y: letterSize ? 650 : 875,
        height: 75,
        width: 75,
      });
      page.drawText('Portal', {
        font: helveticaOblique,
        x: 517,
        y: 870,
        size: 4,
      });
    }

    // Title
    const titleWidth = helveticaBold.widthOfTextAtSize(modelPackage[0].title, 24);
    page.drawText(modelPackage[0].title, {
      x: page.getWidth() / 2 - titleWidth / 2,
      y: letterSize ? 595 : 815,
      size: 24,
      lineHeight: 10,
    });

    if (letterSize) {
      page.moveTo(50, 610 - (595 - 390 - (item.length + 1) * 15) / 2);
    } else {
      page.moveTo(50, 830 - (815 - 390 - (item.length + 1) * 15) / 2);
    }

    // Table
    const fontSize = 12;
    const textOffset = 2;
    const cellPadding = 2;
    const headerHeight = cellPadding * 2 + fontSize + textOffset;
    let tableWidth = 0;

    tableWidth = headers[index].reduce((prevValue: any, key: any) => {
      let longestText = [...item.map((item: any) => (item[key.id] ? unitTypeText(item[key.id]) : '')), key.label].reduce(
        (a, b) => (a.toString().length > b.toString().length ? a.toString() : b.toString()),
        ''
      );
      return prevValue + helveticaBold.widthOfTextAtSize(longestText, 8) + 10;
    }, 0);

    let difference = 511.288 - tableWidth;

    // Add headers

    headers[index].forEach((header: any) => {
      let longestText = [...item.map((item: any) => (item[header.id] ? unitTypeText(item[header.id]) : '')), header.label].reduce(
        (a, b) => (a.toString().length > b.toString().length ? a.toString() : b.toString()),
        ''
      );

      page.drawRectangle({
        x: page.getX(),
        y: page.getY(),
        height: 15,
        width: helveticaBold.widthOfTextAtSize(longestText, 8) + 10 + difference / headers[index].length,
        borderWidth: 0.1,
        borderColor: rgb(0, 0, 0),
      });
      page.drawText(header.label, {
        font: helveticaBold,
        x:
          page.getX() +
          5 +
          (helveticaBold.widthOfTextAtSize(longestText, 8) +
            difference / headers[0].length -
            helveticaBold.widthOfTextAtSize(header.label, 8)) /
            2,
        y: page.getY() + headerHeight - fontSize - cellPadding,
        size: 8,
        lineHeight: fontSize / 2,
      });
      page.moveRight(helveticaBold.widthOfTextAtSize(longestText, 8) + 10 + difference / headers[index].length);
    });
    page.moveLeft(tableWidth + difference);

    // Add rows
    for (const rowItem of item) {
      page.moveDown(15);
      headers[index].forEach((header: any) => {
        let longestText = [...item.map((item: any) => (item[header.id] ? unitTypeText(item[header.id]) : '')), header.label].reduce(
          (a, b) => (a.toString().length > b.toString().length ? a.toString() : b.toString()),
          ''
        );
        page.drawRectangle({
          x: page.getX(),
          y: page.getY(),
          borderWidth: 0.1,
          width: helveticaBold.widthOfTextAtSize(longestText, 8) + 10 + difference / headers[index].length,
          height: 15,
          borderColor: rgb(0, 0, 0),
        });
        let text = rowItem[header.id] ? rowItem[header.id].toString() : '';
        if (header.id === 'rental' || header.id === 'basePrice') {
          text = numToCurrency.format(rowItem[header.id]);
        }
        if (header.id === 'size' || header.id === 'outdoorSize') {
          text = rowItem[header.id] ? numberWithCommas(rowItem[header.id]) : '0';
        }
        text = unitTypeText(text);
        page.drawText(rowItem[header.id] ? text : '-', {
          x:
            page.getX() +
            5 +
            (helveticaBold.widthOfTextAtSize(longestText, 8) +
              difference / headers[index].length -
              helveticaBold.widthOfTextAtSize(text, 8)) /
              2,
          y: page.getY() + headerHeight - fontSize - cellPadding,
          size: 8,
        });
        page.moveRight(helveticaBold.widthOfTextAtSize(longestText, 8) + 10 + difference / headers[index].length);
      });
      page.moveLeft(tableWidth + difference);
    }

    page.moveTo(page.getX(), 190);

    for (let i = 0; i < 3; i++) {
      page.drawRectangle({
        x: page.getX(),
        y: page.getY(),
        borderWidth: 0.1,
        width: 511.288 / 3,
        height: 250,
        borderColor: rgb(0, 0, 0),
      });
      page.moveRight(511.288 / 3);
    }

    page.moveLeft(511.288);

    let yLocation = page.getY();

    for (const [numIndex, descriptions] of modelPackage[index].descriptions.slice(0, 3).entries()) {
      page.moveTo(page.getX(), yLocation);
      for (const description of descriptions) {
        if (description.type === 'paragraph') {
          for (const content of description.content) {
            let font = helvetica;
            if (content.marks.includes('bold')) {
              font = helveticaBold;
            }
            if (content.marks.includes('italic')) {
              font = helveticaOblique;
            }
            if (content.marks.includes('italic') && content.marks.includes('bold')) {
              font = helveticaBoldOblique;
            }
            const textWidth = font.widthOfTextAtSize(content.text, 7);

            if (content.marks.includes('underline')) {
              page.drawLine({
                start: {
                  x: page.getX() + 511.288 / 3 / 2 - textWidth / 2 + (numIndex * 511.288) / 3,
                  y: page.getY() + 200 - (150 - descriptions.length * 10) / 2,
                },
                end: {
                  x: page.getX() + 511.288 / 3 / 2 + textWidth / 2 + (numIndex * 511.288) / 3,
                  y: page.getY() + 200 - (150 - descriptions.length * 10) / 2,
                },
                thickness: 0.1,
                color: rgb(0, 0, 0),
              });
            }
            if (content.marks.includes('strike')) {
              page.drawLine({
                start: {
                  x: page.getX() + 511.288 / 3 / 2 - textWidth / 2 + (numIndex * 511.288) / 3,
                  y: page.getY() + 202 - (150 - descriptions.length * 10) / 2,
                },
                end: {
                  x: page.getX() + 511.288 / 3 / 2 + textWidth / 2 + (numIndex * 511.288) / 3,
                  y: page.getY() + 202 - (150 - descriptions.length * 10) / 2,
                },
                thickness: 0.1,
                color: rgb(0, 0, 0),
              });
            }
            page.drawText(content.text, {
              x: page.getX() + 511.288 / 3 / 2 - textWidth / 2 + (numIndex * 511.288) / 3,
              y: page.getY() + 200 - (150 - descriptions.length * 10) / 2,
              font: font,
              size: 7,
              lineHeight: 10,
            });
            page.moveDown(10);
          }
        } else if (description.type === 'bulletList') {
          for (const content of description.content) {
            let font = helvetica;
            if (content.marks.includes('bold')) {
              font = helveticaBold;
            }
            if (content.marks.includes('italic')) {
              font = helveticaOblique;
            }
            if (content.marks.includes('italic') && content.marks.includes('bold')) {
              font = helveticaBoldOblique;
            }
            const textWidth = font.widthOfTextAtSize(content.text, 7);

            if (content.marks.includes('underline')) {
              page.drawLine({
                start: { x: page.getX() + 511.288 / 3 / 2 - textWidth / 2, y: page.getY() + 200 - (descriptions.length * 10) / 2 },
                end: { x: page.getX() + 511.288 / 3 / 2 + textWidth / 2, y: page.getY() + 200 - (descriptions.length * 10) / 2 },
                thickness: 0.1,
                color: rgb(0, 0, 0),
              });
            }
            if (content.marks.includes('strike')) {
              page.drawLine({
                start: { x: page.getX() + 511.288 / 3 / 2 - textWidth / 2, y: page.getY() + 202 - (descriptions.length * 10) / 2 },
                end: { x: page.getX() + 511.288 / 3 / 2 + textWidth / 2, y: page.getY() + 202 - (descriptions.length * 10) / 2 },
                thickness: 0.1,
                color: rgb(0, 0, 0),
              });
            }
            page.drawText(content.text, {
              x: page.getX() + 511.288 / 3 / 2 - textWidth / 2,
              y: page.getY() + 200 - (200 - descriptions.length * 10) / 2,
              font: font,
              size: 7,
              lineHeight: 10,
            });
            page.moveDown(10);
          }
        }
      }
    }

    //Disclaimer

    page.moveTo(page.getX(), 175);

    for (const description of modelPackage[index].descriptions[3]) {
      for (const content of description.content) {
        let font = helvetica;
        if (content.marks.includes('bold')) {
          font = helveticaBold;
        }
        const textWidth = font.widthOfTextAtSize(content.text, 7);

        if (content.marks.includes('underline')) {
          page.drawLine({
            start: {
              x: page.getWidth() / 2 - textWidth / 2,
              y: page.getY(),
            },
            end: {
              x: page.getWidth() / 2 - textWidth / 2,
              y: page.getY(),
            },
            thickness: 0.1,
            color: rgb(0, 0, 0),
          });
        }
        if (content.marks.includes('strike')) {
          page.drawLine({
            start: {
              x: page.getWidth() / 2 - textWidth / 2,
              y: page.getY(),
            },
            end: {
              x: page.getWidth() / 2 - textWidth / 2,
              y: page.getY(),
            },
            thickness: 0.1,
            color: rgb(0, 0, 0),
          });
        }
        page.drawText(content.text, {
          x: page.getWidth() / 2 - textWidth / 2,
          y: page.getY(),
          size: 7,
          lineHeight: 10,
        });
        page.moveDown(10);
      }
    }
  }

  //Floorplans

  for (const floorplan of floorplans) {
    let floorplanDoc = await PDFDocument.create();
    const floorplanImage = await fetch(floorplan).then((res) => res.arrayBuffer());
    if (floorplan.includes('pdf')) {
      floorplanDoc = await PDFDocument.load(floorplanImage);
    } else {
      const jpgImage = await floorplanDoc.embedJpg(floorplanImage);
      if (jpgImage.width > jpgImage.height) {
        const page = floorplanDoc.addPage([PageSizes.Letter[1], PageSizes.Letter[0]]);
        page.setSize(792, 612);
        page.drawImage(jpgImage, {
          width: 792,
          height: 612,
        });
      } else {
        const page = floorplanDoc.addPage(PageSizes.Letter);
        page.drawImage(jpgImage, {
          width: 612,
          height: 792,
        });
      }
    }
    let copiedPages = await pdf.copyPages(floorplanDoc, floorplanDoc.getPageIndices());
    await copiedPages.forEach((page: any) => pdf.addPage(page));
  }

  const pdfFile = await pdf.save();
  download(pdfFile, fileName, 'application/pdf');
};

export const ordinal_suffix_of = (i: number) => {
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
};

export const signField = (index: number) => {
  if (index === 0) {
    return 'P1';
  } else if (index === 1) {
    return 'P2';
  } else if (index === -1) {
    return 'Ex';
  } else if (index === -2) {
    return 'Re';
  } else if (index === -3) {
    return 'SO1';
  } else if (index === -4) {
    return 'SO2';
  } else if (index === 2) {
    return 'P3';
  } else if (index === 3) {
    return 'P4';
  } else if (index === 4) {
    return 'P5';
  } else if (index === 5) {
    return 'P6';
  } else if (index === -6) {
    return 'Wit1';
  } else if (index === -7) {
    return 'Wit2';
  } else if (index === -8) {
    return 'Wit3';
  } else if (index === -9) {
    return 'Wit4';
  } else {
    return '';
  }
};

export const checkSpecialChars = (string: string) => {
  let format = /[`!^*()_\=\[\]{};:"\\|,<>\/?~]/;
  let check = format.test(string);
  return check;
};

export const getUnitType = (unitType: string) => {
  switch (unitType) {
    case 'studio':
      return 'Studio';
    case 'jrOneBr':
      return 'JR1BR';
    case 'oneBr':
      return '1BR';
    case 'oneBrInbound':
      return '1BR(I)';
    case 'oneBrDen':
      return '1BR+D';
    case 'oneBrMedia':
      return '1BR+M';
    case 'oneBrFlex':
      return '1BR+F';
    case 'oneBrDenInbound':
      return '1BR+D(I)';
    case 'oneBrMediaInbound':
      return '1BR+M(I)';
    case 'twoBr':
      return '2BR';
    case 'twoBrInbound':
      return '2BR(I)';
    case 'twoBrDen':
      return '2BR+D';
    case 'twoBrMedia':
      return '2BR+M';
    case 'twoBrFlex':
      return '2BR+F';
    case 'juniorTwo':
      return 'JR2';
    case 'twoBrDenInbound':
      return '2BR+D(I)';
    case 'twoBrMediaInbound':
      return '2BR+M(I)';
    case 'threeBr':
      return '3BR';
    case 'threeBrInbound':
      return '3BR(I)';
    case 'threeBrDen':
      return '3BR+D';
    case 'threeBrMedia':
      return '3BR+M';
    case 'threeBrFlex':
      return '3BR+F';
    case 'th':
      return 'TH';
    default:
      return 'N/A';
  }
};

export const envelopeIdRegex = (id: string) => {
  if (id.length > 0) {
    let envelopeIdTest = new RegExp('^[a-zA-Z0-9]+(?:--?[a-zA-Z0-9]+)*$');
    let validId = envelopeIdTest.test(id);
    if (validId && id.length === 36) {
      return false
    } else return true
  } else return false
}
