import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { IPortal } from "../../types/project";

const initialState: IPortalState = {
  portal: {
    _id: '',
    active: false,
    vipActive: false,
    showHotlist: false,
    showFloorPlans: false,
    primaryColor: '',
    secondaryColor: '',
    showOptionsPrice: false,
    showUnitTypes: false,
    showOptions: true,
    showCustom: true,
    createWorksheets: false,
    sortHotlist: 'low',
    password: '',
    information: [],
    promo: '',
    renderings: [],
    photos: [],
    pdfs: [],
    logos: [],
    aerialPhotos: [],
    bRoll: [],
    officialSite: '',
    instaUrl: '',
    subText: '',
    videos: [],
    brochure: '',
    brochureGetUrl: '',
    brochurePutUrl: '',
  }
};

export const portalSlice = createSlice({
  name: "portal",
  initialState,
  reducers: {
    setPortal: (state, action) => {
      state.portal = action.payload
    },
    setPortalColor: (state, action) => {
      state.portal.primaryColor = action.payload
    },
  },
});

export const { setPortal, setPortalColor } = portalSlice.actions;

export const selectPortal = (state: RootState) => state.portal.portal;

interface IPortalState {
  portal: IPortal
}

export default portalSlice.reducer;
