import { useState, useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Box, Typography, Modal, Fade, Grid } from '@mui/material';

import StandardTable from '../../tables/StandardTable';
import { numToCurrency, convertAllDates, downloadExcel, downloadPdf, sortNestedSuites } from '../../../utils/Functions';
import { IUnit } from '../../../types/unit';
import { IPurchaserInfo } from '../../../types/CreateDealForm';

const SummaryCard = (props: ChildProps) => {
  const { projectSummary, dateStart, dateEnd, total } = props;
  const [title, setTitle] = useState<string>('');
  const [units, setUnits] = useState<IUnit[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const [getDeals, { loading: unitLoading }] = useLazyQuery(STATUSDEALS, {
    onCompleted: (data: any) => {
      let sortedSuites = sortNestedSuites([...data.getDateDeals], 'unit', 'suite');
      setUnits(sortedSuites);
    },
  });

  const handleUnitModal = (status: string, cancelled: boolean, rescission: boolean) => {
    if (!rescission && !cancelled && status === 'sales') {
      setTitle('New Sales');
    } else if (status) {
      setTitle(`Status ${status}`);
    } else if (cancelled) {
      setTitle('Cancellations');
    } else if (rescission) {
      setTitle('Rescissions');
    }

    getDeals({ variables: { project: projectSummary.project._id, status: status, dateStart, dateEnd, cancelled, rescission } });
    setOpen(true);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Status',
        accessor: (rowData: any) => {
          return (
            <Box
              sx={{ cursor: total ? 'auto' : 'pointer' }}
              onClick={() => {
                if (!total) {
                  handleUnitModal(rowData._id, false, false);
                }
              }}
            >
              <Box sx={{ fontWeight: total ? 400 : 700 }}>{rowData._id}</Box>
            </Box>
          );
        },
      },
      {
        Header: 'Number of Units',
        accessor: (rowData: any) => rowData.count,
      },
      {
        Header: 'Revenue',
        accessor: (rowData: any) => numToCurrency.format(rowData.revenue),
      },
      {
        Header: 'PPSF',
        accessor: (rowData: any) => numToCurrency.format(rowData.revenue / rowData.size),
      },
    ];
  }, [dateStart, dateEnd, projectSummary, handleUnitModal]);

  const unitModalColumns = useMemo(() => {
    let unitModal = [
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Link
              style={{ textDecoration: 'none', color: 'inherit' }}
              rel="noopener noreferrer"
              target="_blank"
              to={`/${rowData.unit.project._id}/dashboard/${rowData.unit._id}`}
            >
              <strong style={{ cursor: 'pointer' }}>{rowData.unit.suite}</strong>
            </Link>
          );
        },
      },
      {
        Header: 'Status',
        accessor: (rowData: any) => rowData.unit.status,
      },
      {
        Header: 'Base Price',
        accessor: (rowData: any) => numToCurrency.format(rowData.unit.basePrice),
      },
      {
        Header: 'Unit Type',
        accessor: (rowData: any) => rowData.unit.unitType,
      },
      {
        Header: 'Exposure',
        accessor: (rowData: any) => rowData.unit.exposure,
      },
      {
        Header: 'Model Type',
        accessor: (rowData: any) => rowData.unit.modelType,
      },
      {
        Header: 'Size',
        accessor: (rowData: any) => rowData.unit.size,
      },
      {
        Header: 'PPSF',
        accessor: (rowData: any) => numToCurrency.format(rowData.unit.basePrice / rowData.unit.size),
      },
      {
        Header: 'Realtor',
        accessor: (rowData: any) => (rowData.realtor && rowData.realtor.length ? rowData.realtor[0].fullName : 'Public'),
      },
      {
        Header: 'Purchaser Full Name',
        accessor: (rowData: any) =>
          rowData.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.firstName} ${purchaser.lastName}`).join(', '),
      },
      {
        Header: 'Purchaser Email',
        accessor: (rowData: any) => rowData.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.email}`).join(', '),
      },
      {
        Header: 'Purchaser Phone',
        accessor: (rowData: any) => rowData.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.primaryPhone}`).join(', '),
      },
    ];

    if (title === 'Rescissions' || title === 'Cancellations') {
      unitModal.push({
        Header: `Rescission/Cancel`,
        accessor: (rowData: any) =>
          title === 'Rescissions'
            ? convertAllDates(rowData.rescission.dateRescinded, 'PPpp')
            : convertAllDates(rowData.cancelled.dateCancelled, 'PPpp'),
      });
    }

    return unitModal;
  }, [title]);

  const unitDownload = (type: string, data: any) => {
    let headers = [
      {
        label: 'Suite',
        id: 'suite',
      },
      {
        label: 'Base Price',
        id: 'basePrice',
      },
      {
        label: 'Unit Type',
        id: 'unitType',
      },
      {
        label: 'Exposure',
        id: 'exposure',
      },
      {
        label: 'Model Type',
        id: 'modelType',
      },
      {
        label: 'Size',
        id: 'size',
      },
      {
        label: 'Realtor',
        id: 'realtor',
      },
      {
        label: 'Purchaser Full Names',
        id: 'purchaserFullNames',
      },
      {
        label: 'Purchaser Emails',
        id: 'purchaserEmails',
      },
      {
        label: 'Purchaser Phone',
        id: 'purchaserPhones',
      },
      {
        label: 'PPSF',
        id: 'ppsf',
      },
    ];

    let widths = {
      suite: 15,
      basePrice: 15,
      unitType: 15,
      exposure: 15,
      modelType: 15,
      size: 15,
      realtor: 15,
      purchaserFullNames: 15,
      purchaserEmails: 15,
      purchaserPhones: 15,
      ppsf: 15,
    };

    let pdfWidths = {
      suite: 200,
      basePrice: 200,
      unitType: 200,
      exposure: 200,
      modelType: 200,
      size: 200,
      realtor: 200,
      purchaserFullNames: 200,
      purchaserEmails: 200,
      purchaserPhones: 200,
      ppsf: 200,
    };

    let newData = data.map((data: any) => {
      return {
        ...data,
        suite: data.unit.suite,
        basePrice: data.basePrice ? numToCurrency.format(data.basePrice) : numToCurrency.format(data.unit.basePrice),
        unitType: data.unit.unitType,
        exposure: data.unit.exposure,
        modelType: data.unit.modelType,
        size: data.unit.size,
        realtor: data.realtor.length ? data.realtor[0].fullName : 'Public',
        purchaserFullNames: data.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.firstName} ${purchaser.lastName}`).join(', '),
        purchaserEmails: data.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.email}`).join(', '),
        purchaserPhones: data.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.primaryPhone}`).join(', '),
        ppsf: numToCurrency.format(data.basePrice / data.unit.size),
      };
    });

    let sheetTitle = `${projectSummary.project.name} - ${title}`;

    if (type === 'excel') {
      downloadExcel([newData], [headers], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([newData], [headers], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  return (
    <Box>
      <Modal
        open={open}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          m: 3,
        }}
        onClose={() => setOpen(false)}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: {
                xs: 450,
                sm: 700,
                md: 800,
                lg: 1200,
              },
              maxHeight: {
                xs: 550,
                sm: 600,
                md: 600,
                lg: 650,
                xl: 850,
              },
              overflowY: 'auto',
              backgroundColor: '#fff',
              padding: '20px',
              border: '2px solid #000',
            }}
          >
            <Typography variant={'h5'} gutterBottom>
              {title}
            </Typography>
            <StandardTable columns={unitModalColumns} data={units} loading={unitLoading} download={unitDownload} />
          </Box>
        </Fade>
      </Modal>
      <Box sx={{ height: '100%' }}>
        <Typography
          sx={{
            textAlign: 'center',
            p: 2,
            backgroundColor: projectSummary.project.portal.primaryColor ? projectSummary.project.portal.primaryColor : '#00142a',
            color: '#fff',
          }}
        >
          <strong>{projectSummary.project.name}</strong>
        </Typography>
        <Grid container spacing={2} sx={{ py: 1 }}>
          <Grid
            item
            xs={6}
            sx={{ cursor: total ? 'auto' : 'pointer', textAlign: 'center' }}
            onClick={() => {
              if (!total) {
                handleUnitModal('', false, true);
              }
            }}
          >
            <Box sx={{ fontWeight: total ? 400 : 700 }}>Rescission: {projectSummary.rescission}</Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ cursor: total ? 'auto' : 'pointer', textAlign: 'center' }}
            onClick={() => {
              if (!total) {
                handleUnitModal('', true, false);
              }
            }}
          >
            <Box sx={{ fontWeight: total ? 400 : 700 }}>Cancelled: {projectSummary.cancelled}</Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ cursor: total ? 'auto' : 'pointer', textAlign: 'center' }}
            onClick={() => {
              if (!total) {
                handleUnitModal('sales', false, false);
              }
            }}
          >
            <Box sx={{ fontWeight: total ? 400 : 700 }}>Sales: {projectSummary.newSales}</Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ cursor: total ? 'auto' : 'pointer', textAlign: 'center' }}
            onClick={() => {
              if (!total) {
                handleUnitModal('deals', false, false);
              }
            }}
          >
            <Box sx={{ fontWeight: total ? 400 : 700 }}>Sales & Presales: {projectSummary.newDeals}</Box>
          </Grid>
        </Grid>
        <Box sx={{ height: '100%' }}>
          <StandardTable data={projectSummary.statuses} columns={columns} />
        </Box>
      </Box>
    </Box>
  );
};

interface ChildProps {
  projectSummary: any;
  dateStart: Date | null | number;
  dateEnd: Date | null;
  total: boolean;
}

const STATUSDEALS = gql`
  query getDateDeals($project: MongoID!, $dateStart: Date!, $dateEnd: Date!, $rescission: Boolean!, $cancelled: Boolean!, $status: String) {
    getDateDeals(
      project: $project
      dateStart: $dateStart
      dateEnd: $dateEnd
      rescission: $rescission
      cancelled: $cancelled
      status: $status
    ) {
      realtor {
        fullName
      }
      unit {
        _id
        suite
        status
        modelType
        unitType
        bathroom
        size
        outdoorType
        project {
          _id
        }
        basePrice
        exposure
      }
      basePrice
      realtor {
        fullName
      }
      rescission {
        dateRescinded
      }
      cancelled {
        dateCancelled
      }
      purchasers {
        firstName
        lastName
        email
        primaryPhone
      }
    }
  }
`;

export default SummaryCard;
