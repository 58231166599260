import { useState, useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { TextField, Autocomplete, Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// import { ActivityContext } from '../../../context/ActivityContext';
import { UnitContext } from '../../../../context/UnitContext';
import Dropdown from '../../../common/formControls/Dropdown';
import { IUser, IUserArray } from '../../../../types/user';
import { tags } from '../../../../utils/Constants';

const DealSummaryForm = (props: ChildProps) => {
  const { filteredDeal, setFilteredDeal } = useContext(UnitContext);
  const { handleDateChange } = props;
  const [users, setUsers] = useState<IUser[]>([]);

  useQuery<IUserArray>(GETSALES, {
    variables: { filter: { OR: [{ type: 'Sales' }, { type: 'Manager' }], locked: false }, sort: 'NAME' },
    onCompleted: (data) => {
      setUsers(data.userMany);
    },
  });

  const handleSalesDropdown = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    let selectedUser = users.find((user: IUser) => user.fullName === event.target.value);
    if (selectedUser) {
      setFilteredDeal({
        ...filteredDeal,
        salesRep: {
          fullName: selectedUser.fullName,
          _id: selectedUser._id,
        },
      });
    } else {
      setFilteredDeal({
        ...filteredDeal,
        salesRep: null,
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={'Sign Date'}
            value={filteredDeal.signDate ? filteredDeal.signDate : null}
            onChange={(newValue) => handleDateChange(newValue, 'signDate')}
            renderInput={(params: any) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={'Execute Date'}
            value={filteredDeal.executeDate ? filteredDeal.executeDate : null}
            onChange={(newValue) => handleDateChange(newValue, 'executeDate')}
            renderInput={(params: any) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={'Firm Date'}
            value={filteredDeal.firmDate ? filteredDeal.firmDate : null}
            onChange={(newValue) => handleDateChange(newValue, 'firmDate')}
            renderInput={(params: any) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          title={'Sales Representative'}
          includeEmpty={true}
          menuList={users.map((user: IUser) => user.fullName)}
          name={'salesRep'}
          handleSelect={(e: any) => handleSalesDropdown(e)}
          value={filteredDeal.salesRep ? filteredDeal.salesRep.fullName : ''}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          multiple
          options={[...tags, ...filteredDeal.project.tags]}
          getOptionLabel={(option: string) => option}
          isOptionEqualToValue={(option, value) => option === value}
          disableClearable={false}
          freeSolo={false}
          value={filteredDeal.tags}
          onChange={(event: any, newValue: any | null) => {
            setFilteredDeal({
              ...filteredDeal,
              tags: newValue.map((option: string) => option),
            });
          }}
          renderInput={(params) => <TextField {...params} label="Tags" size="medium" />}
        />
      </Grid>
    </Grid>
  );
};

interface ChildProps {
  handleDateChange: any;
}

const GETSALES = gql`
  query userMany($filter: FilterFindManyUserInput, $sort: SortFindManyUserInput) {
    userMany(filter: $filter, sort: $sort) {
      _id
      fullName
    }
  }
`;

export default DealSummaryForm;
